import { FC } from 'react'
import { TabelTableType } from '../../../../../types/types'
import { TableBody } from './parts/TableBody'
import { TableHeader } from './parts/TableHeader'
import styles from './Table.module.scss'

type Props = {
    data: Array<TabelTableType>
}

const Table: FC<Props> = ({ data}) => {
    return (
        <table className={styles.tabelTable}>
           <TableHeader
            />
            
            <TableBody

                data={data}
            />
        </table>
    )
}

export default Table