import React, { FC } from 'react';
import { RightBlockWrapper } from '../../../../simples/RightBlockWrapper';
import { BottomPanel } from './parts/BottomPanel';
import { TopPanel } from './parts/TopPanel';
import styles from './RightPanel.module.scss'

const RightPanel: FC = (): JSX.Element => {
    return (
        <RightBlockWrapper
            top={<TopPanel />}
            bottom={<BottomPanel />}
        />
    )
}

export default RightPanel;
