import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Routes } from '../../constants/config'
import { addZero } from '../../constants/helper'
import { SettingsService } from '../../services/SettingsService'
import { SortByType, SortType } from '../../types/types'
import { RootState } from '../index'


export type FilterStaff = {
    tabelGroupId?: string
    search?: string
    branchId?: string
    fired?: 0 | 1
}

export enum ExportType {
    XLSX = 'XLSX',
    PDF = 'PDF'
}

export type FilterTabel = {
    month?: string
    year?: number
    lastName?: string
    branchId?: string
    sortBy?: SortByType
    sort?: SortType
    offset?: number
    limit?: number
    tabelNumber?: number | string
    tabelGroupId?: string
    export?: ExportType
    hideUnimploy?: boolean
    tabelTypeId?: string

}

/**
 * Начальное состояние редьюсера filters/
 */

interface InitState {
    usersFilter: FilterStaff,
    tabelFilter: FilterTabel,
    isLoading: boolean
}

export const initialState: InitState = {
        usersFilter: {
        // lastName: '',
        tabelGroupId: '',
        branchId: '',
        fired: 0,
    },
    tabelFilter: {
        month: addZero(new Date().getMonth() + 1),
        year: new Date().getFullYear(),
        lastName: '',
        tabelNumber: '',
        sortBy: SortByType.LAST_NAME,
        sort: SortType.ASC,
        hideUnimploy: false
    },
    isLoading: true
}

/**
 * получаем фильтры с бэка
 */
export const fetchFilters = createAsyncThunk('filters/fetchFilters',
    async () => {

        const response = await SettingsService.fetchFilters()
        return response

    })

/**
 * save filters
 */
const saveUsersFilter = createAsyncThunk("filters/saveFilter",
    async (data: {
        page: Routes, options: FilterStaff
    }) => {
        const response = await SettingsService.saveFilter({
            page: data.page,
            options: JSON.stringify(data.options)
        })
    })

/**
 * Создание слайса Filters
 */
export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        changeUserFilter: (state, action: PayloadAction<FilterStaff>) => {
            state.usersFilter = action.payload
        },
        changeTabelDateFilter: (state, action: PayloadAction<{year: number, month: string}>) => {
            state.tabelFilter = {...state.tabelFilter, ...action.payload }
        },
        changeTabelFilter: (state, action: PayloadAction<FilterTabel>) => {
            state.tabelFilter = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFilters.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchFilters.rejected, (state) => {
            state.isLoading = false
        })
        builder.addCase(fetchFilters.fulfilled, (state, action) => {
           action.payload.map((item) => {

            switch (item.page) {
                case Routes.STAFF:
                    const filterUser: FilterStaff = JSON.parse(item.options)

                        state.usersFilter = {
                            search: filterUser.search,
                            fired: filterUser.fired,
                            branchId: filterUser.branchId,
                            tabelGroupId: filterUser.tabelGroupId
                        }

                    break;
            
                case Routes.TABEL:

                const filter: FilterTabel = JSON.parse(item.options)

                if(!filter.year || !filter.month || !filter){
                    state.tabelFilter = {...filter, year: new Date().getFullYear(), month: addZero(new Date().getMonth() + 1) }
                } else {
                    state.tabelFilter = filter
                }
                    
                    break;
            
                default:
                    break;
            }



           })
           state.isLoading = false
        })
        builder.addCase(saveUsersFilter.pending, (state, action) => {

        })
    }

})

export const {
    changeUserFilter,
    changeTabelFilter,
    changeTabelDateFilter
} = filtersSlice.actions


export const userFilter = (state: RootState) => state.filters.usersFilter
export const tabelFilter = (state: RootState) => state.filters.tabelFilter

// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default filtersSlice.reducer