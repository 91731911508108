import React, { useEffect } from 'react'
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes as RoutesDom,
} from 'react-router-dom'
import styles from './App.module.scss'
import { Routes } from '../../constants/config'

import { useAppDispatch, useAppSelector } from '../../hooks/store'
import {
    checkUser,
    selectAuthChecking,
    selectIsAuth,
} from '../../store/slice/auth'
import { MainPreloader } from '../simples/MainPreloader'
import { Auth } from '../pages/Auth'
import { PersonalCard } from '../pages/PersonalCard'
import { MainPage } from '../pages/MainPage'
import { LogsPage } from '../pages/LogsPage'
import { TabelsPage } from '../pages/TabelsPage'
import {
    fetchAccessRights,
    fetchColors,
    fetchFilials,
    fetchNotificationsTypes,
    fetchPositions,
    fetchRole,
    fetchTabelGroups,
    fetchTabelTypes,
    fetchTablesNames,
} from '../../store/slice/storage'
import { TabelsSettings } from '../pages/TabelsSettings'
import { StaffPage } from '../pages/StaffPage'
import { fetchFilters, FilterTabel } from '../../store/slice/filters'
import { addZero } from '../../constants/helper'
import { setTableDate } from '../../store/slice/tabel'

const App = () => {
    const preloading = useAppSelector(selectAuthChecking)
    const dispatch = useAppDispatch()

    const isAuth = useAppSelector(selectIsAuth)

    useEffect(() => {
        
       if(isAuth){
        dispatch(fetchTabelGroups()).then()
        dispatch(fetchFilials()).then()
        dispatch(fetchTablesNames()).then()
        dispatch(fetchColors()).then()
        dispatch(fetchPositions()).then()
        dispatch(fetchRole()).then()
        dispatch(fetchTabelTypes()).then()
        dispatch(fetchFilters()).then((data: any) => {

            if(!data.payload) return
            data.payload.map((item: {
                page: Routes,
                options: string
            }) => {

                switch (item.page) {
                    
                    case Routes.TABEL:
    
                    const filter: FilterTabel = JSON.parse(item.options)
    
                    if(!filter.year || !filter.month || !filter){

                        dispatch(setTableDate({
                            year: new Date().getFullYear(), month: addZero(new Date().getMonth() + 1) 
                        }))
                    } else {
                        
                        dispatch(setTableDate({
                            year: filter.year, month: filter.month
                        }))
                    }
                        
                        break;
                
                    default:
                        break;
                }
    
    
    
               })
            console.log(data.payload, 'DATE')
        })
        dispatch(fetchAccessRights()).then()
        dispatch(fetchNotificationsTypes()).then()
       } else {
        dispatch(checkUser()).then()
       }
    }, [dispatch, isAuth])

    if (preloading) {
        return <MainPreloader />
    }

    return (
        <div className={styles.app}>
            <Router>
                {!isAuth ? (
                    <RoutesDom>
                        <Route path={Routes.AUTH} element={<Auth />} />
                        <Route
                            path="*"
                            element={<Navigate replace to={Routes.AUTH} />}
                        />
                    </RoutesDom>
                ) : (
                    <RoutesDom>
                        <Route path={Routes.HOME} element={<MainPage />} />

                        <Route path={Routes.STAFF} element={<StaffPage />} />
                        <Route path={Routes.LOGS} element={<LogsPage />} />
                        <Route path={Routes.HOME} element={<MainPage />} />
                        <Route
                            path={Routes.PERSONAL_CARD}
                            element={<PersonalCard />}
                        />
                        <Route path={Routes.TABEL} element={<TabelsPage />} />
                        <Route
                            path={Routes.TABEL_SETTINGS}
                            element={<TabelsSettings />}
                        />

                        <Route
                            path="*"
                            element={<Navigate replace to={Routes.HOME} />}
                        />
                    </RoutesDom>
                )}
            </Router>
        </div>
    )
}

export default App
