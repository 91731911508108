import React, { FC } from 'react'
import { Button, ButtonColors } from '../../simples/Button'
import { ModalClose } from '../../simples/ModalClose'
import styles from './AlertModal.module.scss'


type Props = {
    onSubmit: () => void
    onCancel: () => void
    onClose: () => void
}

const AlertModal:FC<Props> = ({onSubmit, onCancel, onClose}) => {
    return (
        <ModalClose
            onClose={onClose}
        >
            <h2 className={styles.title}>Внимание</h2>
            <p className={styles.info}>Данные не были сохранены</p>
            <p className={styles.info}>Сохранить данные перед закрытием?</p>

            <div className={styles.btnBlock}>
                <Button
                    onClick={onCancel}
                    color={ButtonColors.Light}
                >Нет</Button>
                <Button
                onClick={onSubmit}
                    color={ButtonColors.Default}
                >Да</Button>
            </div>

        </ModalClose>
    )
}

export default React.memo(AlertModal)