import React, { FC, useEffect, useState } from 'react'
import { TextField } from '../../../../../simples/TextField'
import { Title } from '../../../../../simples/Title'
import styles from './Styles.module.scss'
import { ReactComponent as SearchIcon } from '../../../../../../assets/icons/search.svg'

type Props = {
    onSearch: (search: string) => void
}
const Header: FC<Props> = ({ onSearch }) => {

    const [value, setValue] = useState<string>('')

    useEffect(() => {
        onSearch(value)
    }, [onSearch, value])
    return (
        <div className={styles.root}>
            <Title>Документы</Title>
            <div className={styles.inputWrapper}>
                <TextField
                    classes={{
                        root: styles.input
                    }}
                    onChange={setValue}
                    value={value}
                    icon={<SearchIcon />}
                />
            </div>
        </div>
    )
}

export default React.memo(Header)