import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { ButtonWithIcon } from '../../../../simples/ButtonWithIcon'
import styles from './RightPanel.module.scss'
import { ReactComponent as EraseIcon } from '../../../../../assets/icons/erase.svg'
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/btn_arrow.svg'
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/print.svg'
import { ReactComponent as XlsIcon } from '../../../../../assets/icons/xls.svg'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import {
    activeCellTable,
    changeCell,
    setActiveTabelFinalize,
} from '../../../../../store/slice/tabel'
import { TabelsService } from '../../../../../services/TabelsService'
// @ts-ignore
import $message from 'message-like-antd'
import FileSaver from 'file-saver'
import { _API_PREFIX } from '../../../../../constants/constants'
import PrintModal from './PrintModal'
import { ExportType, tabelFilter } from '../../../../../store/slice/filters'
import printJS from 'print-js'
import { selectUser } from '../../../../../store/slice/auth'
import {
    AccessRightType,
    RolesKind,
    TabelTableType,
    TableNames,
} from '../../../../../types/types'
import {
    ListContext,
    ArrowEnableContext,
    KeyDirection,
    EditDayContext,
    UpdateDataContext,
    ClearAllDayContext,
} from '../../config'
import { Button, ButtonColors } from '../../../../simples/Button'
import { ModalClose } from '../../../../simples/ModalClose'
import { DropZone } from '../../../../complexes/DropZone'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../../constants/config'
import { FinalizationModal } from '../../../../complexes/FinalizationModal'
import { User } from '../../../../../services/UserService'
import { VACATION_VAL } from '../../../../../constants/tabel'

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

const BottomPart = () => {
    const [isImportModal, setisImportModal] = useState<boolean>(false)
    const [fileName, setFileName] = useState<string>('')
    const [file, setFile] = useState<File>()
    const [finalModal, setFinalModal] = useState<boolean>(false)
    const [finalize, setFinaloze] = useState<string>()
    // финализирован ли текущий табель
    const isFinalized = useAppSelector((state) => state.tabel.isFinalize)
    const [, setEditDay] = useContext(EditDayContext)
    const updateData = useContext(UpdateDataContext)
    const clearDay = useContext(ClearAllDayContext)

    const currentUser = useAppSelector(selectUser)
    const canEdit = useMemo(() => {
        return (
            currentUser?.accessRights.includes(
                AccessRightType.ChangeTabelData
            ) ||
            currentUser?.accessRights.includes(
                AccessRightType.EditTabelSelfGroup
            ) ||
            currentUser?.role === RolesKind.ADMIN
        )
    }, [currentUser?.accessRights, currentUser?.role])
    const canFinalize = useMemo(() => {
        return (
            currentUser?.accessRights.includes(AccessRightType.FinalizeTabel) ||
            currentUser?.role === RolesKind.ADMIN
        )
    }, [currentUser?.accessRights, currentUser?.role])

    const [list, setList] = useContext(ListContext)

    // сотояние включенной стрелки
    const [arrowDirection, setArrowDirection] = useContext(ArrowEnableContext)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const aCell = useAppSelector(activeCellTable)
    const aUser = useAppSelector((state) => state.tabel.activeUser)

    const filter = useAppSelector(tabelFilter)

    const [showPrint, setShowPrint] = useState<'print' | 'export'>()

    /**
     * изменение активной ячейки
     */
    const onChangeDirectionCell = useCallback(
        (type: KeyDirection) => {
            switch (type) {
                case KeyDirection.DOWN:
                    setArrowDirection(KeyDirection.DOWN)
                    break

                case KeyDirection.RIGHT:
                    setArrowDirection(KeyDirection.RIGHT)
                    break

                default:
                    break
            }
        },
        [setArrowDirection]
    )

    /** Обновление значения инпута */
    const changeValueInput = useCallback(
        (value: string) => {
            if (value === VACATION_VAL) return
            if (aCell && aUser) {
                let newList: TabelTableType[] = [...list]
                const aUserListIndex = list.findIndex(
                    (el) => el.id === aUser.id
                )
                const currUserlRow = list[aUserListIndex]
                const aTabelTypes = currUserlRow.tabelsTypes
                const aTabelIndex = aTabelTypes.findIndex(
                    (el) => el.id === aCell?.tabelId
                )
                const aTabels = aTabelTypes[aTabelIndex].tabels
                const currValueTabelCell = aTabels[Number(aCell.day)]
                const aCellVal = aCell?.value
                const newValueTabelCell = {
                    id: (aCellVal ? aCellVal?.id : aCellVal) || 0,
                    hours: value,
                    iaVacation: currValueTabelCell.isVacation,
                }
                const newTabelTypes = [...aTabelTypes]
                newTabelTypes[aTabelIndex] = {
                    ...newTabelTypes[aTabelIndex],
                    //@ts-ignorets
                    tabels: {
                        ...aTabels,
                        [Number(aCell.day)]: newValueTabelCell,
                    },
                }
                newList[aUserListIndex] = {
                    ...currUserlRow,
                    tabelsTypes: newTabelTypes,
                }

                setList(newList)
            }
        },
        [aCell, aUser, setList, list]
    )

    /**
     * получаем таблицу в эксель
     */
    const onExportExcel = useCallback(
        async (e: {
            branchId?: string
            tabelTypeId?: string
            tabelGroupId?: string
            date: {
                month: string
                year: number
            }
        }) => {
            const response = await TabelsService.getTabelsTablesExl({
                export: ExportType.XLSX,
                ...e.date,
                tabelGroupId: e.tabelGroupId ? e.tabelGroupId : undefined,
                tabelTypeId: e.tabelTypeId ? e.tabelTypeId : undefined,
                branchId: e.branchId ? e.branchId : undefined,
            })

            const fileName =
                response.fileName.split('/')[
                    response.fileName.split('/').length - 1
                ]
            FileSaver.saveAs(_API_PREFIX + response.fileName, fileName)

            setShowPrint(undefined)
        },
        []
    )

    /**
     *
     * получаем ПДФ для вывода на печать
     */
    const onPrintPdf = useCallback(
        async (e: {
            tabelTypeId?: string
            tabelGroupId?: string
            branchId?: string
            date: {
                month: string
                year: number
            }
        }) => {
            const response = await TabelsService.getTabelsTablesExl({
                export: ExportType.PDF,
                ...e.date,
                tabelGroupId: e.tabelGroupId ? e.tabelGroupId : undefined,
                tabelTypeId: e.tabelTypeId ? e.tabelTypeId : undefined,
                branchId: e.branchId ? e.branchId : undefined,
            })
            setShowPrint(undefined)
            printJS({
                printable: _API_PREFIX + response.fileName,
                type: 'pdf',
                showModal: true,
                modalMessage: 'Подготавливаем документ к печати...',
            })
        },
        []
    )

    /**
     * Получаем экспорт за год
     * @param year -
     */
    const onFullYearExport = useCallback(async (year: number) => {
        try {
            const response = await TabelsService.getTabelFullYearXls(year)
            const fileName =
                response.fileName.split('/')[
                    response.fileName.split('/').length - 1
                ]
            FileSaver.saveAs(_API_PREFIX + response.fileName, fileName)

            setShowPrint(undefined)
        } catch (error) {}
    }, [])

    /**
     * нажатие Submit в модальном
     * @param e выбранные параметры в модалке печати или экспорта
     */
    const onHandleSubmit = (e: {
        tabelTypeId?: string
        tabelGroupId?: string
        branchId?: string
        date: {
            month: string
            year: number
        }
        year?: number
    }) => {
        if (!e.year) {
            if (showPrint === 'export') {
                onExportExcel(e)
            } else {
                onPrintPdf(e)
            }
        } else {
            onFullYearExport(e.year)
        }
    }
    /* кнопка финализации отчета */
    const finalizeBtn: string = useMemo((): string => {
        return !!!isFinalized ? 'Финализировать' : 'Разблокировать'
    }, [isFinalized])

    // modal
    const print = () => {
        console.log('print im waiting backend')
        setisImportModal(true)
    }

    const onUpload = (files: Array<File>) => {
        setFileName(files[0].name)
        setFile(files[0])
    }

    const sendOnBack = async () => {
        if (file) {
            const response = await TabelsService.uploadTabel({ file })
            if (response) {
                $message.success('Табель успешно импортирован')
                setFile(undefined)
                setFileName('')
                setisImportModal(false)
            } else {
                $message.error('Табель небыл импортирован')
            }
        }
    }

    const changeACell = useCallback(() => {
        dispatch(
            changeCell({
                right: arrowDirection === KeyDirection.RIGHT,
                down: arrowDirection === KeyDirection.DOWN,
            })
        )
    }, [arrowDirection, dispatch])

    //изменение значения в ячейки
    const onChangeValue = useCallback(
        async (val: string) => {
            changeValueInput(val)
            setEditDay(val)
            await updateData(val)
            changeACell()
        },
        [changeACell, changeValueInput, setEditDay, updateData]
    )

    const onClearCell = useCallback(() => {
        onChangeValue('0')
    }, [onChangeValue])

    const onFinalizeTable = useCallback(async () => {
        if (!isFinalized) {
            try {
                const response = await TabelsService.finalTable(
                    `${filter?.year}-${filter?.month}-01`
                )
                dispatch(setActiveTabelFinalize(1))
                $message.destroy()
                $message.success(response.msg, [750])
                setFinalModal(false)
            } catch (error: any) {
                if (error.response.data.status === 403) {
                    $message.destroy()
                    $message.error(error.response.data.message, [750])
                } else {
                    const errors = error.response.data.errors
                    $message.destroy()
                    for (let key of errors) {
                        $message.error(key, [750])
                    }
                }
                setFinalModal(false)
            }
        } else {
            try {
                const response = await TabelsService.unfinalizeTable(
                    `${filter?.year}-${filter?.month}-01`
                )
                $message.destroy()
                $message.success(response, [750])
                dispatch(setActiveTabelFinalize(0))
                setFinalModal(false)
            } catch (error: any) {
                if (error.response.data.status === 403) {
                    $message.destroy()
                    $message.error(error.response.data.message, [750])
                } else {
                    const errors = error.response.data.errors
                    $message.destroy()
                    for (let key of errors) {
                        $message.error(key, [750])
                    }
                }
                setFinalModal(false)
            }
        }
    }, [dispatch, filter?.month, filter?.year, isFinalized])

    const onClearDay = useCallback(() => {
        clearDay(aCell)
        dispatch(
            changeCell({
                right: true,
            })
        )
    }, [aCell, clearDay, dispatch])

    const onNavigateToLogs = useCallback(() => {
        navigate(`${Routes.LOGS}?table=${TableNames.tabels}`)
    }, [navigate])

    const userFinal = useCallback(async () => {
        try {
            const response = await User.UserFinal(
                `${filter?.year}-${filter?.month}`
            )
            console.log(response?.user)
            if (response) {
                setFinaloze(
                    `${response.msg} ${
                        !!!response.user!.lastName
                            ? ''
                            : response.user!.lastName + ''
                    } ${
                        !!!response.user!.firstName
                            ? ''
                            : response.user!.firstName
                    }  ${
                        !!!response.user!.secondName
                            ? ''
                            : response.user!.secondName + ''
                    } ${response.date}`
                )
                return
            }
        } catch (error) {
            setFinaloze('Месяц не финализирован')
        }
    }, [filter?.month, filter?.year])

    useEffect(() => {
        if (isFinalized) {
            userFinal().then()
        } else {
            setFinaloze('Месяц не финализирован')
        }
    }, [isFinalized, filter.year, filter.month, userFinal])

    return (
        <div>
            {canEdit && (
                <div className={styles.label}>
                    Вставить значение в выбранную ячейку:
                </div>
            )}

            {canEdit && (
                <div className={styles.hours}>
                    <span onClick={onClearCell} className={styles.hourItem}>
                        0
                    </span>
                    {hours.map((h) => (
                        <span
                            key={h}
                            onClick={() => onChangeValue(h.toString())}
                            className={styles.hourItem}
                        >
                            {h}
                        </span>
                    ))}

                    <span
                        onClick={() => onChangeValue(VACATION_VAL)}
                        className={styles.hourItem}
                    >
                        От
                    </span>
                </div>
            )}

            {canEdit && (
                <div className={styles.cellBlock}>
                    <ButtonWithIcon
                        icon={<EraseIcon />}
                        label={'Очистить день'}
                        onClick={onClearDay}
                    />

                    <div className={styles.cellNav}>
                        <ButtonWithIcon
                            isActive={arrowDirection === KeyDirection.DOWN}
                            classes={{
                                root: styles.navBtn,
                            }}
                            icon={<ArrowIcon className={styles.downIcon} />}
                            onClick={() =>
                                onChangeDirectionCell(KeyDirection.DOWN)
                            }
                        />
                        <ButtonWithIcon
                            isActive={arrowDirection === KeyDirection.RIGHT}
                            classes={{
                                root: styles.navBtn,
                            }}
                            icon={<ArrowIcon />}
                            onClick={() =>
                                onChangeDirectionCell(KeyDirection.RIGHT)
                            }
                        />
                    </div>
                </div>
            )}
            <div className={styles.buttons}>
                <div className={styles.btnLeft}>
                    <ButtonWithIcon
                        onClick={() => setShowPrint('print')}
                        label={'Печать'}
                        icon={<PrintIcon />}
                    />
                </div>
                <ButtonWithIcon
                    onClick={() => setShowPrint('export')}
                    label={'Экспорт в xls'}
                    icon={<XlsIcon />}
                />
            </div>
            <div className={styles.rightBlock}>
                <Button
                    onClick={print}
                    color={ButtonColors.Light}
                    className={styles.changeBtn}
                >
                    Импортировать проходную
                </Button>
                {/* Todo: uncomment up to will work */}
                {/* <Button
                    onClick={onNavigateToLogs}
                    color={ButtonColors.Light}
                    className={styles.changeBtn}
                >
                    Изменения
                </Button> */}
                {canFinalize && (
                    <Button
                        onClick={onFinalizeTable}
                        color={ButtonColors.Light}
                        className={styles.finalBtn}
                    >
                        {finalizeBtn}
                    </Button>
                )}
            </div>
            <p className={styles.finalText}>{finalize}</p>
            {showPrint && (
                <PrintModal
                    type={showPrint}
                    onSubmit={onHandleSubmit}
                    onClose={() => setShowPrint(undefined)}
                />
            )}
            {isImportModal ? (
                <>
                    <ModalClose
                        classes={{
                            modal: styles.modalDrop,
                        }}
                        onClose={() => setisImportModal(false)}
                        title={'Импорт Табеля'}
                    >
                        <div className={styles.dropZone}>
                            <DropZone name={fileName} onUpload={onUpload} />
                        </div>
                        <button onClick={sendOnBack} className={styles.btnSend}>
                            Импортировать
                        </button>
                    </ModalClose>
                </>
            ) : (
                <></>
            )}
            {finalModal && (
                <FinalizationModal
                    onClose={() => setFinalModal(false)}
                    onSubmit={onFinalizeTable}
                />
            )}
        </div>
    )
}

export default React.memo(BottomPart)
