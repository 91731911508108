import produce from 'immer'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DepartamentService } from '../../../../../services/DepartamentService'
import { AccessRightType, Department, RolesKind } from '../../../../../types/types'
import { DeleteModal } from '../../../../complexes/DeleteModal'
import { Button, ButtonColors } from '../../../../simples/Button'
import styles from './Departments.module.scss'
import Item from './Item'
// @ts-ignore
import $message from 'message-like-antd'
import { useAppSelector } from '../../../../../hooks/store'
import { selectUser } from '../../../../../store/slice/auth'



const Departments: FC = () => {

    const user = useAppSelector(selectUser)

    const [list, setList] = useState<Array<Department>>([])
    const [activeDepart, setActiveDepart] = useState<Department>()
    const currentId = useRef<number>(0)

    const canEdit = useMemo(() => {
        return user?.accessRights.includes(AccessRightType.EditFilialsTable) || user?.role === RolesKind.ADMIN
    },[user])


    const [showDelModal, setShowDelModal] = useState<number>()

    const [isEdit, setIsEdit] = useState<boolean>(false)

    const mainBlock = useRef<HTMLDivElement>(null)

    const has = useRef(true)
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await DepartamentService.getDepartaments({
            offset: offset.current,
            limit: 30
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setList([...list, ...result])
    }

    const onDeparmetClick = useCallback((dep: Department) => {
            setActiveDepart(dep);

    }, [])

    useEffect(() => {
        load().then()
    }, [])

    /**
     * Изменяем имя активного филиала
     */
    const onHandleChange = useCallback((id: number, e: string) => {
        setList(produce(
            (draft) => {
                const find = draft.find(i => i.id === id)
                if (find) {
                    find.name = e
                }
            }
        ))
    }, [])

    /**
     * Добавляем новый филиал
     */
    const onAddDepartment = useCallback(() => {
        setList(produce(
            (draft) => {

                const newDepart = {
                    id: new Date().getTime(),
                    name: '',
                    description: '',
                    isDraft: true
                }
                setActiveDepart(newDepart)
                draft.push(newDepart)
            }
        ))
        setIsEdit(true)
        
    }, [])

    /**
     * Создаем или обновляем филиал
     */
    const onHandleEditSave = useCallback(async () => {
        if(!isEdit){
            setIsEdit(true)
        } else {
            if (activeDepart) {
                const find = list.find(i => i.id === activeDepart.id)
                if (!activeDepart.isDraft) {
                    try {
                        if (find) {
                            const response = await DepartamentService.updateDepart(activeDepart.id, { name: find.name })
                            setActiveDepart(undefined)
                            setIsEdit(false)
                            $message.success(`Филиал ${response.name} обновлен`)
                        }
                    } catch (error:any) {
                        if(error.response.data.status === 403){
                            $message.error(error.response.data.message, [750])
                        } else {
                            const errors = error.response.data.errors
                            $message.destroy()
                            for(let key of errors){
                                $message.error(key, [750])
                            }
                        }
                    }
                } else {
                    try {
                        if (find) {
                            const response = await DepartamentService.createDepart({ name: find.name })
                            $message.success(`Филиал ${response.name} добавлен`)
                            
                            // после сохранения обновляем филиал в списке записывая данные с бэка
                            setList(produce(
                                draft => {
                                    const find = draft.find(i => i.id === activeDepart.id)
                                    if(find){
                                        find.id = response.id
                                        find.name = response.name
                                        find.description = response.description
                                        find.isDraft = false
                                    }

                                }
                            ))
                            setActiveDepart(undefined)
                            setIsEdit(false)
                        }
                    } catch (error: any) {
                        if(error.response.data.status === 403){
                            $message.error(error.response.data.message, [750])
                        } else {
                            const errors = error.response.data.errors
                            $message.destroy()
                            for(let key of errors){
                                $message.error(key, [750])
                            }
                        }
                    }
                }

            }
            
        }
       
    }, [activeDepart, isEdit, list])


    // Метод сброса фокуса с выбранного элемента
    const loseFocus = useCallback(() => {
        setList(produce(
            draft => {
                const find = draft.find(i => i.id === activeDepart?.id)
                if(!find?.name && !activeDepart?.name){
                    setList(prev => prev.filter(i => i.id !== activeDepart?.id))
                }
                if (find && find?.name !== activeDepart?.name){
                    find.name = activeDepart?.name || ''
                }
            }
        ))
        setActiveDepart(undefined)
        setIsEdit(false)
    }, [activeDepart])

    // Метод закрытия селектора при клике вне самого селектора
    const closeSelectOutOfBlock = useCallback(
        (event: any) => {
            if (mainBlock && mainBlock.current) {
                // Проверка добавлена для устранения бага в Firefox
                if (!mainBlock.current.contains(event.target)) {
                    loseFocus()
                }
            }
        },
        [loseFocus]
    )

    // Установка/удаление обработчика события на документе.
    useEffect(() => {
        document.addEventListener('click', closeSelectOutOfBlock, false)
        return () => {
            document.removeEventListener('click', closeSelectOutOfBlock, false)
        }
    }, [closeSelectOutOfBlock])


    /**
     * Функция удаления Филиала
     */
    const onDeleteDepartment = useCallback(async () => {
        if (!showDelModal) {
            return
        }
        try {
            const response = await DepartamentService.deleteDepartment(showDelModal)
            setList(prev => prev.filter(i => i.id !== showDelModal))
            setShowDelModal(undefined)
            $message.success(response)

        } catch (error: any) {
            if(error.response.data.status === 403){
                $message.error(error.response.data.message, [750])
            } else {
                const errors = error.response.data.errors

                for(let key of errors){
                    $message.error(key, [750])
                }
            }

        }
    }, [showDelModal])

    return (
        <>
            <div
                ref={mainBlock}
                className={styles.list}>
                {list.map((department) => <Item
                    key={department.id}
                    isEdit={(department.id === activeDepart?.id) && isEdit}
                    department={department}
                    activeDepart={activeDepart}
                    onClick={onDeparmetClick}
                    onChange={onHandleChange}
                />)}
               {canEdit && <div className={styles.btnBlock}>
                    <Button
                        onClick={onAddDepartment}
                        className={styles.button}
                        color={ButtonColors.Main}
                    >Добавить филиал</Button>

                    {!!activeDepart && <Button
                        onClick={onHandleEditSave}
                        className={styles.button}
                        color={ButtonColors.Main}
                    >{!isEdit ? "Редактировать" : "Сохранить"}</Button>}

                    {!!activeDepart && !activeDepart.isDraft && <Button
                        onClick={() => setShowDelModal(activeDepart.id)}
                        className={styles.button}
                        color={ButtonColors.Main}
                    >Удалить</Button>}
                </div>}
            </div>
            {/* {showModal && <AlertModal
                onSubmit={onHandleEditSave}
                onCancel={onModalCancel}
                onClose={() => {
                    setShowModal(false)
                    setActiveDepart(prev => prev)
                }}
            />} */}

            {showDelModal && <DeleteModal
                onSubmit={onDeleteDepartment}
                onCancel={() => setShowDelModal(undefined)}
                text={'При удалении филиала вы потеряете все данные связанные с данным филиалом'}
            />}
        </>
    )
}

export default Departments