import React, { FC } from 'react';
import styles from './ListModal.module.scss'


interface Options {
    value: number
    label: string
}

type Props = {
    onClick: (id: number) => void
    options: Array<Options>
    position?: 'bottom' | 'top'
}

const ListModal: FC<Props> = ({
    onClick,
    options,
    position = 'top'
}) => {
    return (
        <div 
        style={{
            top: position === 'bottom' ? '100%' : '-100%'
        }}
        className={styles.root}>
            {
                options.map((option) => <div
                    className={styles.item}
                    onClick={() => onClick(option.value)}
                    key={option.value}>{option.label}</div>)
            }
        </div>
    )
}
export default ListModal