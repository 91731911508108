import classNames from 'classnames'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { stringDate } from '../../../../../../../../constants/helper'
import { Input } from '../../../../../../../simples/Input'
import { Selector } from '../../../../../../../simples/Selector'
import { Title } from '../../../../../../../simples/Title'
import { ActiveUserContext, AddImployerContext } from '../../../../../StaffPage'
import { ReactComponent as MarkIcon } from '../../../../../../../../assets/icons/mark.svg'
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/pencil.svg'
import styles from './PartsInfoUser.module.scss'
import { OptionType } from '../../../../../../../simples/Selector/Selector'
import { useAppSelector } from '../../../../../../../../hooks/store'
import { AccessRightType, RolesKind } from '../../../../../../../../types/types'
import { selectUser } from '../../../../../../../../store/slice/auth'

type ErrorsType = {
    mail?: string
    add_mail?: string
}

const GeneralInfo: FC = () => {

    const currentUser = useAppSelector(selectUser)

    /**
    * editble user
    */
    const [editUser, setEditUser] = useContext(AddImployerContext)



    const positions = useAppSelector((state) => state.storage.positions)
    const filials = useAppSelector((state) => state.storage.filials)
    const groups = useAppSelector((state) => state.storage.tabelGroups)
    const role = useAppSelector((state) => state.storage.roles)


    const [error, setError] = useState<ErrorsType>({})

    const [user, setUser] = useContext(ActiveUserContext)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(true)


    /**
     *  может ли редактировать пользователь других пользователей
     */
    const canEdit = useMemo(() => {
        return currentUser?.accessRights.includes(AccessRightType.EditStaff) || currentUser?.role === RolesKind.ADMIN ||
            (currentUser?.accessRights.includes(AccessRightType.EditTabelSelfGroup) && currentUser.id === user?.boss)
    }, [currentUser?.accessRights, currentUser?.id, currentUser?.role, user?.boss])

    const onSetEditAndSave = useCallback(() => {
        setEditUser(user)
    }, [setEditUser, user])

    const positionOptions = useMemo((): OptionType[] => {
        const options: OptionType[] = positions?.map((i) => ({
            label: i.name,
            value: `${i.id}`,
        })) as OptionType[]
        return options
    }, [positions])

    const filialsOptions = useMemo((): OptionType[] => {
        const options: OptionType[] = filials?.map((i) => ({
            label: i.name,
            value: `${i.id}`,
        })) as OptionType[]
        return options
    }, [filials])

    const groupsOptions = useMemo((): OptionType[] => {
        const options: OptionType[] = groups?.map((i) => ({
            label: i.name,
            value: `${i.id}`,
        })) as OptionType[]
        return options
    }, [groups])

    const rolesOptions = useMemo((): OptionType[] => {
        const options: OptionType[] = role?.map((i) => ({
            label: i.description,
            value: `${i.name}`,
        })) as OptionType[]
        return options
    }, [role])


    const onChangeBranches = useCallback((e: string) => {
        if (user.branches) {
            if (user.branches.includes(+e)) {
                const newBranches = user.branches.filter(i => i !== +e)
                setUser((prev) => ({
                    ...prev,
                    branches: newBranches,
                }))
            } else {
                const newBranches = [...user.branches, +e]
                setUser((prev) => ({
                    ...prev,
                    branches: newBranches,
                }))
            }
        } else {
            setUser((prev) => ({
                ...prev,
                branches: [+e],
            }))
        }


    }, [setUser, user.branches])


    const onChangeGroups = useCallback((e: string) => {
        if (user.tabelGroups) {
            if (user.tabelGroups.includes(+e)) {
                const newBranches = user.tabelGroups.filter(i => i !== +e)
                setUser((prev) => ({
                    ...prev,
                    tabelGroups: newBranches,
                }))
            } else {
                const newBranches = [...user.tabelGroups, +e]
                setUser((prev) => ({
                    ...prev,
                    tabelGroups: newBranches,
                }))
            }
        } else {
            setUser((prev) => ({
                ...prev,
                tabelGroups: [+e],
            }))
        }


    }, [setUser, user.tabelGroups])

    /**
     * valide and change mail
     */

    const onChangeMail = (e: string) => {
        setError({ ...error, mail: '' })
        setUser((prev) => ({ ...prev, email: e }))
    }
    const onChangeAddMail = (e: string) => {
        setError({ ...error, add_mail: '' })
        setUser((prev) => ({ ...prev, addEmail: e }))
    }


    return (
        <div className={styles.root}>
            <div className={styles.top}>
                <div
                    onClick={() => setIsOpen((prev) => !prev)}
                    className={classNames(
                        styles.flexBlock,
                        styles.cursorPointer
                    )}
                >
                    <MarkIcon
                        className={classNames(styles.icon, {
                            [styles.isOpen]: isOpen,
                        })}
                    />
                    <Title size={14}>Основные данные</Title>
                </div>
                {canEdit && <div
                    onClick={onSetEditAndSave}
                    className={classNames(
                        styles.flexBlock,
                        styles.cursorPointer
                    )}
                >
                    <EditIcon className={styles.icon} />
                    <span>{'Изменить'}</span>
                </div>}
            </div>
            {isOpen && (
                <div className={styles.items}>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Табельный номер</div>
                        <div className={classNames(styles.rightItem)}>
                            <Input
                                disabled={true}
                                value={`${user.tabelNumber}`}
                                onChange={(e) =>
                                    setUser((prev) => ({
                                        ...prev,
                                        tabelNumber: +e,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>День рождения</div>
                        <div className={classNames(styles.rightItem)}>
                            {isEdit ? (
                                <Input
                                    mask={'99.99.9999'}
                                    disabled={!isEdit}
                                    value={user.dateOfBirth}
                                    onChange={(e) =>
                                        setUser((prev) => ({
                                            ...prev,
                                            dateOfBirth: e,
                                        }))
                                    }
                                />
                            ) : (
                                <span>{stringDate(user.dateOfBirth)}</span>
                            )}
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Роль</div>
                        <div className={classNames(styles.rightItem)}>
                            <Selector
                                classes={{
                                    root: styles.selector,
                                    label: styles.selectorLabel
                                }}
                                disabled={!isEdit}
                                options={rolesOptions}
                                value={`${user?.role}` || ''}
                                onChange={(e) =>
                                    setUser((prev) => ({
                                        ...prev,
                                        role: e as RolesKind,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    {user.role === RolesKind.CHIEF && <div className={styles.item}>
                        <div className={styles.leftItem}>Доступные группы</div>
                        <div className={classNames(styles.rightItem)}>
                            <Selector
                                classes={{
                                    root: styles.selector,
                                    label: styles.selectorLabel
                                }}
                                label={'группы'}
                                disabled={!isEdit}
                                options={groupsOptions}
                                value={''}
                                onChange={onChangeGroups}
                                values={user.tabelGroups || []}
                                isMultiple

                            />
                        </div>
                    </div>}
                    {user.role === RolesKind.SUPERVISOR && <div className={styles.item}>
                        <div className={styles.leftItem}>Доступные филиалы</div>
                        <div className={classNames(styles.rightItem)}>
                            <Selector
                                classes={{
                                    root: styles.selector,
                                    label: styles.selectorLabel
                                }}
                                label={'филиалы'}
                                disabled={!isEdit}
                                options={filialsOptions}
                                value={''}
                                values={user.branches || []}
                                onChange={onChangeBranches}
                                isMultiple
                            />
                        </div>
                    </div>}
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Должность</div>
                        <div className={classNames(styles.rightItem)}>
                            <span>{user.position?.name || ''}</span>

                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Филиал</div>
                        <div className={classNames(styles.rightItem)}>
                            <span>{user.branch?.name || ''}</span>

                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Группа</div>
                        <div className={classNames(styles.rightItem)}>
                            <span>{user.tabelGroup?.name || ''}</span>

                        </div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.leftItem}>Телефон</div>
                        <div className={classNames(styles.rightItem)}>
                            <Input
                                mask="+7(999)999-99-99"
                                disabled={!isEdit}
                                value={user?.phone || ''}
                                onChange={(e) =>
                                    setUser((prev) => ({ ...prev, phone: e }))
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Внутр. телефон</div>
                        <div className={classNames(styles.rightItem)}>
                            <Input
                                mask="999999"
                                disabled={!isEdit}
                                value={user.internalPhone || ''}
                                onChange={(e) =>
                                    setUser((prev) => ({
                                        ...prev,
                                        internalPhone: e,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>E-mail</div>
                        <div className={classNames(styles.rightItem)}>
                            <span><a href={`mailto:${user.email}`}>{user.email}</a></span>

                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Доп. e-mail</div>
                        <div className={classNames(styles.rightItem)}>
                            <Input
                                error={error.add_mail}
                                disabled={!isEdit}
                                value={user.addEmail || ''}
                                onChange={onChangeAddMail}
                            />
                        </div>
                    </div>
                    <span className={styles.line}></span>
                </div>
            )}
        </div>
    )
}

export default React.memo(GeneralInfo)
