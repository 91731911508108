import classNames from 'classnames'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DAYS_NAMES_FULL } from '../../../../../constants/constants'
import { getTime, prindTimeDate, printTime, stringDate } from '../../../../../constants/helper'
import { useAppSelector } from '../../../../../hooks/store'
import { TabelsService } from '../../../../../services/TabelsService'
import { activeCellTable, activeDate } from '../../../../../store/slice/tabel'
import { AccessRightType, EntranceType, OneTabelType, RolesKind } from '../../../../../types/types'
import { Title } from '../../../../simples/Title'
import styles from './RightPanel.module.scss'

// import { ReactComponent as EntranceIcon } from '../../../../../assets/icons/entrance.svg'
// import { ReactComponent as ExitIcon } from '../../../../../assets/icons/exit.svg'
import { Button } from '../../../../simples/Button'
// import TimeChanger from './parts/TimeChanger'

import produce from 'immer'
// @ts-ignore
import $message from 'message-like-antd'
import { ListModal } from '../../../../complexes/ListModal'
import { tabelFilter } from '../../../../../store/slice/filters'
import { selectUser } from '../../../../../store/slice/auth'
import { ListContext } from '../../config'
import { getOnlyTimeFromDate } from '../../../../../utils/composeString'
import { VACATION_VAL } from '../../../../../constants/tabel'


type Props = {

}



const TopPart: FC<Props> = () => {

    const currentUser = useAppSelector(selectUser)

    const canEdit = useMemo(() => {
        return currentUser?.accessRights.includes(AccessRightType.ChangeTabelData) || currentUser?.accessRights.includes(AccessRightType.EditTabelSelfGroup) || currentUser?.role === RolesKind.ADMIN
    }, [currentUser?.accessRights, currentUser?.role])

    const [, setList] = useContext(ListContext)
    // дата в активной ячеки в формате yyyy-mm-ddThh:mm:ss
    const aDate = useAppSelector(activeDate)
    // активная ячейка
    const aCell = useAppSelector(activeCellTable)
    // табельные типы
    const tabelsTypes = useAppSelector(state => state.tabel.tabelTypeIds)
    // табельный номер выбранного пользователя
    const tabelNumber = useAppSelector(state => state.tabel.userTabelNumber)
    // фильтр таблицы
    const filter = useAppSelector(tabelFilter)
    // активный пользователь
    const aUser = useAppSelector(state => state.tabel.activeUser)

    // текуший табель
    const [currentTabel, setCurrentTabel] = useState<OneTabelType>({} as OneTabelType)
    // редактируем весь месяц или один день
    const [isEditTime, setIsEditTime] = useState<EntranceType>()

    /**
     * отображение выбора смены типа табеля
     */
    const [isChangeTabelType, setIsChangeTabelType] = useState<boolean>(false)

    /**
     * опции для выбора типа табеля
     */
    const tabelTypesOptions = useMemo(() => {
        if (aUser && aUser.tabelsTypes) {
            return aUser.tabelsTypes.map((i) => ({
                label: i.name,
                value: i.id
            }))
        } else {
            return []
        }
    }, [aUser])


    /**
     * получение одного табеля при выборе ячейки
     */
    const getOneTabel = async (id: number) => {
        let prevData = {}
        if (aDate && aUser && aCell) {
             prevData = await TabelsService.getEntranceData({
                date: aDate.split('T')[0],
                userId: aUser.id,
                tableId: aCell.tabelId,
            })
        }
        if (aCell && aCell.value && aCell?.value.id) {
            const response = await TabelsService.getTabelById(aCell?.value.id)
            setCurrentTabel({...prevData, ...response});
        } else {
            setCurrentTabel({workHours: VACATION_VAL} as OneTabelType);
        }
    }

    /**
     * получаем данные по проходной
     */
    const getCheckpointData = async () => {
        if (aDate && aUser && aCell) {
            const response = await TabelsService.getEntranceData({
                date: aDate.split('T')[0],
                userId: aUser.id,
                tableId: aCell.tabelId,

            })
            setCurrentTabel(response)
        }
    }

    /**
     * получаем табель только если в нем есть значение и ид
     */
    useEffect(() => {
        if (aCell && aCell.value) {
            getOneTabel(aCell.value.id)
        } else {
            setCurrentTabel({} as OneTabelType)
            getCheckpointData()
        }
        setIsEditTime(undefined)
    }, [aCell])

    // название табеля
    const tabelName: string = useMemo((): string => {
        const find = tabelsTypes?.find(i => i.id === aCell?.tabelId)?.name
        return find || ''
    }, [tabelsTypes, aCell])



    /***
     * Время по проходной
     */
    const time = useMemo((): string => {
        let differtentTime = 0
        if (currentTabel.entrance && currentTabel.exit) {
            differtentTime = getTime(currentTabel.entrance, currentTabel.exit)
        }


        return printTime(differtentTime)

    }, [currentTabel?.entrance, currentTabel?.exit,])


    /**
    * получение табеля пользователя
    */
    const getOneTabelUser = useCallback(async () => {
        const response = await TabelsService.getTabelsTables({
            ...filter,
            tabelNumber
        })
        setList(produce(
            draft => {
                const find: any = draft.find((item) => item.tabelNumber === response[0].tabelNumber)
                if (find) {

                    find.tabelsTotal = response[0].tabelsTotal
                    find.tabelsTypes = response[0].tabelsTypes
                }
            }
        ))
    }, [filter, tabelNumber, setList])

    /**
 * меняем на ввод данных
 */
    const onChangeTabelEntrance = useCallback(async (type: EntranceType) => {

        const response = await TabelsService.setTimeByEntrance({
            type,
            userId: aUser?.id || 0,
            tabelTypeId: aCell?.tabelId || 0,
            date: aDate || ''
        })

        await getOneTabelUser()
        $message.destroy()
        $message.success(response.message, [750])

        // setIsEditTime(type)
    }, [aCell?.tabelId, aDate, aUser?.id, getOneTabelUser])

    /**
     * меняем тип табеля
     */
    const onChangeTabelType = useCallback(async (id: number) => {
        if (!aUser ||
            !filter?.year ||
            !filter?.month ||
            !aCell?.tabelId) {
            $message.destroy()
            $message.warning('Невозможно сменить тип табеля', 750)
            setIsChangeTabelType(false)
            return
        }
        try {
            const response = await TabelsService.changeTabelTypeId({
                userId: aUser?.id,
                year: filter?.year,
                month: filter?.month,
                fromTabelTypeId: aCell?.tabelId,
                toTabelTypeId: id

            })
            setIsChangeTabelType(false)
            $message.destroy()
            $message.success(response)
            await getOneTabelUser()
        } catch (error: any) {
            if (error.response.data.status === 403) {
                $message.destroy()
                $message.error(error.response.data.message, [750])
            } else {
                const errors = error.response.data.errors
                $message.destroy()
                for (let key of errors) {
                    $message.error(key, [750])
                }
            }
        }
    }, [aCell?.tabelId, aUser, filter?.month, filter?.year, getOneTabelUser])


    return (
        <>
            {aCell?.day ? <div className={styles.topRoot}>
                <Title>{`${stringDate(aDate || '')}, ${DAYS_NAMES_FULL[new Date(aDate || '').getDay()]}`}</Title>
                <div className={styles.wrapper}>
                    <div>
                        <div className={styles.items}>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>Тип табеля</div>
                                <div className={classNames(styles.rightItem)}>{tabelName}</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>Часы</div>
                                <div className={classNames(styles.rightItem)}>{aCell?.value ? aCell.value.hours === -1 ? VACATION_VAL : aCell.value.hours: ''}</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>Поставил</div>
                                <div className={classNames(styles.rightItem)}>{currentTabel?.author?.lastName || ''}</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>Дата записи</div>
                                <div className={classNames(styles.rightItem)}>{prindTimeDate(currentTabel?.inputAt || '')}</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>За день</div>
                                <div className={classNames(styles.rightItem)}>{currentTabel?.workHours || '0 часов'}</div>
                            </div>
                        </div>
                        <div className={styles.items}>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>Сотрудник</div>
                                <div className={classNames(styles.rightItem)}>{`${aUser?.lastName || currentTabel?.user?.lastName} ${aUser?.firstName || currentTabel?.user?.firstName}`}</div>
                            </div> 
                            <div className={styles.item}>
                                <div className={styles.leftItem}>Табельный номер</div>
                                <div className={classNames(styles.rightItem)}>{tabelNumber || currentTabel?.tabelGroupId}</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>Должность</div>
                                <div className={classNames(styles.rightItem)}>{aUser?.position || currentTabel?.user?.position.name}</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>Группа</div>
                                <div className={classNames(styles.rightItem)}>{aUser?.tabelGroup || currentTabel?.user?.tabelGroup.name}</div>
                            </div>
                        </div>
                        <div className={styles.items}>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>По проходной</div>
                                <div className={classNames(styles.rightItem)}>
                                    <div className={styles.timeBlock}>
                                        <span>{time}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.leftItem}>Отмечался по проходной</div>
                                <div className={classNames(styles.rightItem)}>
                                    <div className={styles.checksBlock}>
                                        {currentTabel?.checks?.map((check, index) => (
                                            <p className={styles.check} key={check.id}>
                                                {currentTabel.checks.length !== index && index !== 0 && ', '}
                                                {getOnlyTimeFromDate(check.time)}
                                            </p>)
                                        )}
                                    </div>
                                </div>
                            </div>
                            {canEdit &&
                                <div className={styles.changeButtons}>
                                    <Button
                                        className={classNames(styles.changeButton, {
                                            [styles.activeBtnTime]: isEditTime === EntranceType.DAY
                                        })}
                                        onClick={() => onChangeTabelEntrance(EntranceType.DAY)}
                                    >Простановка текущего дня табеля по проходной</Button>
                                    <Button
                                        className={classNames(styles.changeButton, {
                                            [styles.activeBtnTime]: isEditTime === EntranceType.MONTH
                                        })}
                                        onClick={() => onChangeTabelEntrance(EntranceType.MONTH)}
                                    >Простановка всего табеля по данным с проходной</Button>
                                    <Button className={styles.changeButton}
                                            onClick={() => setIsChangeTabelType(prev => !prev)}>
                                        Заменить всем тип табеля
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                    {canEdit && isChangeTabelType && 
                        <ListModal
                            position={'bottom'}
                            onClick={onChangeTabelType}
                            options={tabelTypesOptions}
                        /> 
                    }
                </div>
            </div> : <div className={styles.emptyBlock}>Выберите сотрудника <br></br> и день в табеле</div>
            }
        </>
    )
}

export default React.memo(TopPart)