import classNames from 'classnames';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../../constants/config';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/store';
import { selectUser } from '../../../../../../../store/slice/auth';
import { selectCheckedUsers, setCheckedUsers } from '../../../../../../../store/slice/staff';
import { AccessRightType, ProfileType, RolesKind } from '../../../../../../../types/types';
import { Button, ButtonColors } from '../../../../../../simples/Button';
import { CheckBox } from '../../../../../../simples/CheckBox';
import { ActiveUserContext, AddImployerContext, AddUserContext } from '../../../../StaffPage';
import styles from './TableBody.module.scss';


type Props = {
    user: ProfileType
}

const UserItem: FC<Props> = ({ user }): JSX.Element => {


    const currentUser = useAppSelector(selectUser)
    const canEdit = useMemo(() => {
        return currentUser?.accessRights.includes(AccessRightType.EditUserAndRights) || currentUser?.role === RolesKind.ADMIN
    }, [currentUser?.accessRights, currentUser?.role])
    const canViewProfile = useMemo(() => {
        return currentUser?.accessRights.includes(AccessRightType.ViewPersonalInfo) || currentUser?.role === RolesKind.ADMIN
    }, [currentUser?.accessRights, currentUser?.role])

    const [activeUser, setActiveUser] = useContext(ActiveUserContext)
    const [, setShowAddUserModal] = useContext(AddUserContext)
    const [, setShowImployerModal] = useContext(AddImployerContext)
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const checkedUsers = useAppSelector(selectCheckedUsers)
    const isCheched = useMemo(() => {
        if (checkedUsers.includes(user.id)) {
            return true
        }
        return false
    }, [user, checkedUsers])

    const onCheckUser = useCallback(() => {
        dispatch(setCheckedUsers(user.id))
    }, [dispatch, user.id])

    const onSetActiveUser = useCallback(() => {
        if (activeUser.id && activeUser.id === user.id) {
            setActiveUser({} as ProfileType)
        } else {
            setActiveUser(user)
        }
    }, [activeUser.id, setActiveUser, user])

    const goToProfile = useCallback(() => {
        if (canViewProfile || currentUser?.id === user.id) {
            navigate(`${Routes.PERSONAL_CARD}?id=${user.id}`)
        }
    }, [canViewProfile, currentUser?.id, navigate, user.id])


    const onAddOrChangeUser = useCallback(() => {
        if (!user.isUser) {
            setShowAddUserModal(user);
        } else {
            setShowAddUserModal(user)
        }
    }, [setShowAddUserModal, user])

    return (
        <tr
            onClick={onSetActiveUser}
            className={classNames(styles.userItemRow, {
                [styles.activeUser]: activeUser?.id === user.id
            })}>
            <td className={classNames(styles.userCell, {
                [styles.activeUser]: activeUser?.id === user.id
            })}>
                <div className={styles.nameWrapper}>
                    <div className={styles.leftNumber}>
                        <div className={styles.flexBlock}>
                            <div className={styles.checkBox}>
                                <CheckBox
                                    checked={isCheched}
                                    onChange={onCheckUser}
                                />
                            </div>
                            <span onClick={goToProfile} className={styles.number}>{user.tabelNumber}</span>
                        </div>
                    </div>

                </div>
            </td>
            <td className={classNames(styles.userCell, {
                [styles.activeUser]: activeUser?.id === user.id
            })}>
                <div onClick={onSetActiveUser} className={styles.userName}>
                    {`${user.lastName || ''} ${user?.firstName || ''} ${user.secondName || ''}`}
                </div>
            </td>
            <td className={classNames(styles.userCell, {
                [styles.activeUser]: activeUser?.id === user.id
            })}>
                <span>{user.phone}</span>
            </td>
            <td className={classNames(styles.userCell, {
                [styles.activeUser]: activeUser?.id === user.id
            })}>
                <span>{user.internalPhone}</span>
            </td>
            <td className={classNames(styles.userCell, {
                [styles.activeUser]: activeUser?.id === user.id
            })}>
                <span><a href={`mailto:${user.email}`}>{user.email}</a></span>
            </td>
            <td className={classNames(styles.userCell, {
                [styles.activeUser]: activeUser?.id === user.id
            })}>
                {canEdit && <Button
                    onClick={onAddOrChangeUser}
                    color={ButtonColors.Light}
                    className={styles.changeBtn}
                >
                    {user.isUser ? 'Редактировать пользователя' : 'Добавить пользователя'}
                </Button>}
            </td>

        </tr>
    )
}

export default React.memo(UserItem);
