import classNames from 'classnames'
import React, { FC, useEffect, useRef } from 'react'
import { TabelGroup } from '../../../../../types/types'
import { Selector } from '../../../../simples/Selector'
import { OptionType } from '../../../../simples/Selector/Selector'
import styles from './TabelGroup.module.scss'


type Props = {
    options: Array<OptionType>
    department: TabelGroup
    activeGroup?: TabelGroup
    onClick: (dep: TabelGroup) => void
    onChange: (id: number, val: string) => void
    onChangeDepartment:(id: number, val: string) => void
    isEdit: boolean
    index: number
}

const Item: FC<Props> = ({ 
    department, 
    activeGroup, 
    onClick, 
    onChange, 
    index, 
    onChangeDepartment,
    options,
    isEdit
}) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if(isEdit){
            inputRef.current?.focus()
        }
    },[isEdit])
    return (
        <div
            key={department.id}
            className={classNames(styles.item, {
                [styles.activeItem]: department.id === activeGroup?.id,
                [styles.disableItem]: !!activeGroup && department.id !== activeGroup.id
            })}
            onClick={() => onClick(department)}>
                   <div className={classNames({
                [styles.isEdit]: isEdit
            })}>
                <input
                    ref={inputRef}
                    disabled={!isEdit}
                    onChange={(e) => onChange(department.id, e.target.value)} 
                    value={department.name}
                />
            </div>
           
            <div className="">
                <Selector
                    disabled={!isEdit}
                    isOdd={!!!((index + 1) % 2 === 0)}
                    value={String(department.branchId)}
                    placeholder={'Филиал'}
                    options={options}
                    onChange={(e) => onChangeDepartment(department.id, e)}
                />
                
            </div>
        </div>
    )
}


export default Item;