import classNames from 'classnames'
import React, { ChangeEvent, FC, SyntheticEvent, useCallback, useRef, useState } from 'react'
import styles from './TextField.module.scss'
import { ReactComponent as HideIcon } from '../../../assets/icons/eye-close.svg'
import { ReactComponent as OpenIcon } from '../../../assets/icons/eye.svg'
import { error } from 'console'
import InputMask from 'react-input-mask';


type Props = {
    onChange: (val: string) => void
    value: string
    isSecure?: boolean
    placeholder?: string
    classes?: {
        root?: string
        input?: string
    }
    multyline?: boolean
    icon?: JSX.Element
    disabled?: boolean
    isError?: boolean
    label?: string
    mask?: string
    error?: string

    notes?: Array<{
        value: string
        label: string
    }>
    onNoteClick?: (value: string) => void
    date?: boolean
}





const TextField: FC<Props> = ({
    date,
    onChange,
    value,
    isSecure,
    placeholder,
    classes,
    multyline,
    icon,
    disabled,
    isError,
    label,
    mask,
    error,
    notes,
    onNoteClick
}) => {

    const [onFocus, setOnfocuse] = useState<boolean>(false)

    const [hide, setHide] = useState<boolean>(true)

    const onHandleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(event.target.value)
    }, [onChange])

    const onHandleChangeHide = useCallback(() => {
        setHide(prev => !prev)
    }, [])

    const onNoteClickHandle = (e: SyntheticEvent<HTMLDivElement>, value: string) => {
        e.stopPropagation()
        if (onNoteClick) {
            onNoteClick(value)
        }

    }

    const onHandleBlure = () => {
        setTimeout(() => setOnfocuse(false), 300)
    }

    const onChangeWithMask = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (!date) {
            onChange(e.target.value)
        } else {
            const data = e.target.value.split('.')
            const day = data[0]
            const month = data[1]
            const year = data[2]



            if (!month.includes('_') && +month > 12) {
                onChange(`${day}.12.${year}`)
            } else {
                onChange(e.target.value)
            }

            if (!e.target.value.includes('_')) {
                const date = e.target.value.split('.').reverse().join('.')
                // Array with days count in the month
                const DAYS_IN_MONTH = [
                    31,
                    date && date !== undefined && date !== null
                        ? new Date(date).getFullYear() % 4 === 0
                            ? 29
                            : 28
                        : 28,
                    31,
                    30,
                    31,
                    30,
                    31,
                    31,
                    30,
                    31,
                    30,
                    31,
                ]
                const data = e.target.value.split('.')
                const day = data[0]
                const month = data[1]
                const year = data[2]
                if ( +day > DAYS_IN_MONTH[+month - 1]) {
                    onChange(`${DAYS_IN_MONTH[+month - 1]}.${month}.${year}`)
                } else {
                    onChange(e.target.value)
                }

            }
        }

    }, [date, onChange])

    return (
        <div
            className={classNames(styles.root, classes?.root, {
                [styles.error]: isError
            })}>
            {label && <span className={styles.label}>{label}</span>}
            {
                !multyline && !mask ? <input
                    
                    onBlur={onHandleBlure}
                    onFocus={() => setOnfocuse(true)}
                    disabled={disabled}
                    placeholder={placeholder}
                    className={classNames(styles.input, classes?.input)}
                    onChange={onHandleChange}
                    value={value}
                    type={!isSecure || !hide ? 'text' : 'password'}
                /> : !multyline && mask
                    ?
                    <InputMask
                        disabled={disabled}
                        className={classNames(styles.input, classes?.input)}
                        value={value}
                        onChange={onChangeWithMask}
                        mask={mask}

                    /> :
                    <textarea
                        className={classNames(styles.textarea, classes?.input)}
                        rows={12}
                        value={value}
                        onChange={onHandleChange}
                    />
            }
            {
                isSecure &&
                <div
                    onClick={onHandleChangeHide}
                    className={styles.icon}>
                    {
                        hide ? <HideIcon /> : <OpenIcon />
                    }
                </div>
            }

            {
                icon &&
                <div
                    className={styles.icon}>
                    {icon}
                </div>
            }

            {
                !!error &&
                <span className={styles.errorText}>{error}</span>
            }

            {
                notes && notes.length > 0 && onFocus &&
                <div className={styles.noteWrapper}>
                    {
                        notes.map((note) => <div
                            key={note.value}
                            onClick={(e) => onNoteClickHandle(e, note.value)}
                            className={styles.noteItem}>{note.label}</div>)
                    }
                </div>
            }
        </div>
    )
}

export default TextField;

