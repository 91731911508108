import { FC, useCallback, useContext, useMemo } from 'react'
import { SortByType, SortType } from '../../../../../../../types/types'
import styles from './TableHeader.module.scss'
import { ReactComponent as Mark } from '../../../../../../../assets/icons/mark.svg'
import { DAYS_NAMES } from '../../../../../../../constants/constants'
import classNames from 'classnames'
import { hexToRgb } from '../../../../../../../constants/helper'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/store'
import { activeCellTable } from '../../../../../../../store/slice/tabel'
import { changeTabelFilter } from '../../../../../../../store/slice/filters'
import { DayOffContext } from '../../../../config'


export enum SortField {
    NUMBER = 'number',
    NAME = 'name'
}

type Props = {

}



const TableHeader: FC<Props> = () => {
    const dispatch = useAppDispatch()

    // фильтр по таблице
    const tableFilter = useAppSelector(state => state.filters.tabelFilter)
    // выходные дни
    const daysOff = useContext(DayOffContext)

    const currentDate = useAppSelector(activeCellTable)

    /**
     * формируем список выводимых дней
     * с отмеченными выходными и праздничными днями
     */
    const dateCells = useMemo(() => {
        let DAYS_IN_MONTH = 31
        if (tableFilter.month && tableFilter.year) {
            DAYS_IN_MONTH = [
                31,
                tableFilter?.year % 4 === 0
                    ? 29
                    : 28
                ,
                31,
                30,
                31,
                30,
                31,
                31,
                30,
                31,
                30,
                31,
            ][+tableFilter.month - 1]
        }


        const dayOptions = []
        for (let i = 0; i < DAYS_IN_MONTH; i++) {
            const currentDay = new Date(`${tableFilter?.year}-${tableFilter?.month}-${i + 1 < 10 ? `0${i + 1}` : i + 1}`).getDay()
            const isDayOff = daysOff.includes(`${tableFilter?.year}-${tableFilter?.month}-${i + 1 < 10 ? `0${i + 1}` : i + 1}`)

            dayOptions.push({
                day: i + 1,
                name: DAYS_NAMES[currentDay],
                isWeekend: currentDay === 0 || currentDay === 6 || isDayOff,
                isDayOff: isDayOff

            })
        }

        return dayOptions

    }, [daysOff, tableFilter.month, tableFilter.year])



    // Изменение сортировки по ФИО
    const changeSortFio = useCallback(() => {
        if (tableFilter.sort === SortType.ASC) {
            dispatch(changeTabelFilter({ ...tableFilter, sort: SortType.DESC, sortBy: SortByType.LAST_NAME }))
        } else {
            dispatch(changeTabelFilter({ ...tableFilter, sort: SortType.ASC, sortBy: SortByType.LAST_NAME }))
        }

    }, [dispatch, tableFilter])

    //изменение сортировки по табельному номеру
    const changeSortTabelNumber = useCallback(() => {
        if (tableFilter.sort === SortType.ASC) {
            dispatch(changeTabelFilter({ ...tableFilter, sort: SortType.DESC, sortBy: SortByType.TABEL_NUMBER }))
        } else {
            dispatch(changeTabelFilter({ ...tableFilter, sort: SortType.ASC, sortBy: SortByType.TABEL_NUMBER }))
        }

    }, [dispatch, tableFilter])

    //вывод компонента
    return (
        <thead style={{
            backgroundColor: '#fff',
            position: 'sticky',
            top: 0
        }}>
            <tr className={styles.row}>
                <th className={classNames(styles.cell, styles.cell_sticky_1)}>
                    <div onClick={changeSortTabelNumber} className={styles.flexBlock}>
                        <span>№</span>
                        <Mark className={classNames(styles.numberSort, {
                            [styles.desc]: tableFilter.sortBy === SortByType.TABEL_NUMBER && tableFilter.sort === SortType.DESC
                        })} />
                    </div>
                </th>
                <th className={classNames(styles.cell, styles.cell_sticky_2)}>
                    <div onClick={changeSortFio} className={styles.flexBlock}>
                        <span>Сотрудник</span>
                        <Mark className={classNames(styles.numberSort, {
                            [styles.desc]: tableFilter.sortBy === SortByType.LAST_NAME && tableFilter.sort === SortType.DESC
                        })} />
                    </div>
                </th>
                {dateCells.map((item) => <th
                    key={item.day}
                    style={
                        {
                            backgroundColor: currentDate && +item.day === +currentDate?.day ? `rgba(${hexToRgb(currentDate.color)},0.15)` : '',
                            borderLeftColor: currentDate && +item.day === +currentDate?.day ? currentDate.color : '',
                            borderRightColor: currentDate && +item.day === +currentDate?.day ? currentDate.color : '',
                            borderRightWidth: currentDate && +item.day === +currentDate?.day ? 2 : 0.5,
                            borderLeftWidth: currentDate && +item.day === +currentDate?.day ? 2 : 0.5,

                        }
                    }
                    className={classNames(styles.cell, styles.cell_stycky_day, {
                        [styles.weekend]: !!item.isWeekend,
                        [styles.dayOff]: !!item.isDayOff,
                        [styles.isCurrentDay]: currentDate && +item.day === +currentDate?.day ? true : false,
                    })}>
                    <div className={styles.dayCell}>
                        <div className={classNames({
                            [styles.weekend]: !!item.isWeekend
                        })}>{item.day}</div>
                        <div className={classNames({
                            [styles.weekend]: !!item.isWeekend
                        })}>{item.name}</div>
                    </div>
                </th>)}
            </tr>
        </thead>
    )
}

export default TableHeader