import React, { FC, useState } from 'react';
import ItemMenu from '../../complexes/Layout/ItemMenu';
import { OptionType } from './Dropdown';
import styles from './Dropdown.module.scss'
import { ReactComponent as Shevron } from '../../../assets/icons/Arrows-Vector.svg'
import { CheckBox } from '../CheckBox';



type Props = {
    option: OptionType
    onClick: (data: {
        value: string,
        keyName?: string
    }) => void
    isMultiple?: boolean
    isChecked?: boolean

}

const Item: FC<Props> = ({
    option,
    onClick,
    isMultiple,
    isChecked
}) => {

    return (
        <div className={styles.itemRoot}>
            <div className={styles.itemWrapper}>
                <div
                    // onMouseEnter={() => setShowExtra(true)}
                    // onMouseLeave={() => setShowExtra(false)}
                    onClick={(e) => {
                        e.stopPropagation()
                        onClick({ value: option.value, keyName: option.keyName })
                    }}
                    className={styles.options}
                >
                    {isMultiple && <span className={styles.checkbox}><CheckBox onChange={ () => onClick({ value: option.value, keyName: option.keyName })} checked={isChecked} /></span>}
                    {option.label}

                </div>
                <div className={styles.extraWrapper}>
                    {option.options &&
                        option.options?.length > 0 &&
                        <div className={styles.extraMenu}>
                            {option.options.map((item) => {
                                return <Item key={item.value} option={item} onClick={() => onClick({ value: item.value, keyName: item.keyName })} />
                            })
                            }
                        </div>}
                </div>
                {option.options &&
                        option.options?.length > 0 && <Shevron className={styles.itemIcon} />}
            </div>
        </div>
    )
}
export default Item