import { Endpoints } from '../constants/constants'
import { sendFormData } from '../constants/helper'
import { store } from '../store'
import { FilterTabel } from '../store/slice/filters'
import { setActiveTabelFinalize } from '../store/slice/tabel'
import { EntranceType, OneTabelType, TabelTableType } from '../types/types'
import api from '../utils/api'

type CreateTabelType = {
    createdAt: string
    userTabelNumber: number
    tabelTypeId: number
    hours?: number
    entrance?: string
    exit?: string
}

type ResponseTabelsTabelType = {
    data: Array<TabelTableType>
    finalized: 0 | 1
    meta: {
        totalCount: number
    }
}

type FinalizationType = {
    user: {
        id: number
        firstName: string
        lastName: string | null
        secondName: string | null
    }
    date: string
    msg: string
}
export class TabelsService {
    static async getTabelsTables(
        data: FilterTabel
    ): Promise<Array<TabelTableType>> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            if (aData[key]) {
                query.push(`${key}=${aData[key]}`)
            }
        }

        const response = await api.get<ResponseTabelsTabelType>(
            `${Endpoints.TABEL_TABLE}?${query.join('&')}`
        )

        store.dispatch(setActiveTabelFinalize(response.data.finalized))

        return response.data.data
    }
    static async getTabelsTablesExl(
        data: FilterTabel
    ): Promise<{ fileName: string }> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            if (aData[key]) {
                query.push(`${key}=${aData[key]}`)
            }
        }

        const response = await api.get(
            `${Endpoints.TABEL_TABLE}?${query.join('&')}`
        )

        return response.data.data
    }

    static async getTabelFullYearXls(
        year: number
    ): Promise<{ fileName: string }> {
        const response = await api.get(
            `${Endpoints.TABEL_FULL_YEAR_XLS}?year=${year}`
        )
        return response.data.data
    }

    static async createTabel(data: CreateTabelType): Promise<OneTabelType> {
        const fd = new FormData()
        const aData: any = data
        for (let key in data) {
            fd.append(`${key}`, aData[key])
        }
        const response = api.post(Endpoints.TABEL_CREATE, fd)
        return (await response).data.data
    }

    static async getTabelById(id: number): Promise<OneTabelType> {
        const response = await api.get(`${Endpoints.TABEL_BY_ID}/${id}`)
        return response.data.data
    }
    static async setAllMonth(data: {
        year: number
        month: string
        userId: number
        hours: number
        tabelTypeId: number
        entrance?: string
        exit?: string
    }): Promise<string> {
        const fd = new FormData()
        const aData: any = data
        for (let key in data) {
            fd.append(`${key}`, aData[key])
        }
        const response = api.post(Endpoints.TABEL_SET_MONTH, fd)
        return (await response).data.data[0]
    }

    static async updateTabel(
        id: number,
        data: {
            hours?: number
            entrance?: string
            exit?: string
        }
    ): Promise<OneTabelType> {
        const fd = new FormData()
        const aData: any = data
        for (let key in data) {
            fd.append(`${key}`, aData[key])
        }
        const response = api.post(`${Endpoints.TABEL_UPDATE}/${id}`, fd)
        return (await response).data.data
    }

    static async deleteTable(id: number) {
        const response = api.delete(`${Endpoints.TABEL_DELETE}/${id}`)
        return (await response).data.data
    }

    static async fetchTablesNames(): Promise<Array<string>> {
        const response = await api.get(Endpoints.SYSTEM_TABLES_NAMES)
        return response.data.data
    }

    static async finalTable(date: string): Promise<FinalizationType> {
        const response = await api.get(`${Endpoints.TABEL_FINAL}?date=${date}`)
        return response.data.data
    }

    static async unfinalizeTable(date: string): Promise<string> {
        const response = await api.get(
            `${Endpoints.TABEL_UNFINAL}?date=${date}`
        )
        return response.data.data[0]
    }

    static async uploadTabel(data: { file: File }): Promise<boolean> {
        const response = await api.post(
            Endpoints.UPLOAD_TABEL,
            sendFormData(data)
        )
        return response.data.success
    }

    /**
     * изменение типа табеля на другой
     */
    static async changeTabelTypeId(data: {
        year: number
        month: string
        userId: number
        fromTabelTypeId: number
        toTabelTypeId: number
    }): Promise<string> {
        const response = await api.post(
            Endpoints.TABEL_CHANGE_TYPE,
            sendFormData(data)
        )
        return response.data.data[0]
    }

    /**
     * проставление по проходной
     */
    static async setTimeByEntrance(data: {
        date: string
        userId: number
        tabelTypeId: number
        type: EntranceType
    }): Promise<{ message: string; success: boolean }> {
        console.log(data)

        const response = await api.post(
            Endpoints.TABEL_ENTRANCE_TIME,
            sendFormData(data)
        )
        return response.data
    }

    /**
     * получаем данные по табелю
     */
    static async getEntranceData(data: {
        userId: number
        date: string
        tableId: number
    }): Promise<OneTabelType> {
        const response = await api.get(
            `${Endpoints.TABEL_CHECKPOINT}?userId=${data.userId}&date=${data.date}&tableId=${data.tableId}`
        )
        const resData = response.data.data
        const result = { ...resData, workHours: resData['work-hours'] }
        return result
    }

    static async clearDay(data: { date: string; userId: number }) {
        await api.post(Endpoints.TABEL_CLEAR_DAY, sendFormData(data))
    }
}
