import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './Auth.module.scss'
import { ReactComponent as Vars } from '../../../assets/images/vars.svg'
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg'
import { Button } from '../../simples/Button'
import { TextField } from '../../simples/TextField'
import { useAppDispatch } from '../../../hooks/store'
import { checkUser } from '../../../store/slice/auth'
import { AuthService } from '../../../services/AuthService'
import { sendFormData } from '../../../constants/helper'
// @ts-ignore
import $message from 'message-like-antd'
import { fetchFilters } from '../../../store/slice/filters'
import { fetchTabelGroups, fetchFilials, fetchTablesNames, fetchColors, fetchPositions, fetchRole, fetchTabelTypes, fetchAccessRights, fetchNotificationsTypes } from '../../../store/slice/storage'


const Auth: FC = () => {
    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const [tabelNumber, setTabelNumber] = useState<string>('')

    const [error, setError] = useState<string>('')
    const [repairError, setRepairError] = useState<string>('')

    const [isRepair, setIsRepair] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    /**
 * запрос на восстановление пароля
 */
    const repairLogin = useCallback(async () => {
        try {
            const response =  await AuthService.loginProblem({
                login,
                tabelNumber: +tabelNumber
            })
            $message.success(response[0], [750])
        } catch (error: any) {
            setRepairError(error.response.data.errors[0])
        }
    }, [login, tabelNumber])


    const logIn = useCallback(async () => {
        try {
            const response = await AuthService.login(sendFormData({ login, password }))
            return response
        } catch (error:any) {
            setError(error.response.data.errors[0])

        }
    }, [login, password])
    const onLogin = useCallback(async () => {
        if (!isRepair) {
            const response = await logIn()
            if(response) {
                dispatch(checkUser()).then()
              
            }
        } else {
            repairLogin()
        }
    }, [dispatch, isRepair, logIn, repairLogin])

    /**
     * 
     */
    const authError = useCallback(() => {
        setIsRepair(prev => !prev)
        setPassword('')
        setTabelNumber('')
    }, [])


    useEffect(() => {
        setError('')
        setRepairError('')
    },[login, password, tabelNumber])




    return (
        <div className={styles.root}>
            <div className={styles.left}>
                <div className={styles.block_2} />
                <div className={styles.block_1} />
                <div className={styles.block_3} />
                <div className={styles.block_4} />

                <div className={styles.block_3} />
                <div className={styles.block_4} />
                <div className={styles.block_2} />
                <div className={styles.block_1} />

                <div className={styles.block_2} />
                <div className={styles.block_1} />
                <div className={styles.block_4} />
                <div className={styles.block_3} />

                <div className={styles.block_4} />
                <div className={styles.block_3} />
                <div className={styles.block_1} />
                <div className={styles.block_2} />

                <div className={styles.block_2} />
                <div className={styles.block_1} />
                <div className={styles.block_3} />
                <div className={styles.block_4} />

                <div className={styles.vars}>
                    <Vars />
                </div>
            </div>

            <div className={styles.right}>
                <div className={styles.form}>
                    <h2 className={styles.title}>{!isRepair ? 'Вход в систему' : 'Восстановление доступа'}</h2>
                    {!isRepair ? <div className={styles.inputs}>
                        
                        <TextField
                        isError={!!error}
                            classes={{
                                root: styles.login
                            }}
                            placeholder={'Логин'}
                            onChange={setLogin}
                            value={login}
                        />
                        <TextField
                        isError={!!error}
                            isSecure
                            classes={{
                                root: styles.pass
                            }}
                            placeholder={'Пароль'}
                            onChange={setPassword}
                            value={password}
                        />
                        <div className={styles.error}>{error}</div>
                    </div> :
                        <div className={styles.inputs}>
                             <div className={styles.error}>{repairError}</div>
                            <TextField
                            isError={!!repairError}
                                classes={{
                                    root: styles.login
                                }}
                                placeholder={'Email'}
                                onChange={setLogin}
                                value={login}
                            />
                            <TextField
                            isError={!!repairError}
                                classes={{
                                    root: styles.pass
                                }}
                                placeholder={'Табельный номер'}
                                onChange={setTabelNumber}
                                value={tabelNumber}
                            />
                        </div>}

                    <Button 
                    disabled={!login || (!password && !tabelNumber)}
                    onClick={onLogin}>{!isRepair ? 'Войти' : 'Восстановить доступ'}</Button>

                    <div
                        onClick={authError}
                        className={styles.problem}>
                        <span>{!isRepair ? 'Проблема с входом?' : 'Назад'}</span>
                        <Arrow />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth;

