import classNames from 'classnames';
import React, { FC } from 'react'
import styles from './Parts.module.scss'

const TableHeader:FC = () => {
    return (
        <thead>
            <tr className={styles.header}>
                <th className={classNames(styles.cell, styles.cell_1)}>Тип изменения</th>
                <th className={classNames(styles.cell, styles.cell_2)}>Пользователь</th>
                <th className={classNames(styles.cell, styles.cell_3)}>Дата</th>
            </tr>
        </thead>
    )
}

export default React.memo(TableHeader);