import classNames from 'classnames'
import React, { FC, useCallback } from 'react'
import styles from './Tabs.module.scss'

export type TabType = {
    label: string
    value: string
}
type Props = {
    data: Array<TabType>
    onChange: (value: string) => void
    value: string
}

/**
 * 
 * @param data 
 * @param onChange 
 * @param value 
 * @returns 
 */

const Tabs:FC<Props> = ({data, onChange, value}) => {

    const onTabChange = useCallback((val: string) => {
        onChange(val)
    },[onChange])
    
    return (
        <div className={styles.root}>
            {
                data.map((item) => <div 
                key={item.value}
                onClick={() => onTabChange(item.value)}
                className={classNames(styles.item, {
                    [styles.active]: item.value === value
                })}>
                    {item.label}
                </div>)
            }
        </div>
    )
}

export default React.memo(Tabs);