import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Switch.module.scss'



type Props = {
    isCheck: boolean
    onChange: () => void
    label?: string
}

const Switch: FC<Props> = ({
    isCheck,
    onChange,
    label
}) => {
    return (
        <div className={classNames(styles.root)}>
            <div
                onClick={onChange}
                className={classNames(styles.checkWrapper,{
                    [styles.active]: isCheck
                })}>
                <span className={classNames(styles.inner, {
                    [styles.activeInner]: isCheck
                })}></span>
            </div>

            {label && <span className={styles.label}>{label}</span>}
        </div>
    )
}
export default Switch