import { Endpoints } from '../constants/constants'
import {
    BranchType,
    SortByDepart,
    SortByType,
    SortType,
} from '../types/types'
import api from '../utils/api'

export class FilialService {
    static async getFilial(data: {
        name?: string
        sortBy?: SortByType
        sort?: SortType
        offset?: number
        limit?: number
        tabelGroupId?: string
    }): Promise<BranchType[]> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
           if(aData[key]){
            query.push(`${key}=${aData[key]}`)
           }
        }

        const response = await api.get(
            `${Endpoints.BRANCHERS}?${query.join('&')}`
        )

        return response.data.data
    }

    static async getFilialId(id: string): Promise<BranchType> {
        const response = await api.get(`${Endpoints.BRANCHERS}/get/${id}`)
        return response.data.data
    }
}
