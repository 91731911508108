import React, { FC, useCallback, useContext, useMemo } from 'react'
import { clearDate, getfullAge, stringDate } from '../../../../../constants/helper'
import { useAppSelector } from '../../../../../hooks/store'
import { ProfileService } from '../../../../../services/ProfileService'
import { ProfileType, RolesKind } from '../../../../../types/types'
import { BlockWrapper } from '../../../../simples/BlockWrapper'
import { Switch } from '../../../../simples/Switch'
import { Title } from '../../../../simples/Title'
import { EditUserContext } from '../../PersonalCard'
import styles from './UserInfo.module.scss'
// @ts-ignore
import $message from 'message-like-antd'
import { selectUser } from '../../../../../store/slice/auth'

type Props = {
    user: ProfileType
}

const UserInfo: FC<Props> = ({ user }) => {
    const NotificationType = useAppSelector(state => state.storage.notificationsTypes)
    const [currentUser, setCurrentUser] = useContext(EditUserContext)

    /**
 * Установка типов уведомлений
 */
    const setNotifications = useCallback(async (data: Array<number>) => {
        try {
            const response = await ProfileService.setNotifications(data)
        } catch (error: any) {
            if (error.response.data.status === 403) {
                $message.error(error.response.data.message, [750])
            } else {
                const errors = error.response.data.errors

                for (let key of errors) {
                    $message.error(key, [750])
                }
            }
        }
    }, [])
    const activeUser = useAppSelector(selectUser)

    const onSetNotification = useCallback(async (id: number) => {
        if (currentUser) {
            if (currentUser.notificationsOptions.includes(id)) {
                const newNotifications = currentUser.notificationsOptions.filter(i => i !== id)
                setCurrentUser({ ...currentUser, notificationsOptions: newNotifications })
                await setNotifications(newNotifications)
            } else {
                setCurrentUser({ ...currentUser, notificationsOptions: [...currentUser.notificationsOptions, id] })
                await setNotifications([...currentUser.notificationsOptions, id])
            }
        }
    }, [currentUser, setCurrentUser, setNotifications])
    const contatsInfo = useMemo(() => {
        if (activeUser?.role === RolesKind.ADMIN) {
            return true
        } else if (!activeUser?.accessRights.length) {
            return false
        } else {
            return true
        }
    }, [activeUser])
    return (
        <div className={styles.root}>
            <div className={styles.holder}>
                <BlockWrapper>
                    <Title size={16}>
                        {`${user.firstName} 
                        ${user.lastName || ''} 
                        ${user.secondName || ''}`}
                    </Title>
                    <div className={styles.date}>
                        {user.dateOfBirth && stringDate(clearDate(user.dateOfBirth))}
                        <span> / </span>
                        {user.dateOfBirth && getfullAge(clearDate(user.dateOfBirth))} полных лет
                    </div>
                </BlockWrapper>
            </div>
            {contatsInfo && <div className={styles.holder}>
                <BlockWrapper>
                    <Title size={14}>Контактная информация </Title>
                    <div className={styles.info}>
                        <div className={styles.infoItem}>
                            <div className={styles.leftKey}>Телефон</div>
                            <div className={styles.rightValue}>
                                {user?.phone || ''}
                            </div>
                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.leftKey}>Внутр. телефон</div>
                            <div className={styles.rightValue}>
                                {user?.internalPhone || ''}
                            </div>
                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.leftKey}>E-mail</div>
                            <div className={styles.rightValue}>
                                <a href={`mailto:${user.email}`}>{user.email || ''}</a>
                            </div>
                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.leftKey}>Доп. e-mail</div>
                            <div className={styles.rightValue}>
                                {user?.addEmail || ''}
                            </div>
                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.leftKey}>Должность</div>
                            <div className={styles.rightValue}>
                                {user?.position?.name || ''}
                            </div>
                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.leftKey}>Подразделение</div>
                            <div className={styles.rightValue}>
                                {user?.tabelGroup?.name || ''}
                            </div>
                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.leftKey}>Руководитель</div>
                            <div className={styles.rightValue}>
                                {user?.tabelGroup?.supervisor || ''}
                            </div>
                        </div>
                    </div>
                </BlockWrapper>
            </div>}

            <div className={styles.holder}>

                <BlockWrapper>
                    <Title size={14}>Уведомления</Title>
                    <div className={styles.info}>


                        {
                            NotificationType &&
                            NotificationType.map((notification) => {
                                return (
                                    <div key={notification.id} className={styles.switchHolder}>
                                        <Switch
                                            label={notification.name}
                                            isCheck={currentUser ? currentUser?.notificationsOptions.includes(notification.id) : false}
                                            onChange={() => onSetNotification(notification.id)}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </BlockWrapper>
            </div>

        </div>
    )
}

export default React.memo(UserInfo)
