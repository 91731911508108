import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TabelTypeService, UpdateTabelType } from '../../../services/TabelTypeService';
import { AccessRightType, RolesKind, SortType, TabelTypeType } from '../../../types/types';
import { Layout } from '../../complexes/Layout';
import { Button, ButtonColors } from '../../simples/Button';
import { OptionType } from '../../simples/Selector/Selector';
import TableBody from './parts/TableBody';
import TableHeader from './parts/TableHeader';
import styles from './TabelsSettings.module.scss'
// @ts-ignore
import $message from 'message-like-antd'
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { fetchTabelTypes } from '../../../store/slice/storage';
import produce from 'immer';
import { DeleteModal } from '../../complexes/DeleteModal';
import { selectUser } from '../../../store/slice/auth';
import { VACATION_VAL } from '../../../constants/tabel';


export enum FieldType {
    NAME = 'name',
    COEFF = 'coefficient',
    COLOR = 'colorId',
    SINGLE = 'single',
    LABEL = 'label',
    DESCRIPTION = 'description'
}

type TypeDraft = TabelTypeType & { isDraft?: boolean }

export const EditContext = React.createContext<boolean>(false)
export const ListContext = React.createContext<[Array<TabelTypeType>, React.Dispatch<React.SetStateAction<TabelTypeType[]>>]>([[], () => { }])
export const ActiveTypeContext = React.createContext<[TypeDraft | undefined, React.Dispatch<React.SetStateAction<TypeDraft | undefined>>]>([undefined, () => { }])
export const OptionsNameContext = React.createContext<OptionType[]>([])


const TabelsSettings: FC = () => {

    const user = useAppSelector(selectUser)
    const canEdit = useMemo(() => {
        return user?.accessRights.includes(AccessRightType.TuneTabelsTypesList) || user?.role === RolesKind.ADMIN
    },[user])
    const canAdd = useMemo(() => {
        return user?.accessRights.includes(AccessRightType.AddNewTypeOfTabels) || user?.role === RolesKind.ADMIN
    },[user])
    
    const dispatch = useAppDispatch()
    const [list, setList] = useState<TabelTypeType[]>([])
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [nameOptions, setNameOPtions] = useState<OptionType[]>([])

    const mainBlock = useRef<HTMLDivElement>(null)
    const [showDelModal, setShowDelModal] = useState<number>()



    /**
     * выделеннный табель
     */
    const [activeType, setActiveType] = useState<TypeDraft>()

    const has = useRef(true)
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await TabelTypeService.getTabeltypes({
            sort: SortType.ASC,
            offset: offset.current,
            limit: 50
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        const options: OptionType[] = result.map((i) => ({ label: i.name, value: i.name }))
        setNameOPtions(options)
        setList([...list, ...result])
    }

    useEffect(() => {
        load().then()
    }, [])

    /**
     * функция сохранения изменений
     */
    const saveEdited = useCallback(async () => {
        if (activeType) {
            const find = list.find(i => i.id === activeType.id)
            if (!find || !find.id) {
                return
            }
            try {
                const sendData: UpdateTabelType = {
                    name: find.name,
                    description: find.description || '',
                    colorId: find.colorId,
                    label: find.label === VACATION_VAL ? VACATION_VAL : '_',
                    single: find.single,
                    coefficient: find.coefficient

                }

                if (!activeType.isDraft) {
                    const response = await TabelTypeService.updateTabelType(find.id, sendData)
                    $message.destroy()
                    $message.success('Данные обновлены', [750])
                } else {
                    const response = await TabelTypeService.createTabelType(sendData) as TabelTypeType as any
                    setList(produce(

                        draft => {
                            const find = draft.find(i => i.id === activeType.id) as TabelTypeType as any
                            for (let key in find) {
                                find[key] = response[key]
                            }
                        }
                    ))
                    $message.destroy()
                    $message.success('Тип успешно создан', [750])
                }
                dispatch(fetchTabelTypes()).then()
                setActiveType(undefined)
                setIsEdit(false)
            } catch (error: any) {
                if(error.response.data.status === 403){
                    $message.destroy()
                    $message.error(error.response.data.message, [750])
                } else {
                    const errors = error.response.data.errors
                    $message.destroy()
                    for(let key of errors){
                        $message.error(key, [750])
                    }
                }
            }
        }
    }, [activeType, dispatch, list])

    /**
     * нажатие на кнопку редактирования или сохранения
     */
    const onHandleEdit = useCallback(() => {
        if (isEdit) {
            saveEdited().then()
        } else {
            setIsEdit(true)
        }

    }, [isEdit, saveEdited])

    /**
     * добавление нового типа
     */
    const onAddNewType = useCallback(() => {

        setList(produce(
            draft => {
                const newType = {
                    id: new Date().getTime(),
                    name: '',
                    coefficient: '',
                    single: 0,
                    label: '',
                    colorId: 0,
                    isDraft: true
                } as TypeDraft

                draft.push(newType)
                setActiveType(newType)

            }
        ))
        setIsEdit(true)
    }, [])

    // Метод сброса фокуса с выбранного элемента
    const loseFocus = useCallback(() => {
        if(showDelModal){
            return
        }
        setList(produce(
            draft => {
                const find: any = draft.find(i => i.id === activeType?.id)
                if (!find?.name && !activeType?.name) {
                    setList(prev => prev.filter(i => i.id !== activeType?.id))
                }
                if (find && activeType && (
                    find?.name !== activeType?.name ||
                    find?.coefficient !== activeType?.coefficient ||
                    find.single !== activeType.single ||
                    find.label !== activeType.label ||
                    find.colorId !== activeType.colorId ||
                    find.description !== activeType.description)) {
                    const aT: any = activeType
                    for (let key in find) {

                        find[key] = aT[key]
                    }
                }
            }
        ))

        setActiveType(undefined)
        setIsEdit(false)
    }, [activeType, showDelModal])


    // Метод закрытия селектора при клике вне самого селектора
    const closeSelectOutOfBlock = useCallback(
        (event: any) => {
            if (mainBlock && mainBlock.current) {
                // Проверка добавлена для устранения бага в Firefox
                if (!mainBlock.current.contains(event.target)) {
                    loseFocus()
                }
            }
        },
        [loseFocus]
    )

    // Установка/удаление обработчика события на документе.
    useEffect(() => {
        document.addEventListener('click', closeSelectOutOfBlock, false)
        return () => {
            document.removeEventListener('click', closeSelectOutOfBlock, false)
        }
    }, [closeSelectOutOfBlock])

    /**
     * Удаление типа табеля
     */
    const onHandleDelete = useCallback(async () => {
        if (showDelModal) {
            try {
                const response = await TabelTypeService.deleteTabelTape(showDelModal)
                setList(prev => prev.filter(i => i.id !== showDelModal))
                setShowDelModal(undefined)
                $message.destroy()
                $message.success(response, [1500])
                dispatch(fetchTabelTypes()).then()
            } catch (error: any) {
                if(error.response.data.status === 403){
                    $message.destroy()
                    $message.error(error.response.data.message, [750])
                } else {
                    const errors = error.response.data.errors

                    for(let key of errors){
                        $message.destroy()
                        $message.error(key, [750])
                    }
                }
            }
        }
    }, [dispatch, showDelModal, setList])

    return (
        <Layout>

                <EditContext.Provider value={isEdit}>
                    <ListContext.Provider value={[list, setList]}>
                        <OptionsNameContext.Provider value={nameOptions}>

                            <ActiveTypeContext.Provider value={[activeType, setActiveType]}>
                                <div ref={mainBlock} className={styles.root}>
                                    <table className={styles.table}>
                                        <TableHeader />
                                        <TableBody
                                            data={list}
                                        />
                                    </table>
                                    <div className={styles.btnBlock}>
                                        {canAdd && <Button
                                            color={ButtonColors.Main}
                                            onClick={onAddNewType}
                                        >
                                            {'Добавить'}
                                        </Button>}
                                        {activeType && canEdit && <Button
                                            color={ButtonColors.Main}
                                            onClick={onHandleEdit}
                                        >
                                            {!isEdit ? 'Редактировать' : 'Сохранить'}
                                        </Button>}
                                        {activeType && canEdit && <Button
                                            color={ButtonColors.Main}
                                            onClick={() => setShowDelModal(activeType.id)}
                                        >
                                            {'Удалить'}
                                        </Button>}

                                        {showDelModal && <DeleteModal
                                            onSubmit={onHandleDelete}
                                            onCancel={() => setShowDelModal(undefined)}
                                            text={'При удалении типа табеля вы потеряете все данные связанные с данными данного типа'}
                                        />}
                                    </div>
                                </div>
                            </ActiveTypeContext.Provider>

                        </OptionsNameContext.Provider>
                    </ListContext.Provider>
                </EditContext.Provider>
        </Layout>
    )
}

export default TabelsSettings;
