import React, { FC, useCallback, useState } from 'react'
import styles from './Layout.module.scss'
// import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import LogoIcon from '../../../assets/images/logo.png'
import { Routes } from '../../../constants/config'
import ItemMenu, { ItemMenuType } from './ItemMenu'
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg'
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg'
import { ReactComponent as LogOutIcon } from '../../../assets/icons/logOut.svg'
import { ReactComponent as TabelIcon } from '../../../assets/icons/tabel.svg'
import { ReactComponent as SettingIcon } from '../../../assets/icons/settings.svg'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { logout, selectUser } from '../../../store/slice/auth'
import {ReactComponent as Avatar} from '../../../assets/icons/avatar.svg'
import { _API_PREFIX } from '../../../constants/constants'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

const menu: Array<ItemMenuType> = [
    {
        label: 'Дом',
        icon: <HomeIcon />,
        route: Routes.HOME,
    },
    {
        label: 'Пользователи',
        icon: <UsersIcon />,
        route: Routes.STAFF,
    },
    {
        label: 'Табели',
        icon: <TabelIcon />,
        route: Routes.TABEL,
    },
    {
        label: 'Настройки табелей',
        icon: <SettingIcon />,
        route: Routes.TABEL_SETTINGS,
    },
]

const LeftMenu: FC = () => {
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [open, setOpen] = useState<boolean>(false)

    const onLogOut = useCallback(() => {
        dispatch(logout())
    },[dispatch])


    /**
     * 
     */
    const goToProfileEdit = useCallback(() => {
        navigate(`${Routes.PERSONAL_CARD}?id=${user?.id}`)
        
    },[navigate, user?.id])
    return (
        <div className={classNames(styles.leftMenu,{
            [styles.openMenu]:open,
            [styles.closeMenu]:!open,

        })}>
            <div className={styles.top}>
                <img src={LogoIcon} alt="logo" />
                <div className={styles.menu}>
                    {menu.map((item) => (
                        <ItemMenu key={item.route} data={item} />
                    ))}
                </div>
            </div>
            <div className={styles.bottom}>
                <div 
                onClick={goToProfileEdit}
                className={styles.avatar}>
                    {user?.photo ? <img src={_API_PREFIX + user?.photo?.path} alt="logo" /> : <Avatar />}
                </div>
                <div className={styles.logOut}>
                    <LogOutIcon onClick={onLogOut} />
                </div>
            </div>
            <div 
            onClick={() => setOpen(prev => !prev)}
            className={classNames(styles.burger,{
                [styles.openBurger]:open
            })}
            ><span></span></div>
        </div>
    )
}

export default React.memo(LeftMenu)
