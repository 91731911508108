import { Endpoints } from '../constants/constants';
import { LogType, SortByType, SortType, TableNames } from '../types/types';
import api from '../utils/api'

export class LogService {
    static async getLogs(data: {
        sortBy?: SortByType
        sort?: SortType,
        offset?: number
        limit?: number
        table?: TableNames
    }):Promise<LogType[]> {

        const query: Array<string> = []

        let aData: any = data

        for(let key in data){
            query.push(`${key}=${aData[key]}`)
        }

        const response = await api.get(`${Endpoints.LOGS}?${query.join('&')}`)
        return response.data.data
    }
}