import React, { FC } from 'react';
import { ProfileType } from '../../../../../../../types/types';
import UserItem from './UserItem';

type Props = {
    data: Array<ProfileType>
}
const TableBody: FC<Props> = ({ data }) => {

    return (

            <tbody >

                {data.map((user) => <UserItem key={user.id} user={user} />)}

            </tbody>

    )
}

export default React.memo(TableBody);
