import { json } from 'stream/consumers'
import { Endpoints } from '../constants/constants'
import { arrayToQuery, sendFormData } from '../constants/helper'
import { NotificationType, ProfileType, RoleType } from '../types/types'
import api from '../utils/api'

export class ProfileService {
    static async getProfile(data: {
        id?: number
        firstName?: string
        lastName?: string
        secondName?: string
        tabelNumber?: number
        tabelGroup?: number
        tabelGroupId?: string
        email?: string
        phone?: string
        internalPhone?: string
        role?: string
        branchId?: string
        dateOfBirth?: string
        positionId?: string
        fired?: 0 | 1
        limit?: number
        offset?: number
    }): Promise<ProfileType[]> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            if (aData[key] !== undefined) {
                query.push(`${key}=${aData[key]}`)
            }
        }

        const response = await api.get(
            `${Endpoints.PROFILE}?${query.join('&')}`
        )

        return response.data.data
    }

    static async getProfileId(id: number | undefined): Promise<ProfileType> {
        const response = await api.get(`${Endpoints.PROFILE}/get/${id}`)

        return response.data.data
    }

    static async uploadPhoto(id: number, fd: FormData): Promise<ProfileType> {
        const response = await api.post(`${Endpoints.PROFILE_UPDATE}/${id}`, fd)
        return response.data.data
    }

    static async uploadProfileId(
        data: ProfileType,
        id: number | undefined
    ): Promise<ProfileType> {
        const response = await api.post(
            `${Endpoints.PROFILE_UPDATE}/${id}`,
            sendFormData(data)
        )
        return response.data.data
    }

    static async uploadProfileBranch(data: {
        id: number
        branchId: number
    }): Promise<ProfileType> {
        const response = await api.post(
            `${Endpoints.PROFILE_UPDATA_BRANCH}`,
            sendFormData(data)
        )
        return response.data.data
    }

    static async uploadProfileTabelGroup(data: {
        id: number
        tabelGroupId: number
    }): Promise<ProfileType> {
        const response = await api.post(
            `${Endpoints.PROFILE_UPDATA_TABEL_GROUP}`,
            sendFormData(data)
        )
        return response.data.data
    }

    static async uploadProfileTabelGroups(
        id: number,
        branches: Array<number>
    ): Promise<ProfileType> {
        const response = await api.post(
            `${Endpoints.PROFILE_UPDATA_BRANCHES}/${id}`,
            sendFormData(branches)
        )
        return response.data.data
    }

    static async uploadProfileBranchesRuk(
        id: number,
        branches: number[]
    ): Promise<ProfileType> {
        const fd = new FormData()
        fd.append('branches', JSON.stringify(branches))

        const response = await api.post(
            `${Endpoints.PROFILE_UPDATA_BRANCHES}/${id}`,
            fd
        )

        return response.data.data
    }

    static async uploadProfileTabelGroupsNach(
        id: number,
        tabelGroups: Array<number>
    ): Promise<ProfileType> {
        const fd = new FormData()
        fd.append('tabelGroups', JSON.stringify(tabelGroups))
        const response = await api.post(
            `${Endpoints.PROFILE_UPDATA_TABEL_GROUPS}/${id}`,
            fd
        )

        return response.data.data
    }

    static async getRoles(): Promise<RoleType[]> {
        const response = await api.get(`${Endpoints.ROLES}`)
        return response.data.data
    }

    static async uploadRoles(id: number, role: string): Promise<string> {
        const response = await api.post(
            `${Endpoints.ROLES_UPDATA}/${id}`,
            sendFormData(role)
        )
        return response.data
    }

    static async exportProfile(
        usersIds: Array<number>
    ): Promise<{ fileName: string }> {
        const response = await api.get(
            `${Endpoints.PROFILE_EXPORT_XLS}?usersIds=${arrayToQuery(usersIds)}`
        )

        return response.data.data
    }

    static async getProfilePrint(
        usersIds: Array<number>
    ): Promise<Array<ProfileType>> {
        const response = await api.get(
            `${Endpoints.PROFILE_ARRAI}?usersIds=${arrayToQuery(usersIds)}`
        )

        return response.data.data
    }
    /**
     * 
     * @param usersIds 
     * @returns pdf - link
     */
    static async printUsers(
        usersIds: Array<number>
    ): Promise<string> {
        const fd = new FormData()
        fd.append('usersIds', JSON.stringify(usersIds))
        const response = await api.post(
            Endpoints.PROFILE_PRINT_PDF, fd
        )

        return response.data.data.fileName
    }

    /**
     * create user
     */
    static async createProfile(data: ProfileType): Promise<ProfileType> {
        const response = await api.post(Endpoints.PROFILE_CREATE, sendFormData(data))
        return response.data.data
    }

    /**
     * Установка типов табелей для пользователя
     */
    static async setTabelsTypes(userId: number, tabelsTypes: Array<number>) {
        const fd = new FormData()
        fd.append('tabelsTypes', JSON.stringify(tabelsTypes))
        const response = await api.post(`${Endpoints.PROFILE_SET_TABELS_TYPES}/${userId}`, fd)
        return response.data.data
    }
    /**
     * Установка прав доступа для пользователя
     */
    static async setAccessRights(userId: number, accessRights: Array<number>) {

        const fd = new FormData()
        fd.append('accessRights', JSON.stringify(accessRights))
        const response = await api.post(`${Endpoints.PROFILE_SET_ACCESS_RIGHTS}/${userId}`, fd)
        return response.data.data
    }

    /**
     * Получение списка уведомлений
     */
    static async fetchNotificationsType(): Promise<NotificationType[]> {
        const response = await api.get(Endpoints.NOTIFICATIONS_LIST)
        return response.data.data
    }

    /**
     * Установка уведомлений
     */
    static async setNotifications(data: Array<number>) {
        const fd = new FormData()
        fd.append('notifications', JSON.stringify(data))
        const response = await api.post(Endpoints.PROFILE_SET_NOTIFICATIONS, fd)
        return response.data.data
    }
}
