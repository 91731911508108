import React, { FC } from 'react'
import { TabelNoutType } from '../../../../../../../types/types'
import styles from './TabelNoteCard.module.scss'


type Props = {
    data: TabelNoutType
}

const TabelNoteCard:FC<Props> = ({data}) => {
    return (
        <div className="">{data.message}</div>
    )
}

export default React.memo(TabelNoteCard);