export const _API_PREFIX = 'https://time.vars.ru/'

export enum Endpoints {
    LOGIN = '/auth/login',
    LOGIN_TRUBLE = '/auth/login-problem',

    CURRENT_USER = '/users/profile',
    PROFILE = '/users',
    PROFILE_CREATE = '/users/create',
    PROFILE_UPDATE = '/users/update',
    PROFILE_UPDATA_BRANCH = '/users/set-branch',
    PROFILE_UPDATA_TABEL_GROUP = '/users/set-tabel-group',
    PROFILE_UPDATA_BRANCHES = 'users/set-branches',
    PROFILE_SET_TABELS_TYPES = 'users/set-tabels-types',
    PROFILE_SET_ACCESS_RIGHTS = '/users/set-access-rights',
    PROFILE_UPDATA_TABEL_GROUPS = 'users/set-tabels-groups',
    PROFILE_EXPORT_XLS = 'users/xls-export',
    PROFILE_SET_NOTIFICATIONS = 'users/set-notifications',
    PROFILE_PRINT_PDF = 'users/pdf-export',

    ROLES = 'users/roles',
    ROLES_UPDATA = 'users/set-role',
    PROFILE_ARRAI = 'users/info',

    DEPARTMENTS = '/branches',
    TABELS_GROUP = '/tabels-groups',
    BRANCHERS = '/branches',

    DEPARTMENTS_UPDATE = '/branches/update',
    DEPARTMENTS_CREATE = '/branches/create',
    DEPARTMENTS_DELETE = '/branches/delete',
    TABELS_GROUP_ID = '/tabels-groups/get',
    TABELS_GROUP_CREATE = '/tabels-groups/create',
    TABELS_GROUP_UPDATE = '/tabels-groups/update',
    TABELS_GROUP_DELETE = '/tabels-groups/delete',
    NEWS = '/news',
    NEWS_BY_ID = '/news/get',
    NEWS_CREATE = '/news/create',
    NEWS_UPDATE = '/news/update',
    NEWS_DELETE = '/news/delete',
    LOGS = '/logs',
    DOCS = '/docs',
    DOCS_BY_ID = '/docs/get',
    DOCS_UPLOAD = '/docs/upload',
    DOCS_DELETE = '/docs/delete',
    DOCS_ZIP = '/docs/zip',
    NOTIFICATIONS = '/news/notifications',

    TABELS = '/tabels',
    TABEL_BY_ID = '/tabels/get',
    TABEL_FINAL = '/tabels/finalize',
    TABEL_UNFINAL = '/tabels/unfinalize',
    TABEL_CREATE = '/tabels/create',
    TABEL_UPDATE = '/tabels/update',
    TABEL_DELETE = '/tabels/delete',
    TABEL_TABLE = '/tabels/table',
    TABEL_SET_MONTH = '/tabels/set-month',
    TABEL_CHANGE_TYPE = '/tabels/change-month-tabel-type',
    TABEL_FULL_YEAR_XLS = '/tabels/xls-export-year',
    TABEL_CHECKPOINT = '/test/get-time-checkpoint',
    TABEL_CLEAR_DAY = '/test/clear-day',

    TABEL_TYPE = '/tabels-types',
    TABEL_TYPE_BY_ID = '/tabels-types/get',
    TABEL_TYPE_CREATE = '/tabels-types/create',
    TABEL_TYPE_UPDATE = '/tabels-types/update',
    TABEL_TYPE_DELETE = '/tabels-types/delete',

    TABEL_ENTRANCE_TIME = '/test/set-checkpoint',


    SYSTEM_TABLES_NAMES = '/system/tables',
    COLORS = '/colors',
    COLORS_BY_ID = '/colors/get',
    COLORS_CREATE = '/colors/create',
    COLORS_UPDATE = '/colors/update',
    COLORS_DELETE = '/colors/delete',
    POSITIONS = '/positions',
    POSITIONS_ID = '/positions/get',
    POSITIONS_CREATE = '/positions/create',
    POSITIONS_UPDATE = '/positions/update',

    DAY_OFF_LIST = '/weekend-days',

    FILTER_SAVE = '/users-settings/filter-save',
    FILTER_LOAD = '/users-settings/filters-load',

    RIGHTS_LIST = '/access-rights',

    NOTIFICATIONS_LIST = '/notifications',

    USER_FINALE = '/test/get-user-final',
    UPLOAD_TABEL = '/test/import-tabel',

    IMPORT_TABEL = '/test/import-tabel'
}

export const DAYS_NAMES = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
export const DAYS_NAMES_FULL = [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
]

export const DAY_IN_WEEK = 7
export const _MONTHS = [
    {
        id: 1,
        label: 'Январь',
        short: 'Jan',
    },
    {
        id: 2,
        label: 'Февраль',
        short: 'Feb',
    },
    {
        id: 3,
        label: 'Март',
        short: 'Mar',
    },
    {
        id: 4,
        label: 'Апрель',
        short: 'Apr',
    },
    {
        id: 5,
        label: 'Май',
        short: 'May',
    },
    {
        id: 6,
        label: 'Июнь',
        short: 'Jun',
    },
    {
        id: 7,
        label: 'Июль',
        short: 'Jul',
    },
    {
        id: 8,
        label: 'Август',
        short: 'Aug',
    },
    {
        id: 9,
        label: 'Сентябрь',
        short: 'Sep',
    },
    {
        id: 10,
        label: 'Октябрь',
        short: 'Окт',
    },
    {
        id: 11,
        label: 'Ноябрь',
        short: 'Nov',
    },
    {
        id: 12,
        label: 'Декабрь',
        short: 'Dec',
    },
]

export const mailRe = /\S+@\S+\.\S{2}/

export const emailDomens = [
    'gmail.com',
    'mail.ru',
    'inbox.ru',
    'vars.ru',
    'yandex.ru',
    'ya.ru'
]

export enum LockTabelTypes {
    VACATION = 'За Авто',
    AUTO = 'Отпуск'
}
