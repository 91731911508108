import React, { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../constants/config';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { ProfileType, TableNames } from '../../../../../types/types';
import { BlockWrapper } from '../../../../simples/BlockWrapper';
import { Button, ButtonColors } from '../../../../simples/Button';
import { Dropdown } from '../../../../simples/Dropdown';
import { OptionType } from '../../../../simples/Dropdown/Dropdown';
import { TextField } from '../../../../simples/TextField';
import styles from './Header.module.scss'
import { ReactComponent as ClearFilter } from '../../../../../assets/icons/clearFilter.svg'
import { AddImployerContext } from '../../StaffPage';
import { CheckBox } from '../../../../simples/CheckBox';
import { changeUserFilter, userFilter } from '../../../../../store/slice/filters';
import { SettingsService } from '../../../../../services/SettingsService';
// @ts-ignore
import $message from 'message-like-antd'
import { selectUser } from '../../../../../store/slice/auth';

type Props = {

}

enum FilterKeyName {
    DEPARTMENT = 'branchId',
    TABEL_GROUP = 'tabelGroupId'
}

const Header: FC<Props> = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const filter = useAppSelector(userFilter)
    const tabelGroup = useAppSelector((state) => state.storage.tabelGroups)
    const filials = useAppSelector((state) => state.storage.filials)
    const [search, setSearch] = useState('')
    const user = useAppSelector(selectUser)
    const inputTimer = useRef<NodeJS.Timeout>()

    /**
 * editble user
 */
    const [editUser, setEditUser] = useContext(AddImployerContext)

    /**
     * CONTEXT for changing filter
     */

    // const [filter, setFilter] = useContext(FilterContext)

    const optionsTabelGroup = useMemo((): OptionType[] => {
        const opt: {
            [key: number]: OptionType
        } = {}
        tabelGroup.map((item) => {
            if (item.branchId) {

                const os: Array<OptionType> = opt[item.branchId] && opt[item.branchId].options ? opt[item.branchId].options as Array<OptionType> : [] as Array<OptionType>

                opt[item.branchId] = {
                    label: item.branch?.name || '',
                    value: `${item.branchId}`,
                    keyName: FilterKeyName.DEPARTMENT,
                    options: [...os, {
                        label: item.name || '',
                        value: `${item.id}`,
                        keyName: FilterKeyName.TABEL_GROUP
                    }]
                }
            }

        })


        return Object.values(opt).map(i => i).sort((a, b) => {
            if (a.label > b.label) {
                return -1;
            }
            if (a.label < b.label) {
                return 1;
            }
            return 0;
        });

    }, [tabelGroup])


    const onNavigateToLogs = useCallback(() => {
        navigate(`${Routes.LOGS}?table=${TableNames.users}`)
    }, [navigate])

    /**
     * Save filter on back
     */
    const onSaveFilter = useCallback(async (
        page: Routes,
        options: string
    ) => {
        try {
            const response = await SettingsService.saveFilter({
                page,
                options
            })
        } catch (error: any) {
            if (error.response.data.status === 403) {
                $message.error(error.response.data.message, [750])
            } else {
                const errors = error.response.data.errors

                for (let key of errors) {
                    $message.error(key, [750])
                }
            }

        }
    }, [])

    const onChangeFilterDepartment = useCallback(async (value: {
        value: string,
        keyName?: string
    }) => {
        if (value.keyName === FilterKeyName.TABEL_GROUP) {
            const branchId = `${tabelGroup.find(i => i.id === +value.value)?.branchId}` || ''
            dispatch(changeUserFilter({ ...filter, branchId, tabelGroupId: value.value }))
            await onSaveFilter(Routes.STAFF, JSON.stringify({ ...filter, branchId, tabelGroupId: value.value }))
        }
        if (value.keyName === FilterKeyName.DEPARTMENT) {
            dispatch(changeUserFilter({ ...filter, tabelGroupId: '', branchId: value.value }))
        }


    }, [dispatch, filter, onSaveFilter, tabelGroup])

    const dropdownFilterLabel = useMemo(() => {
        let label: string = ''
        if (filter && (filter.branchId || filter.tabelGroupId)) {
            const findDepart = filials.find((i) => {
                if (filter.branchId) {
                    return i.id === +filter.branchId
                }
            })?.name || ''

            const findTabelGroup = tabelGroup.find((i) => {
                if (filter.tabelGroupId) {
                    return i.id === +filter.tabelGroupId
                }
            })?.name || ''

            label = `${findDepart} ${findTabelGroup ? '-> ' + findTabelGroup : ''}`
        }

        return label

    }, [filials, filter, tabelGroup])


    const onChangeFired = useCallback(() => {
        if (filter.fired === undefined) {
            dispatch(changeUserFilter({ ...filter, fired: 0 }))
            onSaveFilter(Routes.STAFF, JSON.stringify({ ...filter, fired: 0 }))
            // setFilter(prev => ({ ...prev, fired: 0 }))
        } else {
            dispatch(changeUserFilter({ ...filter, fired: undefined }))
            onSaveFilter(Routes.STAFF, JSON.stringify({ ...filter, fired: undefined }))
            // setFilter(prev => ({ ...prev, fired: undefined }))
        }
    }, [dispatch, filter, onSaveFilter])

    /**
     * add new imployer
     */
    const onAddImployer = useCallback(() => {
        setEditUser({} as ProfileType)
    }, [setEditUser])

    /**
     * Reset filter
     */
    const onResetFilter = useCallback(async () => {
        dispatch(changeUserFilter({ search: '', tabelGroupId: '' }))
        onSaveFilter(Routes.STAFF, JSON.stringify({ name: '', tabelGroupId: '', branchId: '' }))
    }, [dispatch, onSaveFilter])

    /**
     * поиск по имени
     */
    const onChangeSearch = useCallback(async (value: string) => {
        setSearch(value);
        clearTimeout(inputTimer.current);
        inputTimer.current = setTimeout(async () => {
            dispatch(changeUserFilter({ ...filter, search: value }))
            await onSaveFilter(Routes.STAFF, JSON.stringify({ ...filter, search: value }))
        }, 800)
    }, [dispatch, filter, onSaveFilter])

    return (
        <BlockWrapper>
            <div className={styles.header}>
                <div className={styles.leftPart}>
                    <div className={styles.holder}>
                        <Dropdown
                            cascade
                            placeholder={'Табельная группа'}
                            value={[filter.branchId || '']}
                            labelInner={dropdownFilterLabel}
                            options={optionsTabelGroup}
                            onChange={function (value): void {
                                onChangeFilterDepartment(value)
                            }}
                        />
                    </div>

                    <div className={styles.holder}>
                        <TextField
                            classes={{
                                root: styles.search,
                            }}
                            placeholder={'Поиск'}
                            onChange={onChangeSearch}
                            value={search}
                        />
                    </div>

                    <div className={styles.holder}>
                        <div
                            className={styles.filterBtn}
                            onClick={onResetFilter}
                        >
                            <div className={styles.filterSvg}>
                                <ClearFilter />
                            </div>

                            <span className={styles.filterBtnText}>
                                Сбросить фильтры
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.holder}>
                    <div className={styles.rightBlock}>
                        <Button
                            onClick={onAddImployer}
                            color={ButtonColors.Light}
                            className={styles.changeBtn}
                        >
                            Добавить сотрудника
                        </Button>
                        <Button
                            onClick={onNavigateToLogs}
                            color={ButtonColors.Light}
                            className={styles.changeBtn}
                        >
                            Изменения
                        </Button>
                        <div className={styles.checkbox}>
                            <CheckBox
                                onChange={onChangeFired}
                                checked={filter.fired === undefined}
                                label={'Показывать уволенных'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </BlockWrapper >

    )
}

export default React.memo(Header);
