import { FC } from 'react';
import { TabelTypeType } from '../../../../types/types';
import TableItem from './TableItem';


type Props = {
    data: Array<TabelTypeType>
}
const TableBody: FC<Props> = ({ data }): JSX.Element => {
    return (
        <tbody>
            {
                data.map((tabelType) => <TableItem
                    key={tabelType.id}
                    data={tabelType}
                />)
            }
        </tbody>
    )
}

export default TableBody;
