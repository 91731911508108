import {
    FC,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react'
import { Title } from '../../../../../../simples/Title'
import { ActiveUserContext, UsersContext } from '../../../../StaffPage'
import CheckedUsers from './parts/CheckedUsers'
import GeneralInfo from './parts/GeneralInfo'
import PersonalInfo from './parts/PersonalInfo'
import styles from './TopPanel.module.scss'
import { Button, ButtonColors } from '../../../../../../simples/Button'
import { ProfileService } from '../../../../../../../services/ProfileService'
// @ts-ignore
import $message from 'message-like-antd'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/store'
import { userFilter } from '../../../../../../../store/slice/filters'
import { AccessRightType, ProfileType, RolesKind } from '../../../../../../../types/types'
import produce from 'immer'
import { selectUser } from '../../../../../../../store/slice/auth'
import { ModalClose } from '../../../../../../simples/ModalClose'
import { DeleteModal } from '../../../../../../complexes/DeleteModal'
import TabelTypes from './parts/TabelTypes'
// import produce from 'immer'

const TopPanel: FC = (): JSX.Element => {
    const aUser = useAppSelector(state => state.tabel.activeUser)
    console.log(aUser);

    const [activeUser, setActiveUser] = useContext(ActiveUserContext)
    const filter = useAppSelector(userFilter)
    const [list, setList] = useContext(UsersContext)
    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const fullName: string = useMemo((): string => {
        const fName = `${activeUser.lastName || ''} ${activeUser.firstName || ''
            } ${activeUser.secondName || ''}`
        return fName
    }, [activeUser])
    console.log('activeUser', activeUser);

    /**
     * увольнение сотрудника
     */
    const onFire = useCallback(async () => {

        try {
            const fired = activeUser.fired ? 0 : 1
            const response = await ProfileService.uploadProfileId({ ...activeUser, fired }, activeUser.id)
            console.log('vsdvs', response);
            if (response.permanentDelete) {
                setList(prev => prev.filter(i => i.id !== response.id))
            }
            if (filter.fired === 0 || (response.permanentDelete && response.fired)) {
                console.log('delete');

                setList(prev => prev.filter(i => i.id !== response.id))
                setActiveUser({} as ProfileType)

            } else {
                setList(produce(
                    draft => {
                        const find: any = draft.find(i => i.id === response.id)
                        const NewData: any = response as ProfileType
                        if (find) {
                            for (let key in find) {
                                find[key] = NewData[key]
                            }
                        }
                    }
                ))
                setActiveUser(response)
            }
            $message.success(response.fired ? 'Сотрудник успешно уволен' : 'Сотрудник успешно восстановлен')
            setIsShowModal(false)
        } catch (error: any) {
            if (error.response.data.status === 403) {
                $message.error(error.response.data.message, [750])
            } else {
                const errors = error.response.data.errors

                for (let key of errors) {
                    $message.error(key, [750])
                }
            }
        }



    }, [activeUser, filter.fired, setActiveUser, setList])

    const selectAuth = useAppSelector(selectUser)
    // console.log('selectAuth', selectAuth);


    const personalInfo = useMemo(() => {
        if (selectAuth?.role === RolesKind.ADMIN) {
            return true
        }
        else if (!!!selectAuth?.accessRights.length || !selectAuth?.accessRights.includes(AccessRightType.ViewPersonalInfo)) {
            return false
        } else {
            return true
        }
    },
        [selectAuth])


    const clickDeleteUser = () => {
        activeUser.fired ? onFire() : setIsShowModal(true)

    }

    const personalInfoView = useMemo(() => {
        if (selectAuth?.role === RolesKind.ADMIN) {
            return true
        } else if (!selectAuth?.accessRights.length) {
            return false
        }
    }, [selectAuth])

    return (
        <>
            <div className={styles.root}>
                <div className={styles.topWrapper}>
                    <Title>{fullName}</Title>
                    {activeUser?.id && <Button
                        onClick={clickDeleteUser}
                        color={ButtonColors.Light}
                        className={styles.changeBtn}
                    >
                        {activeUser.fired ? 'Восстановить' : 'Уволить'}
                    </Button>}
                </div>
                {activeUser?.id ? (
                    <>
                        <GeneralInfo />
                        <TabelTypes />
                        {personalInfoView ? <PersonalInfo /> : ''}
                    </>
                ) : (
                    <CheckedUsers />
                )}
            </div>
            {isShowModal && <DeleteModal
                onSubmit={onFire}
                onCancel={() => setIsShowModal(false)}
                text={'Вы хотите удалить данного пользователя?'}
            />}
        </>

    )
}

export default TopPanel
