import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ByRoleOptions } from '@testing-library/react'
import { ColorService } from '../../services/ColorService'
import { FilialService } from '../../services/FilialService'
import { PositionsService } from '../../services/PositionsServece'
import { ProfileService } from '../../services/ProfileService'
import { RightsService } from '../../services/RightsService'
import { TabelsGroupService } from '../../services/TabelsGroupService'
import { TabelsService } from '../../services/TabelsService'
import { TabelTypeService } from '../../services/TabelTypeService'
import {
    AccessRightsType,
    BranchType,
    ColorType,
    NotificationType,
    PositionType,
    RoleType,
    TabelGroup,
    TabelTypeType,
} from '../../types/types'
import { RootState } from '../index'

/**
 * Начальное состояние редьюсера Auth/
 */

type StateType = {
    tabelGroups: Array<TabelGroup>
    loading: boolean
    filials: Array<BranchType>
    tableNames: Object
    colors?: Array<ColorType>
    positions?: Array<PositionType>
    roles?: Array<RoleType>
    tabelTypes: Array<TabelTypeType>
    accessRights: Array<AccessRightsType>
    notificationsTypes: Array<NotificationType>
}

export const initialState: StateType = {
    tabelGroups: [] as TabelGroup[],
    filials: [] as BranchType[],
    loading: false,
    tableNames: {},
    colors: [] as ColorType[],
    positions: [] as PositionType[],
    roles: [] as RoleType[],
    tabelTypes: [] as TabelTypeType[],
    accessRights: [],
    notificationsTypes: [],
}

// -------------------------------------------
// Async Thunks
// -------------------------------------------

export const fetchTabelGroups = createAsyncThunk(
    'storage/fetchTabelGroups',
    async () => {
        const response = await TabelsGroupService.getTabelsGroups({ limit: 500 })
        return response
    }
)

export const fetchFilials = createAsyncThunk(
    'storage/fetchFilials',
    async () => {
        const response = await FilialService.getFilial({ limit: 500 })
        response.sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });

        return response
    }
)
export const fetchTablesNames = createAsyncThunk(
    'storage/fetchTablesNames',
    async () => {
        const response = await TabelsService.fetchTablesNames()
        return response
    }
)
export const fetchColors = createAsyncThunk('storage/fetchColors', async () => {
    const response = await ColorService.getColors({ limit: 500 })
    return response
})

export const fetchPositions = createAsyncThunk(
    'storage/fetchPositions',
    async () => {
        const response = await PositionsService.getPositions({ limit: 500 })
        return response
    }
)
export const fetchRole = createAsyncThunk('storage/fetchRole', async () => {
    const response = await ProfileService.getRoles()
    return response
})

export const fetchTabelTypes = createAsyncThunk('storage/fetchTabelTypes', async () => {
    const response = await TabelTypeService.getTabeltypes({ limit: 300 })
    return response
})
/**
 * получаем список 
 */
export const fetchAccessRights = createAsyncThunk('storage/fetchAccessRights',
    async () => {
        const response = await RightsService.fetchRights()
        return response
    })
/**
 * получаем типов уведомлений
 */
export const fetchNotificationsTypes = createAsyncThunk('storage/fetchNotificationsTypes',
    async () => {
        const response = await ProfileService.fetchNotificationsType()
        return response
    })

/**
 * Создание слайса App
 */
export const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTabelGroups.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchTabelGroups.fulfilled, (state, action) => {
            state.tabelGroups = action.payload.sort((a, b) => {
                if (a.name < b.name) { return -1 }
                if (a.name > b.name) { return 1 }
                return 0
            })
            state.loading = false
        })
        builder.addCase(fetchFilials.fulfilled, (state, action) => {
            state.filials = action.payload
            state.loading = false
        })
        builder.addCase(fetchPositions.fulfilled, (state, action) => {
            state.positions = action.payload
            state.loading = false
        })
        builder.addCase(fetchRole.fulfilled, (state, action) => {
            state.roles = action.payload
            state.loading = false
        })
        builder.addCase(fetchTablesNames.fulfilled, (state, action) => {
            const t: any = {}

            const s: Array<string> = action.payload

            for (let i = 0; i < action.payload.length; i++) {
                t[s[i]] = action.payload[i]
            }
            state.tableNames = t
            state.loading = false
        })
        builder.addCase(fetchColors.fulfilled, (state, action) => {
            state.colors = action.payload
        })
        builder.addCase(fetchTabelTypes.fulfilled, (state, action) => {
            state.tabelTypes = action.payload
        })
        builder.addCase(fetchAccessRights.fulfilled, (state, action) => {
            state.accessRights = action.payload
        })
        builder.addCase(fetchNotificationsTypes.fulfilled, (state, action) => {
            state.notificationsTypes = action.payload
        })
    },
})

export const { } = storageSlice.actions

export const selectStorageTabelGroups = (state: RootState) =>
    state.storage.tabelGroups
export const selectTablesNames = (state: RootState) => state.storage.tableNames
export const selectColors = (state: RootState) => state.storage.colors
export const selectPositions = (state: RootState) => state.storage.positions
export const selectRoles = (state: RootState) => state.storage.roles
export const selectAccessRights = (state: RootState) => state.storage.accessRights
// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default storageSlice.reducer
