import React, { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LogService } from '../../../services/LogService'
import { LogType, SortType, TableNames } from '../../../types/types'
import styles from './LogsTable.module.scss'
import TableBody from './parts/TableBody'
import TableHeader from './parts/TableHeader'

type Props = {

}


const LogsTable:FC<Props> = () => {

    const tableType: TableNames = useLocation().search.split('=')[1] as TableNames

    const [logs, setLogs] = useState<LogType []>([])

    const has = useRef(true)
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)

    const load = async () => {
        if (!has.current || loading || !tableType) {
            return
        }

        setLoading(true)
        const result = await LogService.getLogs({
            table: tableType,
            sort: SortType.DESC,
            offset: offset.current,
            limit: 30
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setLogs([...logs, ...result])
    }

    useEffect(() => {
        if(tableType){
            load().then()
        }
    },[])

    return (
            <table className={styles.table}>
                <TableHeader />
                <TableBody data={logs} />
            </table>
    )
}
export default React.memo(LogsTable)