import { produce } from 'immer'
import React, {
    createContext,
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { DepartamentService } from '../../../../../services/DepartamentService'
import { TabelsGroupService } from '../../../../../services/TabelsGroupService'
import {
    AccessRightType,
    RolesKind,
    SortByType,
    SortType,
    TabelGroup as TabelGroupType,
} from '../../../../../types/types'
import { AlertModal } from '../../../../complexes/AlertModal'
import { DeleteModal } from '../../../../complexes/DeleteModal'
import { Button, ButtonColors } from '../../../../simples/Button'
import { OptionType } from '../../../../simples/Selector/Selector'
import Item from './Item'
import styles from './TabelGroup.module.scss'
// @ts-ignore
import $message from 'message-like-antd'
import Header from './Header'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { selectUser } from '../../../../../store/slice/auth'
export const SortNameContext = createContext<
    [SortType, React.Dispatch<React.SetStateAction<SortType>>]
>([SortType.ASC, () => {}])
export const SortByContext = createContext<
    [SortByType | undefined, React.Dispatch<React.SetStateAction<SortByType>>]
>([SortByType.NAME, () => {}])

const TabelGroup: FC = () => {
    const user = useAppSelector(selectUser)
    const canEdit = useMemo(() => {
        return (
            user?.accessRights.includes(
                AccessRightType.EditTabelsGroupsTable
            ) || user?.role === RolesKind.ADMIN
        )
    }, [user])
    const loading = useAppSelector((state) => state.app.isLoading)
    //табельные группы
    const [list, setList] = useState<Array<TabelGroupType>>([])
    const listCurrent = useRef<TabelGroupType[]>(list)
    listCurrent.current = list
    // активная группа
    const [activeGroup, setActiveGroup] = useState<TabelGroupType>()
    // режим редактирования
    const [isEdit, setIsEdit] = useState<boolean>(false)
    // блок для подгрузки динамической
    const mainBlock = useRef<HTMLDivElement>(null)

    const [showModal, setShowModal] = useState<boolean>(false)
    // модалка при удалении
    const [showDelModal, setShowDelModal] = useState<number>()
    const has = useRef(true)
    const offset = useRef(0)
    // опции с выбором филиалов
    const [departOptions, setDepartOptions] = useState<OptionType[]>([])

    // tabel group sort
    const [sort, setSort] = useState<SortType>(SortType.ASC)
    // tabel group sort by
    const [sortBy, setSortBy] = useState<SortByType>(SortByType.NAME)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        const result = await TabelsGroupService.getTabelsGroups({
            sort,
            sortBy,
            offset: offset.current,
            limit: 30,
        })

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setList([...listCurrent.current, ...result])
    }

    const loadDeparts = async () => {
        if (!has.current || loading) {
            return
        }

        const result = await DepartamentService.getDepartaments({
            offset: offset.current,
            limit: 300,
        })

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setDepartOptions(
            Array.from(result, (i) => ({
                label: i.name,
                value: `${i.id}`,
            }))
        )
    }

    const clear = () => {
        listCurrent.current = []
        has.current = true
        offset.current = 0
        setList([])
        load().then()
    }

    useEffect(() => {
        clear()
    }, [sort, sortBy])

    useEffect(() => {
        loadDeparts().then()
    }, [])

    // Метод сброса фокуса с выбранного элемента
    const loseFocus = useCallback(() => {
        setList(
            produce((draft) => {
                const find = draft.find((i) => i.id === activeGroup?.id)
                if (!find?.name && !activeGroup?.name) {
                    setList((prev) =>
                        prev.filter((i) => i.id !== activeGroup?.id)
                    )
                }
                if (
                    find &&
                    (find?.name !== activeGroup?.name ||
                        find?.branchId !== activeGroup?.branchId)
                ) {
                    find.name = activeGroup?.name || ''
                    find.branchId = activeGroup?.branchId as number
                }
            })
        )

        setActiveGroup(undefined)
        setIsEdit(false)
    }, [activeGroup?.branchId, activeGroup?.id, activeGroup?.name])

    const onDeparmetClick = useCallback((group: TabelGroupType) => {
        setActiveGroup(group)
    }, [])

    // Метод закрытия селектора при клике вне самого селектора
    const closeSelectOutOfBlock = useCallback(
        (event: any) => {
            if (mainBlock && mainBlock.current) {
                // Проверка добавлена для устранения бага в Firefox
                if (!mainBlock.current.contains(event.target)) {
                    loseFocus()
                }
            }
        },
        [loseFocus]
    )

    // Установка/удаление обработчика события на документе.
    useEffect(() => {
        document.addEventListener('click', closeSelectOutOfBlock, false)
        return () => {
            document.removeEventListener('click', closeSelectOutOfBlock, false)
        }
    }, [closeSelectOutOfBlock])

    /**
     * Изменяем название Группы
     */
    const onHandleChange = useCallback((id: number, e: string) => {
        setList(
            produce((draft) => {
                const find = draft.find((i) => i.id === id)
                if (find) {
                    find.name = e
                }
            })
        )
    }, [])

    /**
     * Изменяем филиал группы
     */
    const onHandleChangeDepartment = useCallback((id: number, e: string) => {
        setList(
            produce((draft) => {
                const find = draft.find((i) => i.id === id)
                if (find) {
                    find.branchId = +e
                }
            })
        )
    }, [])

    /**
     * Добавляем новую группу
     */
    const onAddDepartment = useCallback(() => {
        setList(
            produce((draft) => {
                const newGroup = {
                    id: new Date().getTime(),
                    name: '',
                    branchId: null,
                    description: '',
                    isDraft: true,
                }
                draft.push(newGroup)
                setActiveGroup(newGroup)
            })
        )
        setIsEdit(true)
    }, [])

    // сохранение
    const onHandleEditSave = useCallback(async () => {
        if (!isEdit) {
            setIsEdit(true)
            return
        } else {
            if (activeGroup) {
                const find = list.find((i) => i.id === activeGroup.id)
                if (find) {
                    if (!find.name || !find.branchId) {
                        $message.warning('Все поля обязательны для заполнения')
                        return
                    }
                }
                if (!activeGroup.isDraft) {
                    try {
                        if (find) {
                            const response =
                                await TabelsGroupService.updateTabelsGroup(
                                    activeGroup.id,
                                    {
                                        name: find.name,
                                        branchId: find.branchId || 0,
                                    }
                                )
                            setIsEdit(false)
                            setActiveGroup(undefined)
                            setShowModal(false)
                        }
                    } catch (error: any) {
                        if (error.response.data.status === 403) {
                            $message.error(error.response.data.message, [750])
                        } else {
                            const errors = error.response.data.errors

                            for (let key of errors) {
                                $message.error(key, [750])
                            }
                        }
                    }
                } else {
                    try {
                        if (find) {
                            const response =
                                await TabelsGroupService.createTabelsGroup({
                                    name: find.name,
                                    branchId: find.branchId || 0,
                                })

                            // после сохранения обновляем табельную группу в списке записывая данные с бэка
                            setList(
                                produce((draft) => {
                                    const find = draft.find(
                                        (i) => i.id === activeGroup.id
                                    )
                                    if (find) {
                                        find.id = response.id
                                        find.name = response.name
                                        find.description = response.description
                                        find.branchId = response.branchId
                                        find.isDraft = false
                                    }
                                })
                            )
                            setIsEdit(false)
                            setActiveGroup(undefined)
                            setShowModal(false)
                        }
                    } catch (error: any) {
                        if (error.response.data.status === 403) {
                            $message.error(error.response.data.message, [750])
                        } else {
                            const errors = error.response.data.errors

                            for (let key of errors) {
                                $message.error(key, [750])
                            }
                        }
                    }
                }
            }
        }
    }, [isEdit, activeGroup, list])

    const onModalCancel = useCallback(() => {
        setList(
            produce((draft) => {
                const find = draft.find((i) => i.id === activeGroup?.id)
                setShowModal(false)
                if (find) {
                    find.name = activeGroup?.name || ''
                    find.branchId = activeGroup?.branchId || 0
                }
            })
        )
    }, [activeGroup?.id, activeGroup?.name, activeGroup?.branchId])

    /**
     * Функция удаления Табельной группы
     */
    const onDeleteTabelGroup = useCallback(async () => {
        if (!showDelModal) {
            return
        }
        try {
            const response = await TabelsGroupService.deleteTabelGroup(
                showDelModal
            )
            setList((prev) => prev.filter((i) => i.id !== showDelModal))
            setShowDelModal(undefined)
            $message.success(response)
        } catch (error: any) {
            if (error.response.data.status === 403) {
                $message.error(error.response.data.message, [750])
            } else {
                const errors = error.response.data.errors

                for (let key of errors) {
                    $message.error(key, [750])
                }
            }
        }
    }, [showDelModal])

    return (
        <>
            <div ref={mainBlock} className={styles.list}>
                <SortNameContext.Provider value={[sort, setSort]}>
                    <SortByContext.Provider value={[sortBy, setSortBy]}>
                        <Header />
                    </SortByContext.Provider>
                </SortNameContext.Provider>
                {list.map((department, index) => (
                    <Item
                        key={department.id}
                        isEdit={activeGroup?.id === department.id && isEdit}
                        options={departOptions}
                        activeGroup={activeGroup}
                        department={department}
                        onClick={onDeparmetClick}
                        onChange={onHandleChange}
                        onChangeDepartment={onHandleChangeDepartment}
                        index={index}
                    />
                ))}
                {canEdit && (
                    <div className={styles.btnBlock}>
                        <Button
                            onClick={onAddDepartment}
                            className={styles.button}
                            color={ButtonColors.Light}
                        >
                            Добавить группу
                        </Button>

                        {!!activeGroup && (
                            <Button
                                // disabled={!activeGroup}
                                onClick={onHandleEditSave}
                                className={styles.button}
                                color={ButtonColors.Light}
                            >
                                {!!!activeGroup || !isEdit
                                    ? 'Редактировать'
                                    : 'Сохранить'}
                            </Button>
                        )}

                        {!!activeGroup && !activeGroup.isDraft && (
                            <Button
                                onClick={() => setShowDelModal(activeGroup.id)}
                                className={styles.button}
                                color={ButtonColors.Main}
                            >
                                Удалить
                            </Button>
                        )}
                    </div>
                )}
            </div>
            {showModal && (
                <AlertModal
                    onSubmit={onHandleEditSave}
                    onCancel={onModalCancel}
                    onClose={() => {
                        setShowModal(false)
                        setActiveGroup((prev) => prev)
                    }}
                />
            )}

            {showDelModal && (
                <DeleteModal
                    onSubmit={onDeleteTabelGroup}
                    onCancel={() => setShowDelModal(undefined)}
                    text={
                        'При удалении табельной группы вы потеряете все данные связанные с данной табельной группой'
                    }
                />
            )}
        </>
    )
}

export default TabelGroup
