/* eslint-disable array-callback-return */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NewsService } from '../../../../../services/NewsService';
import { AccessRightType, AddNewUserType, BirthdayUserType, DashboardItemType, NewsType, RolesKind, SortByType, SortType, TabelNoutType } from '../../../../../types/types';
import { CreateNewsModal } from '../../../../complexes/CreateNewsModal';
import { BlockWrapper } from '../../../../simples/BlockWrapper';
import { Button, ButtonColors } from '../../../../simples/Button';
import { Title } from '../../../../simples/Title';
import { AddNewUserCard } from './cards/AddNewUserCard';
import { BirthDayCard } from './cards/BirthDayCard';
import { TabelNoteCard } from './cards/TabelNoteCard';
import styles from './StaffBirhdays.module.scss'
// @ts-ignore
import $message from 'message-like-antd'
import { useAppSelector } from '../../../../../hooks/store';
import { selectUser } from '../../../../../store/slice/auth';

const StaffBirhdays: FC = () => {

    const currentUser = useAppSelector(selectUser)
    const canCreateNews = useMemo(() => {
        return currentUser?.accessRights.includes(AccessRightType.PublishNews) || currentUser?.role === RolesKind.ADMIN
    },[currentUser?.accessRights, currentUser?.role])

    const [list, setList] = useState<Array<NewsType>>([])

    const [notifications, setNotifications] = useState<Array<BirthdayUserType |
        AddNewUserType |
        TabelNoutType>>([])

    const [showmodal, setShowModal] = useState<boolean>(false)

    const has = useRef(true)
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await NewsService.getNews({
            offset: offset.current,
            sort: SortType.DESC,
            sortBy: SortByType.CREATE_AT,
            limit: 30
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setList([...list, ...result])
    }

    const createNews = useCallback(async (content: string) => {
        try {
            const response = await NewsService.createNews({ content })

            setList(prev => [response, ...prev])
            setShowModal(false)
        } catch (error: any) {
            if(error.response.data.status === 403){
                $message.error(error.response.data.message, [750])
            } else {
                const errors = error.response.data.errors

                for(let key of errors){
                    $message.error(key, [750])
                }
            }
        }
    }, [])

    useEffect(() => {
        load().then()
    }, [])
    return (
        <>
            <div className={styles.root}>
                <div className={styles.halfBlock}>
                    {notifications.length > 0 && <>
                        <Title>Уведомления</Title>


                        <div className={styles.listWrapper}>

                            {notifications.map((news, index) => {
                                switch (news.type) {
                                    case DashboardItemType.ADD_NEW_USER:
                                        return <BlockWrapper key={index}><AddNewUserCard data={news} /></BlockWrapper>

                                    case DashboardItemType.BIRTHDAY:
                                        return <BlockWrapper key={index}><BirthDayCard data={news} /></BlockWrapper>

                                    case DashboardItemType.TABEL_NOUT:
                                        return <BlockWrapper key={index}><TabelNoteCard data={news} /></BlockWrapper>

                                }
                            })}
                        </div>
                    </>}
                    <Title>Сообщение за сегодня</Title>


                    <div className={styles.listWrapper}>

                        {list.map((news) => {
                            return <BlockWrapper key={news.id}>{news.content}</BlockWrapper>
                        })}
                    </div>

                    <div className={styles.note}>
                        ( для просмотра данных сообшений необходимо войти в систему)
                    </div>
                    {canCreateNews && <Button
                        onClick={() => setShowModal(true)}
                        className={styles.button}
                        color={ButtonColors.Light}
                    >Добавить новость</Button>}
                </div>
                <div className={styles.halfBlock}>
                    <Title>Сегодня работаем над</Title>

                    <div className={styles.listWrapper}>
                        {/* <BlockWrapper></BlockWrapper> it's should be dynamic but still not( */}
                    </div>
                </div>
            </div>
            {showmodal && <CreateNewsModal
                onCreate={createNews}
                onClose={() => setShowModal(false)}
            />}
        </>
    )
}

export default StaffBirhdays;