import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../store/slice/auth'
import appReducer from '../store/slice/app'
import tabelReducer from '../store/slice/tabel'
import storageReducer from '../store/slice/storage'
import staffReducer from '../store/slice/staff'
import filtersReducer from '../store/slice/filters'


export const store = configureStore({
    reducer: {
        auth: authReducer,
        tabel: tabelReducer,
        staff: staffReducer,
        app: appReducer,
        storage: storageReducer,
        filters: filtersReducer
    },
})

// Вывод типов `RootState` и `AppDispatch` из стора.
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
