import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './PartsSettings.module.scss'

type Props = {

}

const TableHeader:FC<Props> = () => {
    return (
        <thead>
            <tr className={styles.rowHeader}>
                <th className={classNames(styles.headerCell, styles.headerCell_1)}>ID</th>
                <th className={classNames(styles.headerCell, styles.headerCell_2)}>Название (уникальное)</th>
                <th className={classNames(styles.headerCell, styles.headerCell_3)}>Коэффициент</th>
                <th className={classNames(styles.headerCell, styles.headerCell_4)}>Отдельно</th>
                <th className={classNames(styles.headerCell, styles.headerCell_5)}>Метка</th>
                <th className={classNames(styles.headerCell, styles.headerCell_6)}>Цвет</th>
                <th className={classNames(styles.headerCell, styles.headerCell_7)}>Примечание</th>
            </tr>
        </thead>
    )
}

export default React.memo(TableHeader);
