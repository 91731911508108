import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'

/**
 * Начальное состояние редьюсера Auth/
 */

type StateType = {
    checkedUsers: Array<number>

}

export const initialState: StateType = {
    checkedUsers:[] as Array<number>

}



/**
 * Создание слайса App
 */
export const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        setCheckedUsers: (state, action:PayloadAction<number>) => {
            if(state.checkedUsers.includes(action.payload)){
                const newUsers = state.checkedUsers.filter(i => i !== action.payload)
                state.checkedUsers = newUsers
            } else {
                state.checkedUsers = [...state.checkedUsers, action.payload]
            }
            
        },

        checkAllUsers: (state, action: PayloadAction<Array<number>>) => {
            state.checkedUsers = action.payload
        },
        unCheckAllUsers: (state) => {
            state.checkedUsers = []
        },
        setFillialsChecked: (state, action:PayloadAction<number[]>) => {
            const commonArr: Array<number> = [...state.checkedUsers, ...action.payload]
            state.checkedUsers = commonArr.filter((v, i, a) => a.indexOf(v) === i)
        },
        unCheckedFilial: (state, action:PayloadAction<number[]>) => {
            let difference = state.checkedUsers.filter(x => !state.checkedUsers.includes(x)).concat(state.checkedUsers.filter(x => !state.checkedUsers.includes(x)));
            state.checkedUsers = difference
        },
    },

})




export const {
    setCheckedUsers,
    setFillialsChecked,
    unCheckedFilial,
    checkAllUsers,
    unCheckAllUsers
} = staffSlice.actions


export const selectCheckedUsers = (state: RootState) => state.staff.checkedUsers


// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default staffSlice.reducer
