import React, { FC } from 'react';
import { Button, ButtonColors } from '../../simples/Button';
import { ModalClose } from '../../simples/ModalClose';
import styles from './DeleteModal.module.scss'
import { ReactComponent as WarningIcon } from '../../../assets/icons/Warning.svg'



type Props = {
    onSubmit: () => void
    onCancel: () => void
    onClose?: () => void
    text: string
}

const DeleteModal: FC<Props> = ({ onSubmit, onCancel, onClose, text }) => {
    return (
        <ModalClose
        
        classes={{
            modal: styles.modal
        }}
        close={false}
        onClose={onClose}
        >
            <div className={styles.header}>
                <WarningIcon />
                <h2 className={styles.title}>Удаление</h2>
            </div>

            <div className={styles.content}>{text}</div>

            <div className={styles.btnBlock}>
                <Button
                    className={styles.btn}
                    onClick={onSubmit}
                    color={ButtonColors.Primary}
                >Ок</Button>
                <Button
                 className={styles.btn}
                    onClick={onCancel}
                    color={ButtonColors.Secondary}
                >Отмена</Button>
            </div>

        </ModalClose>
    )
}
export default DeleteModal