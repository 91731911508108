import { useContext, useMemo, useState } from 'react'
import { useAppSelector } from '../../../../../../../../hooks/store'
import { selectUser } from '../../../../../../../../store/slice/auth'
import { AccessRightType, RolesKind } from '../../../../../../../../types/types'
import {
    EditImployerTabelTypesContext,
    ActiveUserContext,
} from '../../../../../StaffPage'
import classNames from 'classnames'
import styles from './PartsInfoUser.module.scss'
import { Title } from '../../../../../../../simples/Title'
import { ReactComponent as MarkIcon } from '../../../../../../../../assets/icons/mark.svg'
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/pencil.svg'

const TabelTypes = () => {
    const [isOpen, setIsOpen] = useState<boolean>(true)
    const currentUser = useAppSelector(selectUser)
    const [editUser] = useContext(ActiveUserContext)
    const [, setTabelTypeUser] = useContext(EditImployerTabelTypesContext)
    const tabelTypes = useAppSelector((state) => state.storage.tabelTypes)

    const canEdit = useMemo(
        () =>
            currentUser?.accessRights.includes(AccessRightType.EditStaff) ||
            currentUser?.role === RolesKind.ADMIN ||
            (currentUser?.accessRights.includes(
                AccessRightType.EditTabelSelfGroup
            ) &&
                currentUser?.id === editUser?.boss),
        [
            currentUser?.accessRights,
            currentUser?.id,
            currentUser?.role,
            editUser?.boss,
        ]
    )

    const switchIsOpen = () => setIsOpen((prev) => !prev)
    const getNameTabelById = (tableId: number) => {
        return tabelTypes.find((el) => el.id === tableId)?.name || ''
    }

    return (
        <div className={styles.root}>
            <div className={styles.top}>
                <div
                    onClick={switchIsOpen}
                    className={classNames(
                        styles.flexBlock,
                        styles.cursorPointer
                    )}
                >
                    <MarkIcon
                        className={classNames(styles.icon, {
                            [styles.isOpen]: isOpen,
                        })}
                    />
                    <Title size={14}>Табели</Title>
                </div>
                {canEdit && (
                    <div
                        onClick={setTabelTypeUser.bind(null, editUser)}
                        className={classNames(
                            styles.flexBlock,
                            styles.cursorPointer
                        )}
                    >
                        <EditIcon className={styles.icon} />
                        <span>{'Изменить'}</span>
                    </div>
                )}
            </div>
            {isOpen && (
                <div className={styles.tabels}>
                    {editUser?.tabelsTypes.map((tabelId) => (
                        <p className={styles.tabel} key={tabelId}>
                            {getNameTabelById(tabelId)}
                        </p>
                    ))}
                </div>
            )}
            <span className={styles.line} />
        </div>
    )
}

export default TabelTypes
