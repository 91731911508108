import React, {
    createContext,
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { ProfileService } from '../../../services/ProfileService'
import { checkAllUsers, unCheckAllUsers } from '../../../store/slice/staff'
import { ProfileType, SortByType, SortType } from '../../../types/types'
import { EditImployerModal } from '../../complexes/EditImployerModal'
import { EditUserModal } from '../../complexes/EditUserModal'
import { Layout } from '../../complexes/Layout'
import { Header } from './parts/Header'
import { RightPanel } from './parts/RightPanel'
import { Table } from './parts/Table'

// @ts-ignore
import $message from 'message-like-antd'
import styles from './StaffPage.module.scss'
import { userFilter } from '../../../store/slice/filters'
import produce from 'immer'
import { localStorageType } from '../../complexes/EditUserModal/EditUserModal'
import { EditTypesTabelEmployerModal } from '../../complexes/EditTypesTabelEmployerModal'

export const CheckAllContext = createContext<
    [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => {}])
export const SortUsersContext = createContext<
    [
        {
            sort: SortType
            sortBy: SortByType
        },
        React.Dispatch<
            React.SetStateAction<{
                sort: SortType
                sortBy: SortByType
            }>
        >
    ]
>([
    {
        sort: SortType.ASC,
        sortBy: SortByType.TABEL_NUMBER,
    },
    () => {},
])
export const CheckedUsersContext = createContext<
    [Array<number>, React.Dispatch<React.SetStateAction<number[]>>]
>([[], () => {}])
export const ActiveUserContext = createContext<
    [ProfileType, React.Dispatch<React.SetStateAction<ProfileType>>]
>([{} as ProfileType, () => {}])
export const UsersContext = createContext<
    [ProfileType[], React.Dispatch<React.SetStateAction<ProfileType[]>>]
>([[] as ProfileType[], () => {}])

export const AddUserContext = createContext<
    [
        ProfileType | undefined,
        React.Dispatch<React.SetStateAction<ProfileType | undefined>>
    ]
>([undefined, () => {}])
export const AddImployerContext = createContext<
    [
        ProfileType | undefined,
        React.Dispatch<React.SetStateAction<ProfileType | undefined>>
    ]
>([undefined, () => {}])

export const EditImployerTabelTypesContext = createContext<
    [
        ProfileType | undefined,
        React.Dispatch<React.SetStateAction<ProfileType | undefined>>
    ]
>([undefined, () => {}])

const StaffPage: FC = () => {
    const dispatch = useAppDispatch()
    // состояние загрузки фильтра с бэка/ мы не подгружаем пользователей пока не подгружены фильтры для избежания перендера
    const loadFilter = useAppSelector((state) => state.filters.isLoading)

    // фильтр по персоналу
    const filter = useAppSelector(userFilter)

    const userWrapperRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLDivElement>(null)
    const reached = useRef(false)

    // отмечены ли все пользователи
    const [checkAll, setCheckAll] = useState<boolean>(false)

    // состояние сортировки
    const [sort, setSort] = useState<{
        sort: SortType
        sortBy: SortByType
    }>({
        sort: SortType.ASC,
        sortBy: SortByType.TABEL_NUMBER,
    })

    /**
     * active user
     */
    const [activeUser, setActiveUser] = useState<ProfileType>({
        lastName: '',
    } as ProfileType)

    /**
     * LOADING
     */
    const [loading, setLoading] = useState<boolean>(false)
    const [list, setList] = useState<Array<ProfileType>>([])
    const currentList = useRef<Array<ProfileType>>(list)
    currentList.current = list
    const offset = useRef(0)
    const has = useRef(true)

    /**
     * add User modal
     */
    const [showAddUserModal, setShowAddUserModal] = useState<ProfileType>()

    /**
     * add imployer modal
     */
    const [showAddImployerModal, setShowImployerModal] = useState<ProfileType>()

    /**
     * eddit imployer tabel types
     */
    const [showEdditImployerTabelTypesModal, setEdditImployerTabelTypesModal] =
        useState<ProfileType>()

    /**
     * table height
     */
    const tableHeight = useMemo(() => {
        let height: number = 900
        if (headerRef && headerRef.current) {
            height = window.innerHeight - headerRef.current?.clientHeight - 60
        }
        return height
    }, [headerRef.current?.clientHeight])

    // при отмечании всех пользователей добавляем их ИД в редакс
    useEffect(() => {
        if (checkAll) {
            dispatch(checkAllUsers(list.map((i) => i.id)))
        } else {
            dispatch(unCheckAllUsers())
        }
    }, [checkAll, dispatch, list])

    // запрос на все Филиалы
    const load = async () => {
        if (!has.current || loading) {
            return
        }
        setLoading(true)
        const result = await ProfileService.getProfile({
            ...filter,
            ...sort,
            offset: offset.current,
            limit: 30,
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
        }

        offset.current = offset.current + result.length
        setList([...currentList.current, ...result])
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        currentList.current = []
        setList([])
    }

    const handleSearch = async () => {
        clear()
        load().then()
    }

    useEffect(() => {
        if (!loadFilter) {
            handleSearch()
        }
    }, [
        filter,
        filter.branchId,
        filter.fired,
        filter.search,
        loadFilter,
        filter.tabelGroupId,
        sort.sort,
        sort.sortBy,
    ])

    /**
     *
     * dynamic pagination
     */
    const handleScroll = () => {
        if (!userWrapperRef.current) {
            return
        }

        const contentHeight = userWrapperRef.current.offsetHeight
        const scrollHeight = userWrapperRef.current.scrollHeight

        const scrollTop = userWrapperRef.current.scrollTop

        if (scrollHeight <= contentHeight) {
            return
        }

        const afterEndReach =
            scrollHeight - (scrollTop + contentHeight) < contentHeight / 2

        if (afterEndReach && !reached.current) {
            reached.current = true

            load().then()
        } else if (!afterEndReach && reached.current) {
            reached.current = false
        }
    }

    /**
     * сохранение изменений у сотрудника или добавление нового
     */
    const onSaveImployer = useCallback(
        async (user: ProfileType) => {
            if (user && user.id) {
                try {
                    let response: ProfileType
                    response = await ProfileService.uploadProfileId(
                        { ...user, active: 1 },
                        user.id
                    )

                    if (user.tabelsTypes && user.tabelsTypes.length > 0) {
                        response = await ProfileService.setTabelsTypes(
                            user.id,
                            user.tabelsTypes
                        )
                    }
                    setList(
                        produce((draft) => {
                            const find = draft.find(
                                (i) => i.id === user.id
                            ) as ProfileType as any
                            const aI = response as ProfileType as any
                            if (find) {
                                for (let key in find) {
                                    find[key] = aI[key]
                                }
                            }
                        })
                    )

                    setActiveUser(response)

                    $message.success('Пользователь успешно обновлен', [750])
                    setShowImployerModal(undefined)
                } catch (error: any) {
                    if (error.response.data.status === 403) {
                        $message.error(error.response.data.message, [750])
                    } else {
                        const errors = error.response.data.errors

                        for (let key of errors) {
                            $message.error(key, [750])
                        }
                    }
                }
            }
            if (user && !user.id) {
                try {
                    const response = await ProfileService.createProfile({
                        ...user,
                        isUser: 0,
                        active: 1,
                    })

                    /**
                     * проверяем подходит ли добавленный пользователь под фильтр
                     * если подходит то добавляем его в список
                     */
                    if (
                        (filter.branchId &&
                            +filter.branchId === response.branchId &&
                            response.lastName.includes(filter.search || '')) ||
                        (!filter.branchId &&
                            filter.tabelGroupId &&
                            +filter.tabelGroupId === response.tabelGroupId &&
                            response.lastName.includes(filter.search || '')) ||
                        (filter.search &&
                            response.lastName.includes(filter.search || '')) ||
                        (!filter.tabelGroupId && !filter.branchId)
                    ) {
                        setList(
                            produce((draft) => {
                                draft.unshift(response)
                            })
                        )
                    }

                    $message.success('Пользователь успешно добавлен', [750])
                    setShowImployerModal(undefined)
                } catch (error: any) {
                    if (error.response.data.status === 403) {
                        $message.error(error.response.data.message, [750])
                    } else {
                        const errors = error.response.data.errors

                        for (let key of errors) {
                            $message.error(key, [750])
                        }
                    }
                }
            }
        },
        [filter.branchId, filter.search, filter.tabelGroupId]
    )

    /**
     * сохранение пользователя
     */
    const onSaveUser = useCallback(
        async (data: localStorageType) => {
            if (showAddUserModal && showAddUserModal.id) {
                try {
                    let response: ProfileType

                    response = await ProfileService.uploadProfileId(
                        { ...showAddUserModal, ...data, isUser: 1 },
                        showAddUserModal.id
                    )
                    if (showAddUserModal.accessRights) {
                        response = await ProfileService.setAccessRights(
                            showAddUserModal.id,
                            showAddUserModal.accessRights
                        )
                    }
                    // обновляем данные в списке пользователей
                    setList(
                        produce((draft) => {
                            const find: any = draft.find(
                                (i) => i.id === response.id
                            )
                            if (find) {
                                const data: any = response
                                for (let key in find) {
                                    find[key] = data[key]
                                }
                            }
                        })
                    )

                    $message.success('Пользователь успешно обновлен', [750])
                    setShowAddUserModal(undefined)
                    return response
                } catch (error: any) {
                    if (error.response.data.status === 403) {
                        $message.error(error.response.data.message, [750])
                    } else {
                        const errors = error.response.data.errors

                        for (let key of errors) {
                            $message.error(key, [750])
                        }
                    }
                    throw error
                }
            }
        },
        [showAddUserModal]
    )

    /**
     * вывод компонента
     */

    return (
        <Layout onEndReached={load}>
            <UsersContext.Provider value={[list, setList]}>
                <div className={styles.root}>
                    <div className={styles.left}>
                        <div ref={headerRef} className={styles.header}>
                            <AddImployerContext.Provider
                                value={[
                                    showAddImployerModal,
                                    setShowImployerModal,
                                ]}
                            >
                                <Header />
                            </AddImployerContext.Provider>
                        </div>

                        <div
                            ref={userWrapperRef}
                            onScroll={handleScroll}
                            style={{
                                maxHeight: tableHeight,
                                overflowY: 'auto',
                                minHeight: 500,
                            }}
                            className=""
                        >
                            <CheckAllContext.Provider
                                value={[checkAll, setCheckAll]}
                            >
                                <SortUsersContext.Provider
                                    value={[sort, setSort]}
                                >
                                    <ActiveUserContext.Provider
                                        value={[activeUser, setActiveUser]}
                                    >
                                        <AddUserContext.Provider
                                            value={[
                                                showAddUserModal,
                                                setShowAddUserModal,
                                            ]}
                                        >
                                            <AddImployerContext.Provider
                                                value={[
                                                    showAddImployerModal,
                                                    setShowImployerModal,
                                                ]}
                                            >
                                                <Table data={list} />
                                            </AddImployerContext.Provider>
                                        </AddUserContext.Provider>
                                    </ActiveUserContext.Provider>
                                </SortUsersContext.Provider>
                            </CheckAllContext.Provider>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <ActiveUserContext.Provider
                            value={[activeUser, setActiveUser]}
                        >
                            <AddImployerContext.Provider
                                value={[
                                    showAddImployerModal,
                                    setShowImployerModal,
                                ]}
                            >
                                <EditImployerTabelTypesContext.Provider
                                    value={[
                                        showEdditImployerTabelTypesModal,
                                        setEdditImployerTabelTypesModal,
                                    ]}
                                >
                                    <RightPanel />
                                </EditImployerTabelTypesContext.Provider>
                            </AddImployerContext.Provider>
                        </ActiveUserContext.Provider>
                    </div>

                    {showAddUserModal && (
                        <AddUserContext.Provider
                            value={[showAddUserModal, setShowAddUserModal]}
                        >
                            <EditUserModal
                                onClose={function (): void {
                                    setShowAddUserModal(undefined)
                                }}
                                onSubmit={onSaveUser}
                                user={showAddUserModal}
                            />
                        </AddUserContext.Provider>
                    )}

                    {showAddImployerModal && (
                        <AddImployerContext.Provider
                            value={[showAddImployerModal, setShowImployerModal]}
                        >
                            <EditImployerModal
                                onClose={function (): void {
                                    setShowImployerModal(undefined)
                                }}
                                onSubmit={onSaveImployer.bind(
                                    null,
                                    showAddImployerModal
                                )}
                                user={showAddImployerModal}
                            />
                        </AddImployerContext.Provider>
                    )}
                    {showEdditImployerTabelTypesModal && (
                        <EditImployerTabelTypesContext.Provider
                            value={[
                                showEdditImployerTabelTypesModal,
                                setEdditImployerTabelTypesModal,
                            ]}
                        >
                            <EditTypesTabelEmployerModal
                                onClose={setEdditImployerTabelTypesModal.bind(
                                    null,
                                    undefined
                                )}
                                onSubmit={onSaveImployer.bind(
                                    null,
                                    showEdditImployerTabelTypesModal
                                )}
                                choosed={
                                    showEdditImployerTabelTypesModal?.tabelsTypes ||
                                    []
                                }
                            />
                        </EditImployerTabelTypesContext.Provider>
                    )}
                </div>
            </UsersContext.Provider>
        </Layout>
    )
}

export default StaffPage
