import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './ButtonWithIcon.module.scss'


type Props = {
    label?: string
    icon: JSX.Element
    onClick: () => void
    disabled?: boolean
    classes?: {
        root?: string
    }
    isActive?: boolean
}

/**
 * 
 * @param label 
 * @param icon 
 * @param onClick 
 * @param disabled 
 * @param disabled 
 * @param isActive 
 * @returns 
 */
const ButtonWithIcon:FC<Props> = ({label, icon, onClick, disabled, classes, isActive}) => {
    return (
        <div 
        onClick={onClick}
        className={classNames(styles.root, classes?.root,{
            [styles.disabled]:disabled,
            [styles.active]: isActive
        })}>
            <div className={styles.icon}>{icon}</div>
            <span>{label}</span>
        </div>
    )
}

export default React.memo(ButtonWithIcon);