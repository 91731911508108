import React, { FC, useEffect, useRef, useState } from 'react'
import { LogService } from '../../../services/LogService'
import { LogType } from '../../../types/types'
import { Layout } from '../../complexes/Layout'
import { LogsTable } from '../../complexes/LogsTable'
import { BlockWrapper } from '../../simples/BlockWrapper'
import { Title } from '../../simples/Title'
import styles from './LogsPage.module.scss'


const LogsPage:FC = () => {

    const [list, setList] = useState<Array<LogType>>([])
    const [activeDepart, setActiveDepart] = useState<LogType>()
    const currentId = useRef<number>(0)

    const mainBlock = useRef<HTMLDivElement>(null)

    const has = useRef(true)
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await LogService.getLogs({
            offset: offset.current,
            limit: 30
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setList([...list, ...result])
    }

    useEffect(() => {
        load().then()
    }, [])

    return (
        <Layout>
            <div className={styles.top}>
                <BlockWrapper><Title size={24} weigth={'normal'}>Логирование</Title></BlockWrapper>
            </div>

            <div className="">
                <LogsTable />
            </div>
        </Layout>
    )
}

export default LogsPage;