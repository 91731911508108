import {
    FC,
    KeyboardEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { TabelsService } from '../../../services/TabelsService'
import {
    ACellType,
    activeCellTable,
    changeCell,
    clearActiveUserTabel,
    setActiveCellTable,
    setActiveUserTabel,
    setDateTabel,
    setTableTypesActive,
    setUserTabelNumber,
} from '../../../store/slice/tabel'
import { IUpdateData, TabelTableType } from '../../../types/types'
import { Layout } from '../../complexes/Layout'
import { Header } from './parts/Header'
import { RightPanel } from './parts/RightPanel'
import { Table } from './parts/Table'
// @ts-ignore
import $message from 'message-like-antd'
import { DayOffService } from '../../../services/DayOffService'
import styles from './TabelsPage.module.scss'
import { tabelFilter } from '../../../store/slice/filters'
import { addZero } from '../../../constants/helper'
import {
    ArrowEnableContext,
    ChangeActiveUser,
    ClearAllDayContext,
    DayOffContext,
    EditDayContext,
    KeyboardKey,
    KeyDirection,
    ListContext,
    UpdateDataContext,
} from './config'
import { useIsDebounce } from '../../../hooks/handle'
import { VACATION_TABEL, VACATION_VAL } from '../../../constants/tabel'

const TabelsPage: FC = () => {
    const headerRef = useRef<HTMLDivElement>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const isLoadFilter = useAppSelector((state) => state.filters.isLoading)
    const filter = useAppSelector(tabelFilter)
    const dispatch = useAppDispatch()
    const has = useRef(true)
    const offset = useRef(0)
    const prevACell = useRef<ACellType>()
    const aCell = useAppSelector(activeCellTable)
    const aUser = useAppSelector((state) => state.tabel.activeUser)
    const [tabels, setTabels] = useState<TabelTableType[]>([])

    const currentTabels = useRef<Array<TabelTableType>>(tabels)
    currentTabels.current = tabels

    const tabelNumber = useAppSelector((state) => state.tabel.userTabelNumber)
    /**
     * необходим для получения табеля по покинотому сотруднику при переходе вниз по сотрудникам
     */
    const prevTableNumber = useRef<number>()
    const [daysOff, setDaysOff] = useState<string[]>([])
    const [editDay, setEditDay] = useState<string | undefined>()
    const isDebouncedEditDay = useIsDebounce(editDay, 1000)

    // правые стрелки
    const [arrowDirection, setArrowDirection] = useState<KeyDirection>(
        KeyDirection.RIGHT
    )

    const getCurrentDay = useCallback(
        () =>
            `${filter?.year}-${filter?.month}-${
                addZero(aCell && aCell.day ? +aCell.day : 1) || '01'
            }T23:23:23`,
        [aCell, filter?.month, filter?.year]
    )

    const getVacationCell = useCallback(
        (data?: IUpdateData) => {
            const activeCell = data?.aCell || aCell

            if (!activeCell || !aUser || !activeCell.day || !tabels.length)
                return null
            const userTabelIndex = tabels.findIndex(
                (tabel) => tabel.id === aUser.id
            )
            const tabelTypes = tabels[userTabelIndex]?.tabelsTypes
            const vacationTabelType = tabelTypes.find(
                (tabelType) => tabelType.name === VACATION_TABEL
            )
            let vacationCell = vacationTabelType?.tabels[activeCell.day]
            if (vacationTabelType) {
                const tabelId = vacationTabelType.id
                const day = activeCell.day
                const color = vacationTabelType.color.color
                //@ts-ignore
                const value = vacationCell === 0 ? VACATION_TABEL : vacationCell
                const tabelName = vacationTabelType.name
                //@ts-ignore
                vacationCell = { tabelId, day, color, value, tabelName }
            }
            return vacationTabelType ? vacationCell : null
        },
        [aCell, aUser, tabels]
    )

    const updateLocalCell = useCallback(
        (newCell, activeCell, data?: IUpdateData) => {
            const activeUser = data?.aUser || aUser

            if (!activeCell || !activeUser || !activeCell.day || !tabels.length)
                return
            const { id: userId } = activeUser
            setTabels((prevTabels) => {
                if (!prevTabels.length) return prevTabels
                const newData: TabelTableType[] = JSON.parse(
                    JSON.stringify(prevTabels)
                )
                const userTabelIndex = newData.findIndex(
                    (tabel) => tabel.id === userId
                )
                const tabelTypes = newData[userTabelIndex]?.tabelsTypes
                const tabelIndex = tabelTypes.findIndex(
                    (el) => el.id === activeCell.tabelId
                )
                const aTabelType = tabelTypes[tabelIndex]
                //@ts-ignore - Todo: fix all types on refactor
                const newActiveTabel = {
                    id: newCell.id,
                    hours: newCell.hours,
                    isVacation: false,
                }
                //@ts-ignore - Todo: fix all types on refactor
                aTabelType.tabels[activeCell.day] = newActiveTabel
                const newTotal = tabelTypes.reduce((acc, tabelType) => {
                    const cTabelIndex = tabelTypes.findIndex(
                        (el) => el.id === tabelType.id
                    )
                    const cATabelType = tabelTypes[cTabelIndex]
                    const coeff = cATabelType.coefficient
                    const currTabels = tabelType.tabels
                    const currTabel = currTabels[activeCell.day]
                    const hours = currTabel ? currTabel.hours : 0
                    return Math.trunc(acc + +hours * coeff)
                }, 0)
                //@ts-ignore - Todo: fix all types on refactor
                newData[userTabelIndex].tabelsTotal[activeCell.day] = newTotal
                return newData
            })
            setEditDay(undefined)
        },
        [aUser, tabels]
    )

    const clearLocalAllDay = useCallback(
        (activeCell: ACellType) => {
            if (!activeCell || !aUser || !activeCell.day || !tabels.length)
                return
            const { id: userId } = aUser
            setTabels((prevTabels) => {
                if (!prevTabels.length) return prevTabels
                const newData: TabelTableType[] = JSON.parse(
                    JSON.stringify(prevTabels)
                )
                const userTabelIndex = newData.findIndex(
                    (tabel) => tabel.id === userId
                )
                const tabelTypes = newData[userTabelIndex]?.tabelsTypes
                const clearedTabelTypes = tabelTypes.map((el) => {
                    //@ts-ignore - Todo: fix all types on refactor
                    el.tabels[activeCell?.day] = 0
                    return el
                })
                newData[userTabelIndex].tabelsTypes = clearedTabelTypes
                newData[userTabelIndex].tabelsTotal[activeCell.day] = 0
                return newData
            })
            setEditDay(undefined)
        },
        [aUser, tabels]
    )

    /**
     * table height
     */
    const tableHeight = useMemo(() => {
        let height: number = 900
        if (headerRef && headerRef.current) {
            height = window.innerHeight - headerRef.current?.clientHeight - 60
        }
        return height
    }, [])

    const isNeedUpdate = useCallback(
        (activeCell, value?: string) => {
            if (!activeCell || !aUser || !activeCell.day || !tabels.length)
                return false
            const { id: userId } = aUser
            const userTabelIndex = tabels.findIndex(
                (tabel) => tabel.id === userId
            )
            const tabelTypes = tabels[userTabelIndex]?.tabelsTypes
            const tabelIndex = tabelTypes.findIndex(
                (el) => el.id === activeCell.tabelId
            )
            const aTabelType = tabelTypes[tabelIndex]
            const aTabel = aTabelType?.tabels[activeCell.day]
            const isEditDay = value !== undefined
            //@ts-ignore - fix types on global refactor
            const isChangedVal = aTabel === 0 || aTabel?.hours !== +value
            const isChangedDay = prevACell.current?.day !== activeCell?.day
            const isChangedTabel =
                prevACell.current?.tabelId !== activeCell?.tabelId
            const isChangedACell = isChangedDay || isChangedTabel
            prevACell.current = activeCell
            return (
                isEditDay &&
                isChangedVal &&
                (isDebouncedEditDay || isChangedACell)
            )
        },
        [aUser, isDebouncedEditDay, tabels]
    )

    /**
     * функция обновления данных в табеле при изменении данных ячейке
     */
    const updateData = useCallback(
        async (value?: string, data?: IUpdateData) => {
            const isValVacation = value === VACATION_VAL
            const tNumber = data?.tabelNumber || tabelNumber
            let activeCell = data?.aCell || aCell
            if (isValVacation) {
                const vacationCell = getVacationCell(data)
                if (vacationCell === null) {
                    $message.destroy()
                    setTimeout(() => {
                        $message.error('Ячейка с отпуском не найдена', [100])
                    }, 100)
                    setEditDay(undefined)
                    return
                }
                //@ts-ignore - Todo: fix on global refactor
                activeCell = vacationCell
            }

            if (!isNeedUpdate(activeCell, value)) return
            setLoading(true)
            //@ts-ignore
            const isVacationCell = activeCell?.value?.isVacation
            try {
                if (value !== undefined) {
                    let hours = value === VACATION_VAL ? -1 : +value
                    let updData = {}
                    if (
                        activeCell &&
                        activeCell.value &&
                        !isVacationCell &&
                        activeCell.value.id
                    ) {
                        updData = await TabelsService.updateTabel(
                            activeCell.value.id,
                            {
                                entrance: '',
                                exit: '',
                                hours,
                            }
                        )
                    } else {
                        updData = await TabelsService.createTabel({
                            createdAt:
                                `${filter?.year}-${filter?.month}-${
                                    activeCell && +activeCell?.day < 10
                                        ? '0' + activeCell.day
                                        : activeCell?.day
                                }T23:23:23` || '',
                            userTabelNumber: tNumber || 0,
                            tabelTypeId: activeCell?.tabelId || 0,
                            hours,
                        })
                    }
                    updateLocalCell(updData, activeCell, data)
                }
                $message.destroy()
                setTimeout(() => {
                    $message.success('Данные обновлены', [100])
                }, 100)
            } catch (error: any) {
                if (error.response.data.status === 403) {
                    $message.destroy()
                    $message.error(error.response.data.message, [100])
                } else {
                    const errors = error.response.data.errors

                    $message.destroy()
                    for (let key of errors) {
                        $message.error(key, [100])
                    }
                }
            }
            setLoading(false)
        },
        [
            aCell,
            isNeedUpdate,
            getVacationCell,
            updateLocalCell,
            filter?.year,
            filter?.month,
            tabelNumber,
        ]
    )

    /**
     * Обработка нажатия на клавишу  Enter
     * @param e - значение нажатой клавиши на клавиатуре
     */
    const onPressKeyboard = (
        e: KeyboardEventHandler<HTMLDivElement> | any
    ): void => {
        if (aCell && aCell?.day) {
            switch (e.key) {
                case KeyboardKey.ENTER:
                    updateData(editDay).then()
                    if (arrowDirection === KeyDirection.DOWN) {
                        const find = tabels.findIndex((i) => i.id === aUser?.id)
                        prevTableNumber.current = tabels[find].tabelNumber
                        setNextUserActive()
                        setEditDay(undefined)
                        break
                    }
                    if (arrowDirection === KeyDirection.RIGHT) {
                        dispatch(changeCell({ right: true }))
                        setEditDay(undefined)
                        break
                    }

                    break
                case KeyboardKey.RIGHT:
                    setEditDay(undefined)
                    dispatch(changeCell({ right: true }))

                    break
                case KeyboardKey.DOWN:
                    setEditDay(undefined)
                    dispatch(changeCell({ down: true }))

                    break
                case KeyboardKey.UP:
                    setEditDay(undefined)
                    dispatch(changeCell({ up: true }))

                    break
                case KeyboardKey.LEFT:
                    setEditDay(undefined)
                    dispatch(changeCell({ left: true }))

                    break

                default:
                    break
            }
        }
    }

    /**
     * получаем нерабочии дни за выбранный год
     */
    const fetchDayOffList = useCallback(async () => {
        const response = await DayOffService.getDayOffList({
            dateFrom: `${filter?.year}-01-01`,
            dateTo: `${filter?.year}-12-31`,
        })
        setDaysOff(Array.from(response, (i) => i.date.slice(0, 10)))
    }, [filter?.year])

    const onClearDay = useCallback(
        (aCell) => {
            if (!aUser) return
            TabelsService.clearDay({
                date: getCurrentDay(),
                userId: aUser.id,
            })
                .then(() => {
                    $message.success('День успешно очищен', [100])
                })
                .catch(() => {
                    $message.error('Не удалось очистить день', [100])
                })
            clearLocalAllDay(aCell)
        },
        [aUser, clearLocalAllDay, getCurrentDay]
    )

    /**
     *
     */
    useEffect(() => {
        fetchDayOffList().then()
    }, [fetchDayOffList, filter.year])

    /**
     *
     * подгружаем фильтр с бэка
     */
    const loadFilter = async () => {
        if (!has.current || loading) {
            return
        }
        setLoading(true)

        try {
            const result = await TabelsService.getTabelsTables({
                ...filter,
                offset: offset.current,
                limit: 30,
            })

            if (!result.length) {
                has.current = false
            }

            offset.current = offset.current + result.length

            setTabels([...currentTabels.current, ...result])
        } catch (error: any) {
            if (error.response.data.status === 403) {
                $message.destroy()
                $message.error(error.response.data.message, [100])
            } else {
                const errors = error.response.data.errors

                $message.destroy()
                for (let key of errors) {
                    $message.error(key, [100])
                }
            }
        }
        setLoading(false)
    }

    /**
     * функция очистки при изменении фильтра
     */
    const clear = () => {
        has.current = true
        offset.current = 0
        currentTabels.current = []
        dispatch(setActiveCellTable(undefined))
        dispatch(clearActiveUserTabel())
        setEditDay(undefined)
        setTabels([])
    }

    const handleSearch = () => {
        clear()
        loadFilter()
    }

    /**
     * срабатывает при изменении фильтра
     * удаляем предыдущий результат поиска и записываем новые данные
     */
    useEffect(() => {
        if (filter.month && filter.year && !isLoadFilter) {
            handleSearch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filter,
        filter.lastName,
        filter.branchId,
        filter.tabelNumber,
        filter.sortBy,
        filter.sort,
        filter.hideUnimploy,
        isLoadFilter,
    ])

    /**
     * переключение на следующего пользователя в табеле
     */
    const setNextUserActive = () => {
        const find = tabels.findIndex((i) => i.id === aUser?.id)
        if (find !== undefined) {
            if (find + 1 < tabels.length) {
                // !TODO убрать лишние стэйты
                dispatch(setTableTypesActive(tabels[find + 1].tabelsTypes))
                dispatch(setActiveUserTabel(tabels[find + 1]))
                dispatch(setUserTabelNumber(tabels[find + 1].tabelNumber))

                if (tabels[find + 1].tabelsTypes.length > 0) {
                    const aDay: any = aCell?.day ? aCell.day : '1'

                    dispatch(
                        setActiveCellTable({
                            day: aCell?.day || '1',
                            tabelId: tabels[find + 1].tabelsTypes[0].id,
                            color: tabels[find + 1].tabelsTypes[0].color.color,
                            value: tabels[find + 1].tabelsTypes[0].tabels[aDay],
                        })
                    )
                }
                const currentDay = getCurrentDay()
                dispatch(setDateTabel(currentDay))
            }
        }
    }

    /**
     *
     * dynamic pagination
     */

    const userWrapperRef = useRef<HTMLDivElement>(null)
    const reached = useRef(false)

    const handleScroll = () => {
        if (!userWrapperRef.current) {
            return
        }

        const contentHeight = userWrapperRef.current.offsetHeight
        const scrollHeight = userWrapperRef.current.scrollHeight

        const scrollTop = userWrapperRef.current.scrollTop

        if (scrollHeight <= contentHeight) {
            return
        }

        const afterEndReach =
            scrollHeight - (scrollTop + contentHeight) < contentHeight / 3

        if (afterEndReach && !reached.current) {
            reached.current = true
            loadFilter().then()
        } else if (!afterEndReach && reached.current) {
            reached.current = false
        }
    }

    /**
     * при размонтировании очищаем активную ячейку
     */
    useEffect(() => {
        return () => {
            dispatch(setActiveCellTable(undefined))
        }
    }, [dispatch])

    /**
     * вывод компонента
     */
    return (
        <Layout onEndReached={loadFilter}>
            <div className={styles.root}>
                <div className={styles.left}>
                    <DayOffContext.Provider value={daysOff}>
                        <EditDayContext.Provider value={[editDay, setEditDay]}>
                            <UpdateDataContext.Provider value={updateData}>
                                <div ref={headerRef} className={styles.header}>
                                    <Header />
                                </div>
                                <div
                                    ref={userWrapperRef}
                                    onScroll={handleScroll}
                                    onKeyDown={onPressKeyboard}
                                    style={{
                                        maxHeight: tableHeight,
                                        overflowY: 'auto',
                                        minHeight: 500,
                                    }}
                                    className={styles.table}
                                >
                                    <Table data={tabels} />
                                </div>
                            </UpdateDataContext.Provider>
                        </EditDayContext.Provider>
                    </DayOffContext.Provider>
                </div>
                <div className={styles.right}>
                    <ClearAllDayContext.Provider value={onClearDay}>
                        <ListContext.Provider value={[tabels, setTabels]}>
                            <EditDayContext.Provider
                                value={[editDay, setEditDay]}
                            >
                                <ChangeActiveUser.Provider
                                    value={setNextUserActive}
                                >
                                    <ArrowEnableContext.Provider
                                        value={[
                                            arrowDirection,
                                            setArrowDirection,
                                        ]}
                                    >
                                        <UpdateDataContext.Provider
                                            value={updateData}
                                        >
                                            <RightPanel />
                                        </UpdateDataContext.Provider>
                                    </ArrowEnableContext.Provider>
                                </ChangeActiveUser.Provider>
                            </EditDayContext.Provider>
                        </ListContext.Provider>
                    </ClearAllDayContext.Provider>
                </div>
            </div>
        </Layout>
    )
}

export default TabelsPage
