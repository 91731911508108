import classNames from 'classnames';
import produce from 'immer';
import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { LockTabelTypes } from '../../../../constants/constants';
import { useAppSelector } from '../../../../hooks/store';
import { selectColors } from '../../../../store/slice/storage';
import { TabelTypeType } from '../../../../types/types';
import { Input } from '../../../simples/Input';
import { Selector } from '../../../simples/Selector';
import { OptionType } from '../../../simples/Selector/Selector';
import { ActiveTypeContext, EditContext, FieldType, ListContext } from '../TabelsSettings';
import styles from './PartsSettings.module.scss'
import { VACATION_VAL } from '../../../../constants/tabel';

type Props = {
    data: TabelTypeType & { isDraft?: boolean }
}

const separateOptions: OptionType[] = [
    {
        value: '1',
        label: 'Да'
    },
    {
        value: '0',
        label: 'Нет'
    }
]

const markOptions: OptionType[] = [
    {
        value: VACATION_VAL,
        label: VACATION_VAL
    },
    {
        value: '_',
        label: '-'
    }
]





const TableItem: FC<Props> = ({ data }): JSX.Element => {
    const isEdit = useContext(EditContext)
    const colors = useAppSelector(selectColors)
    const [list, setList] = useContext<[Array<TabelTypeType>, React.Dispatch<React.SetStateAction<TabelTypeType[]>>]>(ListContext)
    const [activeType, setActiveType] = useContext(ActiveTypeContext)

    /**
     * name ref
     */
    const nameRef = useRef<HTMLInputElement>(null)
    /**
     * coefficient ref
     */
    const coeffRef = useRef<HTMLInputElement>(null)
    // const nameOptions = useContext(OptionsNameContext)

    /**
     * опции для селектора выбора цвета
     */
    const colorsOptions = useMemo((): OptionType[] => {
        const options: OptionType[] = colors?.map((color) => ({ label: color.color, value: `${color.id}` })) || []
        return options
    }, [colors])



    /**
     * 
     * определяем можно ли редактирова
     */
    const isEditable = useMemo(() => {
        return isEdit && activeType?.id === data.id
    }, [activeType?.id, data.id, isEdit])

    /**
     * 
     * устанавливаем фокус 
     */

    useEffect(() => {
        if (
            isEdit && activeType?.id === data.id
        ) {
            nameRef.current?.focus()
        }

    }, [activeType?.id, data.id, isEdit])

    /**
     * функция изменения значения
     * @param e значение поля
     * @param field - меняемое поле
     */
    const onChangeValue = (e: string, field: FieldType) => {
        let val: any = e

        if (field === FieldType.SINGLE) {
            val = +e ? 1 : 0
        }
        setList(produce(
            draft => {
                const find: any = draft.find(i => i.id === data.id) as TabelTypeType
                find[field] = val
            }
        ))


    }

    /**
     * определяем можно-ли редактировать Тип табеля
     */
    const isLock: boolean = useMemo((): boolean => {
        let lockType = Object.values(LockTabelTypes).includes(data.name as LockTabelTypes) || !isEdit
        return lockType
    }, [data.name, isEdit])

    /**
     * выделение типа
     */
    const onHandleClickType = useCallback((e: SyntheticEvent<HTMLTableRowElement>) => {
        if (data.id !== activeType?.id) {
            setActiveType(data)
            // необходимо если сменяем фокус без сохранения
            setList(produce(
                draft => {
                    const find = draft.find(i => i.id === activeType?.id) as TabelTypeType as any
                    const aT = activeType as TabelTypeType as any
                    if (find) {
                        for (let key in find) {
                            find[key] = aT[key]
                        }
                    }
                }
            ))
        }



    }, [activeType, data, setActiveType, setList])

    /**
     * вывод компонента
     */
    return (
        <tr
            onClick={onHandleClickType}
            className={classNames(styles.typeRow, {
                [styles.activeType]: activeType?.id === data.id
            })}
        >
            <td className={classNames(styles.bodyCell, styles.bodyCell_)} >{data.isDraft ? '' : data.id}</td>
            <td className={classNames(styles.bodyCell, styles.bodyCell_)} >
                {isEditable ? <input
                    ref={nameRef}
                    className={styles.nameInput}
                    onChange={(e) => onChangeValue(e.target.value, FieldType.NAME)}
                    value={data.name}
                /> : <span>{data.name}</span>}
            </td>
            <td className={classNames(styles.bodyCell, styles.bodyCell_)} >
                {isEditable ? <input
                    ref={coeffRef}
                    className={styles.coeffInput}
                    disabled={isLock}
                    onChange={(e) => onChangeValue(e.target.value, FieldType.COEFF)}
                    value={data.coefficient}
                /> : <span>{data.coefficient}</span>}
            </td>
            <td className={classNames(styles.bodyCell, styles.bodyCell_)} >
                <Selector
                    disabled={!isEditable}
                    classes={{
                        root: styles.smallSelector,
                        label: styles.smallSelectorLabel
                    }}
                    value={`${data.single}`}
                    options={separateOptions}
                    onChange={(e) => onChangeValue(e, FieldType.SINGLE)}
                />
            </td>
            <td className={classNames(styles.bodyCell, styles.bodyCell_)} >
                <Selector
                    disabled={!isEditable}
                    classes={{
                        root: styles.smallSelector,
                        label: styles.smallSelectorLabel
                    }}
                    value={data.label}
                    options={markOptions}
                    onChange={(e) => onChangeValue(e, FieldType.LABEL)}
                />
            </td>
            <td className={classNames(styles.bodyCell, styles.bodyCell_)} >
                <Selector
                    disabled={!isEditable}
                    classes={{
                        root: styles.smallSelector
                    }}
                    isColor
                    value={`${data.colorId}`}
                    options={colorsOptions}
                    onChange={(e) => onChangeValue(e, FieldType.COLOR)}
                />
            </td>
            <td className={classNames(styles.bodyCell, styles.bodyCell_7)} >
                {isEditable ? <Input
                    classes={{
                        input: styles.descriptionInput
                    }}
                    disabled={!isEditable}
                    onChange={(e) => onChangeValue(e, FieldType.DESCRIPTION)}
                    value={data.description || ''}
                /> : <span >{data.description || ''}</span>}
            </td>
        </tr>
    )
}

export default TableItem;
