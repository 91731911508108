import React, { FC, useCallback } from 'react'
import { ButtonWithIcon } from '../../../../../../simples/ButtonWithIcon'
import styles from './BottomPanel.module.scss'
import { ReactComponent as PrintIcon } from '../../../../../../../assets/icons/print.svg'
import { ReactComponent as XlsIcon } from '../../../../../../../assets/icons/xls.svg'
import { ProfileService } from '../../../../../../../services/ProfileService'
import { useAppSelector } from '../../../../../../../hooks/store'
import FileSaver from 'file-saver'
import { _API_PREFIX } from '../../../../../../../constants/constants'
import printJS from 'print-js'
import ReactDOM from 'react-dom'
import { stringDate } from '../../../../../../../constants/helper'


const BottomPanel: FC = (): JSX.Element => {
    const idArray = useAppSelector((state) => state.staff.checkedUsers)

    const downloadCheckedUserProfile = useCallback(async () => {
        const response = await ProfileService.exportProfile(idArray)
        FileSaver.saveAs(_API_PREFIX + response.fileName, 'docs')
    }, [idArray])


    const printUsers = useCallback(async () => {

        const result = await ProfileService.printUsers(idArray)


        printJS({ printable: _API_PREFIX + result, type: 'pdf', showModal: true, modalMessage: 'Подготавливаем документ к печати...' })

    }, [idArray])
    // Песчать
    return (
        <div className={styles.buttons}>
            <div className={styles.btnLeft}>
                <ButtonWithIcon
                    disabled
                    onClick={printUsers}
                    label={'Печать'}
                    icon={<PrintIcon />}
                />
            </div>
            <ButtonWithIcon
                disabled
                onClick={downloadCheckedUserProfile}
                label={'Экспорт в xls'}
                icon={<XlsIcon />}
            />
        </div>
    )
}

export default React.memo(BottomPanel)
