import React, { createContext } from 'react'
import { FilterTabel } from '../../../store/slice/filters'
import { IUpdateData, TabelTableType } from '../../../types/types'
import { ACellType } from '../../../store/slice/tabel'

export enum KeyboardKey {
    ENTER = 'Enter',
    RIGHT = 'ArrowRight',
    LEFT = 'ArrowLeft',
    UP = 'ArrowUp',
    DOWN = 'ArrowDown'
}

export enum KeyDirection {
    DOWN = 'down',
    RIGHT = 'right'
}

export const ListContext = React.createContext<[Array<TabelTableType>, React.Dispatch<React.SetStateAction<TabelTableType[]>>]>([[], () => { }])
export const EditDayContext = React.createContext<[string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]>([undefined, () => { }])
export const FilterContext = createContext<[FilterTabel, React.Dispatch<React.SetStateAction<FilterTabel>>]>([{}, () => { }])
export const DayOffContext = createContext<Array<string>>([])
export const ChangeActiveUser = createContext<() => void>(() => { })

export const UpdateDataContext = createContext<(value?: string, data?: IUpdateData) => void>(() => { })
export const ArrowEnableContext = createContext<[KeyDirection, React.Dispatch<React.SetStateAction<KeyDirection>>]>([KeyDirection.RIGHT, () => { }])
export const ClearAllDayContext = createContext<(aCell: ACellType) => void>(() => {})  