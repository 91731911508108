import React, { FC } from 'react'
import { RouteProps } from 'react-router-dom'
import styles from './BlockWrapper.module.scss'


type Props = RouteProps 


const BlockWrapper:FC<Props> = ({children}) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}

export default React.memo(BlockWrapper);