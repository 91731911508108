import classNames from 'classnames';
import * as React from 'react';
import { Button, ButtonColors, ButtonVariants } from '../../../../simples/Button';
import { Dropdown } from '../../../../simples/Dropdown';
import { ModalClose } from '../../../../simples/ModalClose';
import styles from './RightPanel.module.scss'
import { ReactComponent as MarkIcon } from '../../../../../assets/icons/mark.svg'
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/print.svg'
import { ReactComponent as ExportIcon } from '../../../../../assets/icons/xls.svg'
import { ChangeMonthComponent } from '../../../../complexes/ChangeMonthComponent';
import { ButtonWithIcon } from '../../../../simples/ButtonWithIcon';
import { useAppSelector } from '../../../../../hooks/store';
import { OptionType } from '../../../../simples/Dropdown/Dropdown';
import { tabelFilter } from '../../../../../store/slice/filters';
import { addZero } from '../../../../../constants/helper';
// @ts-ignore
import $message from 'message-like-antd'
import { useCallback, useState } from 'react';
import { ResetButton } from '../../../../simples/ResetButton';

enum FilterKeyName {
    DEPARTMENT = 'branchId',
    TABEL_GROUP = 'tabelGroupId'
}

type Props = {
    onClose: () => void
    onSubmit: (data: {
        tabelTypeId?: string
        tabelGroupId?: string
        branchId?: string
        date: {
            month: string,
            year: number
        }
        year?: number

    }) => void
    type: 'print' | 'export'

}

enum WhatPrint {
    MONTH_TABEL = 'month_tabel',
    SCORE = 'score',
    YEAR = 'year',
    BRANCH = 'branch'
}

const PrintModal: React.FC<Props> = ({ onClose, onSubmit, type }): JSX.Element => {

    const filter = useAppSelector(tabelFilter)

    const [currentFilter, setCurrentFilter] = useState(filter)
    const tabeltypes = useAppSelector(state => state.storage.tabelTypes)
    const tabelGroups = useAppSelector(state => state.storage.tabelGroups)
    const filials = useAppSelector((state) => state.storage.filials)

    const [whatPrint, setWhatPrint] = React.useState<WhatPrint>(WhatPrint.MONTH_TABEL)
    const [tabelType, setTabelType] = React.useState<string>()
    const [tabelGroup, setTabelGroup] = React.useState<string>(filter.tabelGroupId || '')

    const [date, setDate] = React.useState<{
        month: string,
        year: number
    }>({
        month: filter.month || addZero(new Date().getMonth() + 1),
        year: filter.year || new Date().getFullYear()
    })



    const tabelTypeOptions = React.useMemo((): OptionType[] => {
        let options: OptionType[] = tabeltypes.map((o) => ({
            label: o.name,
            value: `${o.id}`
        }))

        return options
    }, [tabeltypes])

    const tabelGroupsOptions = React.useMemo((): OptionType[] => {
        let options: OptionType[] = tabelGroups.map((o) => ({
            label: o.name,
            value: `${o.id}`
        }))

        return options
    }, [tabelGroups])

    /**
     * нажатие на печать
     */
    const onHandlePrint = React.useCallback(() => {
        if (whatPrint === WhatPrint.MONTH_TABEL) {
            onSubmit({
                tabelTypeId: tabelType,
                branchId: currentFilter.branchId,
                tabelGroupId: currentFilter.tabelGroupId,
                date
            })
        } else if (whatPrint === WhatPrint.SCORE) {
            onSubmit({
                tabelGroupId: tabelGroup,
                date
            })
        } else if (whatPrint === WhatPrint.YEAR) {
            onSubmit({
                date,
                year: date.year
            })
        }
    }, [currentFilter.branchId, currentFilter.tabelGroupId, date, onSubmit, tabelGroup, tabelType, whatPrint])

    const optionsTabelGroup = React.useMemo((): OptionType[] => {
        const opt: {
            [key: number]: OptionType
        } = {}
        tabelGroups.map((item) => {
            if (item.branchId) {

                const os: Array<OptionType> = opt[item.branchId] && opt[item.branchId].options ? opt[item.branchId].options as Array<OptionType> : [] as Array<OptionType>

                opt[item.branchId] = {
                    label: item.branch?.name || '',
                    value: `${item.branchId}`,
                    keyName: FilterKeyName.DEPARTMENT,
                    options: [...os, {
                        label: item.name || '',
                        value: `${item.id}`,
                        keyName: FilterKeyName.TABEL_GROUP
                    }]
                }
            }

        })


        return Object.values(opt).map(i => i).sort((a, b) => {
            if (a.label > b.label) {
                return -1;
            }
            if (a.label < b.label) {
                return 1;
            }
            return 0;
        });

    }, [tabelGroups])

    /**
     * формирование лэйбла для дропдауна Филиала
     */
    const dropdownFilterLabel = React.useMemo(() => {
        let label: string = ''
        if (currentFilter && (currentFilter.branchId || currentFilter.tabelGroupId)) {
            const findDepart = filials.find((i) => {
                if (currentFilter.branchId) {
                    return i.id === +currentFilter.branchId
                }
            })?.name || ''

            const findTabelGroup = tabelGroups.find((i) => {
                if (currentFilter.tabelGroupId) {
                    return i.id === +currentFilter.tabelGroupId
                }
            })?.name || ''

            label = `${findDepart} ${findTabelGroup ? '-> ' + findTabelGroup : ''}`
        }

        return label

    }, [currentFilter, filials, tabelGroups])

    /**
     * изменение филиала
     */
    const onChangeFilterDepartment = useCallback(async (value: {
        value: string,
        keyName?: string
    }) => {
        if (value.keyName === FilterKeyName.TABEL_GROUP) {
            const branchId = `${tabelGroups.find(i => i.id === +value.value)?.branchId}` || ''
            setCurrentFilter({ ...currentFilter, branchId, tabelGroupId: value.value })
        }
        if (value.keyName === FilterKeyName.DEPARTMENT) {
            setCurrentFilter({ ...currentFilter, tabelGroupId: '', branchId: value.value })
        }


    }, [currentFilter, tabelGroups])

    return (
        <ModalClose title={type === 'print' ? 'Печать' : 'Экспорт в xls'} onClose={onClose}>
            <div className={styles.modal}>
                <div className={styles.modalItemFilter}>
                    <span>Печать</span>
                    <div className={styles.btnBlockFilter}>
                        <Button
                            onClick={() => setWhatPrint(WhatPrint.MONTH_TABEL)}
                            className={classNames(styles.chooseBtn, {
                                [styles.activeBtn]: whatPrint === WhatPrint.MONTH_TABEL
                            })}
                            color={ButtonColors.Main}>Табель за месяц</Button>
                        <Button
                            onClick={() => setWhatPrint(WhatPrint.SCORE)}
                            className={classNames(styles.chooseBtn, {
                                [styles.activeBtn]: whatPrint === WhatPrint.SCORE
                            })}
                            color={ButtonColors.Main}>Итоги группы</Button>
                        {type === 'export' && <Button
                            onClick={() => setWhatPrint(WhatPrint.YEAR)}
                            className={classNames(styles.chooseBtn, {
                                [styles.activeBtn]: whatPrint === WhatPrint.YEAR
                            })}
                            color={ButtonColors.Main}>Итоги года</Button>}
                    </div>
                </div>
                {whatPrint === WhatPrint.MONTH_TABEL && <div className={styles.modalItemFilter}>
                    <span>Тип табеля</span>
                    <div className={styles.itemRight}>
                        <div className={styles.itemRightInner}>
                            <Dropdown
                                icon={<MarkIcon />}
                                value={[tabelType || '']}
                                options={tabelTypeOptions}
                                onChange={(e) => setTabelType(e.value)}
                            />
                        </div>
                        {!!tabelType && <ResetButton onClick={() => setTabelType(undefined)} />}
                    </div>
                </div>}
                {whatPrint === WhatPrint.SCORE && <div className={styles.modalItemFilter}>
                    <span>Группа</span>
                    <div className={styles.itemRight}>
                        <div className={styles.itemRightInner}>
                            <Dropdown
                                icon={<MarkIcon />}
                                value={[tabelGroup || '']}
                                options={tabelGroupsOptions}
                                onChange={(e) => setTabelGroup(e.value)}
                            />
                        </div>

                        {!!tabelGroup && <ResetButton onClick={() => setTabelGroup('')} />}
                    </div>
                </div>}
                {whatPrint === WhatPrint.MONTH_TABEL &&
                    <div className={styles.modalItemFilter}>
                        <span>Филиал</span>

                        <div className={styles.itemRight}>
                            <div className={styles.itemRightInner}>
                                <Dropdown
                                    cascade
                                    placeholder={'Табельная группа'}
                                    value={[currentFilter.branchId || '']}
                                    labelInner={dropdownFilterLabel}
                                    options={optionsTabelGroup}
                                    onChange={function (value): void {
                                        onChangeFilterDepartment(value)
                                    }}
                                />
                            </div>
                            {(!!currentFilter.branchId || !!currentFilter.tabelGroupId) &&
                            <ResetButton onClick={() => setCurrentFilter({ ...currentFilter, branchId: undefined, tabelGroupId: undefined })} />}
                        </div>
                    </div>}
                {whatPrint !== WhatPrint.YEAR && <div className={styles.modalItemFilter}>
                    <span>Месяц</span>
                    <div className={styles.itemRight}>
                        <ChangeMonthComponent
                            classes={{
                                root: styles.datePicker
                            }}

                            onChange={setDate}
                            date={`${date.year}-${date.month}-01`}
                        />
                    </div>
                </div>}
                {whatPrint === WhatPrint.YEAR && type === 'export' && <div className={styles.modalItemFilter}>
                    <span>Год</span>
                    <div className={styles.itemRight}>
                        <ChangeMonthComponent
                            onlyYear
                            classes={{
                                root: styles.datePicker
                            }}

                            onChange={setDate}
                            date={`${date.year}-${date.month}-01`}
                        />
                    </div>
                </div>}

                <ButtonWithIcon
                    classes={{
                        root: styles.printBtn
                    }}
                    label={type === 'print' ? 'Распечатать' : 'Экспортировать'}
                    icon={type === 'print' ? <PrintIcon /> : <ExportIcon />}
                    onClick={onHandlePrint} />

            </div>

        </ModalClose>
    )
}

export default PrintModal;
