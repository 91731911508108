import { Endpoints } from '../constants/constants';
import { ColorType, SortType } from '../types/types';
import api from '../utils/api';


export class ColorService {
    static async getColors(data:{
        name?: string
        sort?: SortType
        offset?: number
        limit?: number
    }):Promise<ColorType[]> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            if(aData[key]){
                query.push(`${key}=${aData[key]}`)
            }
            
        }

        const response = await api.get(`${Endpoints.COLORS}?${query.join('&')}`)
        return response.data.data
    }
}
