import { Endpoints } from '../constants/constants';
import { sendFormData } from '../constants/helper';
import { SortType, TabelTypeType } from '../types/types';
import api from '../utils/api';


export type UpdateTabelType = {
    name: string
    coefficient: string
    single: 1 | 0
    label: string | null
    colorId: number
    description?: string
}


export class TabelTypeService {
    static async getTabeltypes(data:{
        name?: string
        colorId?: number
        coeff?: number
        sort?: SortType
        offset?: number
        limit?: number
    }):Promise<TabelTypeType[]> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            if(aData[key]){
                query.push(`${key}=${aData[key]}`)
            }
            
        }

        const response = await api.get(`${Endpoints.TABEL_TYPE}?${query.join('&')}`)
        return response.data.data
    }

    /**
     * метод обновления типа табеля
     * @param id - табеля типа
     * @param data - данные для обновления
     * @returns - тип табеля
     */
    static async updateTabelType(id: number ,data: UpdateTabelType): Promise<TabelTypeType> {
        const response = await api.post(`${Endpoints.TABEL_TYPE_UPDATE}/${id}`, sendFormData(data))
        return response.data.data
    }
    /**
     * метод создания типа табеля
     * @param data - данные для обновления
     * @returns - тип табеля
     */
    static async createTabelType(data: UpdateTabelType): Promise<TabelTypeType> {
        const response = await api.post(Endpoints.TABEL_TYPE_CREATE, sendFormData(data))
        return response.data.data
    }

    /**
     * удаление типа табеля
     */
    static async deleteTabelTape(id: number): Promise<string>{
        const response = await api.delete(`${Endpoints.TABEL_TYPE_DELETE}/${id}`)
        return response.data.data[0]
    }
}
