import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addZero } from '../../constants/helper'
import { TabelGroupType, TabelTableType } from '../../types/types'
import { RootState } from '../index'

/**
 * Начальное состояние редьюсера Auth/
 */

export type ACellType = {
    day: string
    tabelId: number
    color?: string
    value?: 0 | {
        id: number,
        hours: number
    }
    tabelName: string
} | undefined


type StateType = {
    activeCell?: ACellType
    activeUser?: TabelTableType
    date?: string
    tabelTypeIds?: Array<TabelGroupType>
    userTabelNumber?: number
    branchId?: number
    name?: string
    activeUserName?: string

    isFinalize: 0 | 1

    tabelDate?: {
        month: string
        year: number
    }

}

export const initialState: StateType = {
    activeCell: undefined,
    tabelTypeIds: [],
    userTabelNumber: undefined,

    tabelDate: {
        month: (new Date().getMonth() + 1 < 10) ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`,
        year: new Date().getFullYear()
    },

    isFinalize: 0

}



/**
 * Создание слайса App
 */
export const tabelSlice = createSlice({
    name: 'tabel',
    initialState,
    reducers: {
        setActiveCellTable: (state, action) => {
            state.activeCell = action.payload
        },
        changeCell: (state, action: PayloadAction<{
            down?: boolean,
            right?: boolean,
            left?: boolean,
            up?: boolean
            activeUser?: any
        }>) => {


            const find = state.tabelTypeIds?.findIndex(i => state.activeCell && i.id === +state.activeCell?.tabelId)

            if (find !== undefined && state.tabelTypeIds && find < state.tabelTypeIds?.length) {
                if (action.payload.down) {
                    const isNext = find + 1 < state.tabelTypeIds?.length
                    const next = isNext ? state.tabelTypeIds[find + 1].id : state.tabelTypeIds[find].id
                    const name = isNext ? state.tabelTypeIds[find + 1].name : state.tabelTypeIds[find].name
                    const nexValueTabel = state.tabelTypeIds.find((i) => i.id === next)?.tabels
                    const val: any = nexValueTabel ? nexValueTabel[state.activeCell?.day as any] : 0
                    state.activeCell = state.activeCell && { ...state.activeCell, value: val ? { ...val } : 0, tabelId: next, tabelName: name}
                }
                if (action.payload.up) {
                    const isPrev = find > 0
                    const prev = isPrev ? state.tabelTypeIds[find - 1].id : state.tabelTypeIds[find].id
                    const name = isPrev ? state.tabelTypeIds[find - 1].name : state.tabelTypeIds[find].name
                    const nexValueTabel = state.tabelTypeIds.find((i) => i.id === prev)?.tabels
                    const val: any = nexValueTabel ? nexValueTabel[state.activeCell?.day as any] : 0
                    state.activeCell = state.activeCell && { ...state.activeCell, value: val ? { ...val } : 0, tabelId: prev, tabelName: name }
                }

                const tabels = state.tabelTypeIds[find].tabels
                if (state?.tabelDate) {

                    const DAYS_IN_MONTH = [
                        31,
                        state.tabelDate?.year % 4 === 0
                            ? 29
                            : 28
                        ,
                        31,
                        30,
                        31,
                        30,
                        31,
                        31,
                        30,
                        31,
                        30,
                        31,
                    ][+state?.tabelDate?.month - 1]
                    if (action.payload.right) {

                        const nextday = state.activeCell?.day && +state.activeCell?.day < DAYS_IN_MONTH ? `${(+state.activeCell?.day + 1)}` : state.activeCell?.day

                        const currentDay = `${state.tabelDate?.year}-${state.tabelDate?.month}-${(nextday ? addZero(+nextday) : nextday)}T23:23:23`
                        state.date = currentDay
                        const val: any = tabels[nextday as any]
                        state.activeCell = state.activeCell && { ...state.activeCell, value: val ? { ...val } : 0, day: nextday || '' }
                    }
                    if (action.payload.left) {

                        const prevDay = state.activeCell?.day && +state.activeCell?.day > 1 ? `${+state.activeCell?.day - 1}` : state.activeCell?.day

                        const currentDay = `${state.tabelDate?.year}-${state.tabelDate?.month}-${(prevDay ? addZero(+prevDay) : prevDay)}T23:23:23`
                        state.date = currentDay
                        const val: any = tabels[prevDay as any]
                        state.activeCell = state.activeCell && { ...state.activeCell, value: val ? { ...val } : 0, day: prevDay || '' }
                    }



                }
            }

        },
        setTableTypesActive: (state, action) => {
            state.tabelTypeIds = action.payload
        },
        setUserTabelNumber: (state, action) => {
            state.userTabelNumber = action.payload
        },
        setDateTabel: (state, action) => {
            state.date = action.payload
        },
        setActiveUserTabel: (state, action) => {
            state.activeUser = action.payload
            state.userTabelNumber = action.payload.tabelNumber
        },
        clearActiveUserTabel: (state) => {
            state.activeUser = undefined
            state.userTabelNumber = undefined
        },
        setTableDate: (state, action) => {
            state.tabelDate = action.payload
        },
        setBranchId: (state, action) => {
            state.branchId = action.payload
        },
        setNameSearch: (state, action) => {
            state.name = action.payload
        },
        setActiveUserName: (state, action) => {
            state.name = action.payload
        },
        setActiveTabelFinalize: (state, action) => {
            state.isFinalize = action.payload
        }
    },

})




export const {
    setActiveCellTable,
    changeCell,
    setTableTypesActive,
    setUserTabelNumber,
    setDateTabel,
    setActiveUserTabel,
    setTableDate,
    setBranchId,
    setNameSearch,
    setActiveUserName,
    clearActiveUserTabel,
    setActiveTabelFinalize
} = tabelSlice.actions


export const activeCellTable = (state: RootState) => state.tabel.activeCell
export const activeUserTabelNumberTable = (state: RootState) => state.tabel.userTabelNumber
export const activeDate = (state: RootState) => state.tabel.date

// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default tabelSlice.reducer
