import classNames from 'classnames';
import React, { FC } from 'react';
import { useAppSelector } from '../../../../../../../../hooks/store';
import { selectCheckedUsers } from '../../../../../../../../store/slice/staff';
import styles from './PartsInfoUser.module.scss'

const CheckedUsers:FC = ():JSX.Element => {
    const checkedUsers = useAppSelector(selectCheckedUsers)

    const isNull = !(checkedUsers.length > 0)
    return (
        <div className={classNames(styles.numberOfUsers,{
            [styles.checkedUsers]:!isNull
        })}>
            {
                isNull ? 'Выберите сотрудника' : `Выбран(ы) ${checkedUsers.length} сотрудник(а)`
            }
        </div>
    )
}


export default React.memo(CheckedUsers);
