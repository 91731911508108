import classNames from 'classnames';
import React, { FC, useCallback, useContext } from 'react';
import styles from './TabelGroup.module.scss'
import {ReactComponent as Mark } from '../../../../../assets/icons/mark.svg'
import { SortByType, SortType } from '../../../../../types/types';
import { SortByContext, SortNameContext } from './TabelGroup';



type Props = {

}

const Header: FC<Props> = () => {
    //sort
    const [sort, setSort] = useContext(SortNameContext)
    const [sortBy, setSortBy] = useContext(SortByContext)

    // change sort
    const changeSort = useCallback(() => {
        if(sort === SortType.ASC){
            if(sortBy === SortByType.BRANCH_NAME){
                setSort(SortType.DESC)  
            } else {
                setSort(SortType.ASC)  
                setSortBy(SortByType.BRANCH_NAME)
            }   
        } 
        if(sort === SortType.DESC){
            if(sortBy === SortByType.BRANCH_NAME){
                setSort(SortType.ASC)  
            } else {
                setSort(SortType.DESC)  
                setSortBy(SortByType.BRANCH_NAME)
            }   
        } 
        
    },[setSort, setSortBy, sort, sortBy])

    const changeSortTabelName = useCallback(() => {
        if(sort === SortType.ASC){
            if(sortBy === SortByType.NAME){
                setSort(SortType.DESC)  
            } else {
                setSort(SortType.ASC)  
                setSortBy(SortByType.NAME)
            }   
        } 
        if(sort === SortType.DESC){
            if(sortBy === SortByType.NAME){
                setSort(SortType.ASC)  
            } else {
                setSort(SortType.DESC)  
                setSortBy(SortByType.NAME)
            }   
        } 
    }, [setSort, setSortBy, sort, sortBy])

    return (
        <div

            className={classNames(styles.item, styles.header)}
        >
            <div
            onClick={changeSortTabelName}
            className={styles.first}>
                <span>Название табельной группы</span>
                <Mark className={classNames(styles.numberSort, {
                    [styles.desc]: sortBy === SortByType.NAME && sort === SortType.DESC
                })} />
            </div>

            <div onClick={changeSort} 
            className={styles.second}
            >
                <span>Филиал</span>
                <Mark className={classNames(styles.numberSort, {
                    [styles.desc]: sortBy === SortByType.BRANCH_NAME && sort === SortType.DESC
                })} />
            </div>
        </div>
    )
}
export default React.memo(Header)