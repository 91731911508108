import exp from 'constants';
import React, { FC } from 'react';
import { Button, ButtonColors } from '../../simples/Button';
import { ModalClose } from '../../simples/ModalClose';
import styles from './FinalizationModal.module.scss'


type Props = {
    onClose: () => void
    onSubmit: () => void
}

const FinalizationModal: FC<Props> = ({ onClose, onSubmit }) => {
    return (
        <ModalClose
            onClose={onClose}
            title={'Внимание!'}
        >
            <div className={styles.text}>Вы уверены, что хотите финализировать таблицу? После этого действия изменить данные в этой таблице будет невозможно</div>

            <div className={styles.btnBlock}>
                <Button
                    onClick={onClose}
                    className={styles.button}
                    color={ButtonColors.Light}
                >Отмена</Button>
                <Button
                    className={styles.button}
                    color={ButtonColors.Light}
                    onClick={onSubmit}
                >Подтвердить</Button>
            </div>
        </ModalClose>
    )
}
export default FinalizationModal