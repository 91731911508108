import { ACellType } from "../store/slice/tabel"

export type User = {}

export type FileType = {
    id: number
    userId: number
    extension: string
    size: number
    path: string
    createdAt: string
}

export enum SortType {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum SortByType {
    ID = 'id',
    NAME = 'name',
    DESCRIPTION = 'description',
    CREATE_AT = 'createdAt',
    UPDATE_AT = 'updatedAt',
    LAST_NAME = 'lastName',
    TABEL_NUMBER = 'tabelNumber',
    FIO = 'fio',
    BRANCH_NAME = 'branchName'
}

export enum TableNames {
    branches = 'branches',
    docs = 'docs',
    files = 'files',
    news = 'news',
    positions = 'positions',
    tabels = 'tabels',
    tabelsGroups = 'tabelsGroups',
    tabelsMonthsFinalized = 'tabelsMonthsFinalized',
    tabelsTypes = 'tabelsTypes',
    users = 'users',
    weekendDays = 'weekendDays',
}

/**
 * Departments
 */

export type Department = {
    id: number
    name: string
    description: string
    createdAt?: string
    updatedAt?: string //2021-12-21T12:47:27

    isDraft?: boolean
}

export enum SortByDepart {
    NAME = 'name',
    DESCRIPTION = 'description',
    CREATE_AT = 'createdAt',
    UPDATE_AT = 'updatedAt',
}

/**
 * Tabel Group
 */
export type BranchType = {
    createdAt: string
    description: string
    id: number
    name: string
    updatedAt: string
    usersIds: Array<number>
}
export type TabelGroup = {
    id: number
    branch?: BranchType
    branchId: number | null
    name: string
    description: string | null
    createdAt?: string // 2022-01-10T08:23:23,
    updatedAt?: string // 2022-01-10T08:23:23
    supervisorId?: number
    supervisor?: any | null
    value?: string
    label?: string

    isDraft?: boolean
}
/*
 * Filial
 */

export type PositionType = {
    id: number
    name: string
    description: string | null
    createdAt?: string
    updatedAt?: string
}
export type ProfileType = {
    id: number
    firstName: string
    lastName: string
    secondName: string
    tabelNumber: number
    tabelGroup: TabelGroup
    positionId: number
    tabelGroupId: number
    branchId: number
    email: string
    phone: string
    internalPhone: string
    role: RolesKind
    branch: BranchType
    branches: Array<number> | null
    tabelGroups: Array<number> | null
    dateOfBirth: string
    photo: FileType | null
    position: PositionType
    fired: 0 | 1
    addEmail: string
    SNILS: string
    INN: string
    BANK: string
    PASS: string
    INTPASS: string

    tabelsTypes: Array<number>
    accessRights: Array<number>

    isUser: 0 | 1

    notificationsOptions: Array<number>
    active: 0 | 1

    login: string | null
    password: string
    permanentDelete?: boolean

    boss?: number
}

export type RoleType = {
    name: RolesKind
    description: string
}

/**
 * NEWS
 */
export enum DashboardItemType {
    ADD_NEW_USER = 1,
    BIRTHDAY = 2,
    TABEL_NOUT = 3,
    NEWS = 4,
}
export type NewsType = {
    id: number
    type: DashboardItemType.NEWS
    name?: string
    description?: string | null
    content: string
    createdAt?: string // 2022-01-12T07:00:15,
    updatedAt?: string // 2022-01-12T07:00:15
}

export type AddNewUserType = {
    fullName: string
    positionName: string
    branchNamr: string
    age: number
    type: DashboardItemType.ADD_NEW_USER
}
export type BirthdayUserType = {
    fullName: string
    positionName: string
    branchNamr: string
    age: number
    type: DashboardItemType.BIRTHDAY
}
export type TabelNoutType = {
    message: string
    type: DashboardItemType.TABEL_NOUT
}

/**
 * DOCS
 */

export type DocType = {
    id: number
    name: string
    path: string
    extension: string
    size: number
    userId: number
    docTypeId: number
    createdAt: string
}

/**
 * LOGS
 */

export type LogItemType = {
    id: number
    logId: number
    change: string
    changed: string
    createdAt: string
}

export type LogType = {
    id: number
    action: string
    userId: number
    createdAt: string
    user: {
        id: number
        firstName: string
        lastName: string | null
        secondName: string | null
    }
    items: Array<LogItemType>
}

/**
 * TABEL
 */

export type TabelItem = {
    [key: string]:
    | 0
    | {
        id: number
        hours: number
        iaVacation: boolean
    }
}

export type CheckType = {
    id: string;
    login: string;
    time: string;
    day: string;
}

export type OneTabelType = {
    createdAt: string
    tabelTypeId: number
    hours: number
    userId: number
    authorId: number
    tabelGroupId: number
    inputAt: string
    updatedAt: string
    id: number
    entrance: string | null
    exit: string | null
    author?: ProfileType
    position?: any
    tabelGroup?: TabelGroup
    user?: ProfileType
    checks: CheckType[]
    workHours: string
}

export type TabelGroupType = {
    id: number
    name: string
    color: ColorType
    coefficient: number
    tabels: {[key in string]: TabelItem}
}

export type TabelTableType = {
    id: number
    firstName: string
    lastName: string | null
    secondName: string | null
    tabelNumber: number
    email: string
    tabelsTotal: TabelItem
    tabelsTypes: Array<TabelGroupType>
    userId: number
    branch: string
    branchId: number;
    tabelGroup: string
    position: string
}

/**
 * TABEL-type
 */

export type TabelTypeType = {
    id: number
    name: string
    coefficient: string
    single: 1 | 0
    label: string
    createdAt: string | null
    updatedAt: string | null
    colorId: number
    color: ColorType
    description?: string

    isWriteable: 0 | 1

}

/**
 * COLOR TYPE
 */

export type ColorType = {
    id: number
    name: string
    color: string
    createdAt?: string | null
    updatedAt?: string | null
}

/**
 * POSITIONS
 * * */

// export type PositionsType = {
//     id: number
//     name: string
//     description: string
//     createdAt: string
//     updatedAt: string
// }


/**
 * ErrorTypeResponse
 */
export type ErrorTypeResponse = {
    response: {
        data: {
            errors: Array<string>
        }
    }
}

/**
 * DAYOFF
 */

export type DayOffType = {
    id: number
    date: string
    description: string | null
    createdAt: string
    updatedAt: string
}

/**
 * ROLES
 */

export enum RolesKind {
    SUPERVISOR = 'supervisor',
    CHIEF = 'chief',
    ADMIN = 'admin',
    USER = 'user'
}

/**
 * Права доступа
 */
export type AccessRightsType = {
    id: number
    name: string
    description: string
}

/**
 * Уведомления
 */
export type NotificationType = {
    id: number
    name: string
    descriptions: string
}

/**
 * Entrance type
 */
export enum EntranceType {
    DAY = 1,
    MONTH = 2
}

/**
 * 
 */
export type CheckpointType = {
    entrance?: string
    exit?: string
}


/**
 * access rights
 */

export enum AccessRightType {
    EditFilialsTable = 1, //"Редактировать таблицу филиалов"
    EditTabelsGroupsTable = 2, //"Редактировать таблицу табельных групп"
    TuneTabelsTypesList = 3, // "Настройка списков типов табелей"
    AddNewTypeOfTabels = 4, // "Добавление новых типов табелей"
    ChangeTabelData = 6, //"Изменять данные в табеле"
    EditStaff = 7, // "Редактирование сотрудников"
    EditUserAndRights = 8, //"Редактирование пользователей их их прав"
    ViewPersonalInfo = 9, // "Просмотр персональных данных"
    EditPersonalData = 10, // "Редактирование персональных данных"
    FinalizeTabel = 11, // "Финализация табеля"
    PublishNews = 14, // "Разрешить размещение новостей"
    EditTabelSelfGroup = 15, // "Редактирования табеля своей группы"
}


export type IUpdateData = {
    aCell?:  ACellType,
    aUser?: TabelTableType,
    tabelNumber?: number;
}