import { FC } from 'react'
import { TabelTableType } from '../../../../../../../types/types'
import { UserItem } from './parts/UserItem'


export type CurrentCellType = {
    day: string,
    tabelId: number | string
    color?: string
    value?: 0 | {
        id: number,
        hours: number
    }
}

type Props = {
    data: Array<TabelTableType>
}

const TableBody: FC<Props> = ({ data, 

}) => {


    /**
     * выводим тело таблицы
     */
    return (
        <tbody>
            {
                data.map((user) => <UserItem key={user.id} user={user}/>
                )
            }
           
        </tbody>
    )
}

export default TableBody
