import React, { FC } from 'react';
import styles from './ResetButton.module.scss';

type Props = {
    onClick: () => void
}

const ResetButton:FC<Props> = ({onClick}) => {
    return (
        <div onClick={onClick} className={styles.root}>

        </div>
    )
}

export default React.memo(ResetButton);
