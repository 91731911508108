import { Endpoints } from '../constants/constants';
import { arrayToQuery, sendFormData } from '../constants/helper';
import { DocType, SortType } from '../types/types';
import api from '../utils/api'


export class DocsService {
    static async getDocs(data: {
        userId?: number
        docTypeId?: number
        sort?: SortType,
        offset?: number
        limit?: number
        search?: string
    }): Promise<Array<DocType>> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            if (key) {
                query.push(`${key}=${aData[key]}`)
            }
        }
        const response = await api.get(`${Endpoints.DOCS}?${query.join('&')}`)

        return response.data.data
    }

    static async uploadDoc(data: {
        userId?: number
        docTypeId?: number
        file: File
    }): Promise<DocType> {
        const response = await api.post(Endpoints.DOCS_UPLOAD, sendFormData(data))
        return response.data.data
    }

    static async deleteDoc(id: number): Promise<boolean> {
        const response = await api.delete(`${Endpoints.DOCS_DELETE}/${id}`)
        if (response.data.errors.length === 0) {
            return true
        } else {
            return false
        }
    }
    static async getDocById(id: number): Promise<DocType> {
        const response = await api.get(`${Endpoints.DOCS_BY_ID}/${id}`)
        return await response.data.data
    }
    static async getZip(docsIds: Array<number>): Promise<{ fileName: string }> {
        const response = await api.get(`${Endpoints.DOCS_ZIP}?docsIds=${arrayToQuery(docsIds)}`)

        return response.data.data
    }


}