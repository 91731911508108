import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'

/**
 * Начальное состояние редьюсера Auth/
 */

export const initialState = {
    isLoading: false
}



/**
 * Создание слайса App
 */
export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setIsLoading:(state, action) => {
            state.isLoading = action.payload
        }
    },
  
})




export const {
    setIsLoading
} = appSlice.actions

export const selectIsLoading = (state: RootState) => state.app.isLoading
// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default appSlice.reducer
