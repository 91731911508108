import React, { FC, useRef, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import LeftMenu from './LeftMenu'
import styles from './Layout.module.scss'
import { MainPreloader } from '../../simples/MainPreloader'
import { useAppSelector } from '../../../hooks/store'
import { selectIsLoading } from '../../../store/slice/app'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg'
import classNames from 'classnames'


type Props = RouteProps & {
    rightComponent?: JSX.Element
    isLoading?: boolean
    onEndReached?: () => void
    classes?: {

    }
}

const Layout: FC<Props> = ({ rightComponent, isLoading, children, onEndReached }) => {
    const isLoadingSelect = useAppSelector(selectIsLoading)
    const [openRight, setOpenRight] = useState<boolean>(false)

    const contentRef = useRef<HTMLDivElement>(null)
    const reached = useRef(false)


    /**
     *
     * dynamic pagination
     */
    const handleScroll = () => {
        if (!contentRef.current) {
            return
        }

        const contentHeight = contentRef.current.offsetHeight
        const scrollHeight = contentRef.current.scrollHeight

        const scrollTop = contentRef.current.scrollTop

        if (scrollHeight <= contentHeight) {
            return
        }

        const afterEndReach =
            scrollHeight - (scrollTop + contentHeight) < contentHeight / 2

        if (afterEndReach && !reached.current) {
            reached.current = true
            onEndReached && onEndReached()
        } else if (!afterEndReach && reached.current) {
            reached.current = false
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.leftMenuWrapper}>
                <LeftMenu />
            </div>
            {/* <div className={styles.content}> */}
                <div
                    ref={contentRef}
                    onScroll={handleScroll}
                    className={styles.mainContent}>
                    {children}
                </div>
                {/* {rightComponent && <div className={classNames(styles.rightHolder, {
                    [styles.openHolder]: openRight
                })}>
                    <div
                        onClick={() => setOpenRight(prev => !prev)}
                        className={classNames(styles.rightMarkOpen, {
                            [styles.openRightMark]: openRight
                        })}>
                        <span></span>
                    </div>
                    <div className={classNames(styles.rightComponent, {
                        [styles.openRight]: openRight
                    })}>
                        {rightComponent}

                    </div>
                </div>} */}

            {/* </div> */}
            {
                (isLoading || isLoadingSelect) && <MainPreloader />
            }
        </div>
    )
}

export default React.memo(Layout);