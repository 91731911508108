import { Endpoints } from '../constants/constants'
import { sendFormData } from '../constants/helper'
import { ProfileType } from '../types/types'
import api from '../utils/api'


export class AuthService {
    static async login(data: FormData){

            const response = await api.post(Endpoints.LOGIN, data)
            if (typeof window !== "undefined") {
                localStorage.setItem('access_token', response.data.data.token)
            }
            if(response.data.data.token){
                return true
            }
                return false
            

    }

    static async getUser():Promise<ProfileType> {
        const response = await api.get(Endpoints.CURRENT_USER)
        return response.data.data
    }

    static async loginProblem(data: {
        login: string
        tabelNumber: number
    }): Promise<any>{
        const response = await api.post(Endpoints.LOGIN_TRUBLE, sendFormData(data))
        return response.data.data
    } 
}