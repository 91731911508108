import React, { FC, useCallback, useEffect } from 'react';
import styles from './DropZone.module.scss'
// import Dropzone from 'react-dropzone-uploader'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames';
import { ReactComponent as ImageIcon } from '../../../assets/icons/ImageImportIcon.svg'
// import { ImageType } from '../../../types/types';
import { MAX_UPLOADING_DOCUMENT_SIZE } from '../../../constants/config';



type Props = {
  label?: string
  maxFiles?: number
  onUpload: (files: Array<File>) => void
  disabled?: boolean
  name?: string | undefined
}

const DropZone: FC<Props> = ({ label, maxFiles, name, onUpload, disabled }) => {
  const handleSubmit = (files: any, allFiles: any) => {
    console.log(files.map((f: any) => f.meta))
    // allFiles.forEach(f => f.remove())
  }

  //size validation
  const sizeValidator = (file: File) => {
    if (file.size > MAX_UPLOADING_DOCUMENT_SIZE) {
      return {
        code: "size-too-large",
        message: `Размер файла превышает 2мб`
      };
    }
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    !disabled && onUpload(acceptedFiles);

    // Do something with the files
  }, [onUpload])

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept, fileRejections } = useDropzone({
    onDrop,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    maxFiles,
    //@ts-ignore
    validator: sizeValidator
  })

  const fileRejectionItems = () => {
    fileRejections.map(({ file, errors }) => {
      const messages = errors.map(i => i.message).join('\n')
      alert(messages)
    });
  }

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejectionItems()
    }

  }, [fileRejections])


  return (
    <>
      <div className={styles.root}>

        {label && <div className={styles.label}>{label}</div>}

        <div className={classNames(styles.container, {
          [styles.activeContainer]: isDragActive,
          [styles.reject]: isDragReject,
          [styles.disabled]: disabled
        })} {...getRootProps()}>
          <input  {...getInputProps()} />
          <div className={styles.contentInner}>
            <ImageIcon />
            {name ?? name}
            {
              isDragActive ?
                <div className="">
                  {isDragAccept && !disabled && <p>Опустите файлы сюда</p>}
                  {isDragReject && <p>Файлы не поддерживаются</p>}
                </div>
                :
                <div className="">
                  <p>Перетащите сюда файл или кликните для выбора</p>
                </div>
            }
          </div>
        </div>

      </div>
    </>

  )
}
export default DropZone

