import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ChangeMonthComponent } from '../../../../complexes/ChangeMonthComponent'
import { BlockWrapper } from '../../../../simples/BlockWrapper'
import { Dropdown } from '../../../../simples/Dropdown'
import { TextField } from '../../../../simples/TextField'
import styles from './Header.module.scss'
import { ReactComponent as ClearFilter } from '../../../../../assets/icons/clearFilter.svg'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { OptionType } from '../../../../simples/Dropdown/Dropdown'
import {
    setActiveUserTabel,
    setTableDate,
} from '../../../../../store/slice/tabel'
import { Routes } from '../../../../../constants/config'
import { TabelTableType } from '../../../../../types/types'
import { CheckBox } from '../../../../simples/CheckBox'
import {
    changeTabelDateFilter,
    changeTabelFilter,
    tabelFilter,
} from '../../../../../store/slice/filters'
import { addZero } from '../../../../../constants/helper'
import { SettingsService } from '../../../../../services/SettingsService'

enum FilterKeyName {
    DEPARTMENT = 'branchId',
    TABEL_GROUP = 'tabelGroupId',
}

type Props = {}
const Header: FC<Props> = () => {
    const inputTimer = useRef<NodeJS.Timeout>()
    const filials = useAppSelector((state) => state.storage.filials)
    const filter = useAppSelector(tabelFilter)
    const tabelGroup = useAppSelector((state) => state.storage.tabelGroups)

    const filterDate = useMemo(() => {
        if (filter.year && filter.month) {
            return `${filter.year || new Date().getFullYear}-${
                filter.month || addZero(new Date().getMonth() + 1)
            }-01`
        } else {
            return ''
        }
    }, [filter.month, filter.year])

    const aCell = useAppSelector((state) => state.tabel.activeCell)
    // const [filter, setFilter] = useContext<[FilterTabel, React.Dispatch<React.SetStateAction<FilterTabel>>]>(FilterContext)
    const [search, setSearch] = useState<string>('')
    // const tablesNames = useAppSelector(selectTablesNames)

    const dispatch = useAppDispatch()

    /**
     * формирование label для фильтра
     */
    const dropdownFilterLabel = useMemo(() => {
        let label: string = ''
        if (filter && (filter.branchId || filter.tabelGroupId)) {
            const findDepart =
                filials.find((i) => {
                    if (filter.branchId) {
                        return i.id === +filter.branchId
                    }
                })?.name || ''

            const findTabelGroup =
                tabelGroup.find((i) => {
                    if (filter.tabelGroupId) {
                        return i.id === +filter.tabelGroupId
                    }
                })?.name || ''

            label = `${findDepart} ${
                findTabelGroup ? '-> ' + findTabelGroup : ''
            }`
        }

        return label
    }, [filials, filter, tabelGroup])

    /**
     * формирование опция для выпадающего списка фильтра по группам
     */
    const optionsTabelGroup = useMemo((): OptionType[] => {
        const opt: {
            [key: number]: OptionType
        } = {}
        tabelGroup.map((item) => {
            if (item.branchId) {
                const os: Array<OptionType> =
                    opt[item.branchId] && opt[item.branchId].options
                        ? (opt[item.branchId].options as Array<OptionType>)
                        : ([] as Array<OptionType>)

                opt[item.branchId] = {
                    label: item.branch?.name || '',
                    value: `${item.branchId}`,
                    keyName: FilterKeyName.DEPARTMENT,
                    options: [
                        ...os,
                        {
                            label: item.name || '',
                            value: `${item.id}`,
                            keyName: FilterKeyName.TABEL_GROUP,
                        },
                    ],
                }
            }
        })

        return Object.values(opt)
            .map((i) => i)
            .sort((a, b) => {
                if (a.label > b.label) {
                    return -1
                }
                if (a.label < b.label) {
                    return 1
                }
                return 0
            })
    }, [tabelGroup])

    /**
     * Save filter on back
     */
    const onSaveFilter = useCallback(async (page: Routes, options: string) => {
        try {
            await SettingsService.saveFilter({
                page,
                options,
            })
        } catch (error) {
            console.log('SAVE', error)
        }
    }, [])

    /**
     * изменяем фильтр по фио
     * @param s строка поиска по фамилии
     */
    const onSetSearch = async (s: string) => {
        setSearch(s)
        clearTimeout(inputTimer.current)
        inputTimer.current = setTimeout(() => {
            dispatch(changeTabelFilter({ ...filter, lastName: s }))
            onSaveFilter(
                Routes.TABEL,
                JSON.stringify({ ...filter, lastName: s })
            )
        }, 800)
    }

    /**
     * Сброс значений фильтра
     */
    const onResetFilter = useCallback(() => {
        dispatch(
            changeTabelFilter({
                ...filter,
                lastName: '',
                tabelNumber: '',
                hideUnimploy: false,
                tabelGroupId: '',
                branchId: '',
                month: addZero(new Date().getMonth() + 1),
                year: new Date().getFullYear(),
            })
        )

        onSaveFilter(
            Routes.TABEL,
            JSON.stringify({
                ...filter,
                lastName: '',
                tabelNumber: '',
                hideUnimploy: false,
                tabelGroupId: '',
                branchId: '',
                month: '',
            })
        )
        setSearch('')
    }, [dispatch, filter, onSaveFilter])

    /**
     * фильтр по филиалу и группе
     */
    const onChangeFilterDepartment = useCallback(
        async (value: { value: string; keyName?: string }) => {
            if (value.keyName === FilterKeyName.TABEL_GROUP) {
                const branchId =
                    `${
                        tabelGroup.find((i) => i.id === +value.value)?.branchId
                    }` || ''
                dispatch(
                    changeTabelFilter({
                        ...filter,
                        branchId,
                        tabelGroupId: value.value,
                    })
                )
                await onSaveFilter(
                    Routes.TABEL,
                    JSON.stringify({
                        ...filter,
                        branchId,
                        tabelGroupId: value.value,
                    })
                )
            }
            if (value.keyName === FilterKeyName.DEPARTMENT) {
                dispatch(
                    changeTabelFilter({
                        ...filter,
                        tabelGroupId: '',
                        branchId: value.value,
                    })
                )
                await onSaveFilter(
                    Routes.TABEL,
                    JSON.stringify({
                        ...filter,
                        tabelGroupId: '',
                        branchId: value.value,
                    })
                )
            }
        },
        [dispatch, filter, onSaveFilter, tabelGroup]
    )

    /**
     * скрытие уволенных
     */
    const onHideUnimploy = useCallback(async () => {
        dispatch(
            changeTabelFilter({ ...filter, hideUnimploy: !filter.hideUnimploy })
        )
        await onSaveFilter(
            Routes.TABEL,
            JSON.stringify({ ...filter, hideUnimploy: !filter.hideUnimploy })
        )
    }, [dispatch, filter, onSaveFilter])

    /**
     * изменение даты табеля
     */
    const onChangeDate = async (d: { year: number; month: string }) => {
        dispatch(changeTabelDateFilter(d))
        dispatch(setTableDate(d))
        await onSaveFilter(Routes.TABEL, JSON.stringify({ ...filter, ...d }))
        dispatch(setActiveUserTabel({} as TabelTableType))
    }

    useEffect(() => {
        setSearch(filter.lastName || '')
    }, [])

    return (
        <>
            <BlockWrapper>
                <div className={styles.root}>
                    <div className={styles.left}>
                        <div className={styles.holder}>
                            <ChangeMonthComponent
                                date={filterDate}
                                onChange={onChangeDate}
                            />
                        </div>
                        <div className={styles.holder}>
                            <Dropdown
                                cascade
                                placeholder={'Табельная группа'}
                                value={[filter.branchId || '']}
                                labelInner={dropdownFilterLabel}
                                options={optionsTabelGroup}
                                onChange={onChangeFilterDepartment}
                            />
                        </div>
                        <div className={styles.holder}>
                            <TextField
                                classes={{
                                    root: styles.search,
                                }}
                                placeholder={'Фильтр по фамилии или №'}
                                onChange={onSetSearch}
                                value={search || ''}
                            />
                        </div>
                        <div className={styles.holder}>
                            <CheckBox
                                label={'Показать уволенных'}
                                onChange={onHideUnimploy}
                                checked={!filter.hideUnimploy}
                            />
                        </div>
                        <div className={styles.holder}>
                            <div className={styles.btnFilterBlock}>
                                <div
                                    onClick={onResetFilter}
                                    className={styles.filterBtn}
                                >
                                    <ClearFilter />{' '}
                                    <span className={styles.filterBtnText}>
                                        Сбросить фильтры
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BlockWrapper>
        </>
    )
}

export default React.memo(Header)
