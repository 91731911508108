import React, { FC } from 'react'
import { RouteProps } from 'react-router-dom'
import styles from './Title.module.scss'


type Props = RouteProps & {
    size?: number // 16px
    weigth?: string
}

const Title:FC<Props> = ({size,weigth, children}) => {
    return (
        <div 
            style={{
                fontSize: size,
                fontWeight: weigth
            }}
        className={styles.title}>
            {children}
        </div>
    )
}

export default React.memo(Title);