import React, { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ProfileService } from '../../../services/ProfileService'
import { ProfileType, RolesKind } from '../../../types/types'
import { Layout } from '../../complexes/Layout'
import { BlockWrapper } from '../../simples/BlockWrapper'
import { Title } from '../../simples/Title'
import { Docs } from './parts/Docs'
import { UploadPhoto } from './parts/UploadPhoto'
import { UserInfo } from './parts/UserInfo'
import styles from './PersonalCard.module.scss'
// @ts-ignore
import $message from 'message-like-antd'
import { useAppSelector } from '../../../hooks/store'
import { selectUser } from '../../../store/slice/auth'
import { AuthService } from '../../../services/AuthService'


export const EditUserContext = createContext<[ProfileType | undefined, React.Dispatch<React.SetStateAction<ProfileType | undefined>>]>([undefined, () => { }])


const PersonalCard: FC = () => {
    const activeUser = useAppSelector(selectUser)

    const userId = Number(useLocation().search.split('=')[1])
    const [currentUser, setCurrentUser] = useState<ProfileType>()


    /**
     * получение пользователя по ид
     */
    const getuserById = useCallback(async (id: number) => {
        try {
            let response
            if (activeUser && activeUser.id === userId) {
                response = await AuthService.getUser()
            } else {
                response = await ProfileService.getProfileId(id)
            }

            setCurrentUser(response)
        } catch (error: any) {
            console.log(error);

            if (error?.response?.data?.status === 403) {
                $message.error(error.response.data.message, [750])
            } else {
                const errors = error.response.data.errors

                for (let key of errors) {
                    $message.error(key, [750])
                }
            }
        }
    }, [activeUser, userId])

    useEffect(() => {
        getuserById(userId).then()
    }, [userId])

    const user = useAppSelector(selectUser)



    return (
        <Layout>
            <EditUserContext.Provider value={[currentUser, setCurrentUser]}>
                <div className={styles.root}>
                    <div className={styles.top}>
                        <BlockWrapper><Title size={24} weigth={'normal'}>Личная карточка сотрудника</Title></BlockWrapper>
                    </div>
                    {currentUser && <div className={styles.board}>
                        <div className={styles.photo}>
                            <UploadPhoto user={currentUser} />
                        </div>
                        <div className={styles.profile}>
                            <UserInfo user={currentUser} />
                        </div>
                        {
                            (!!user?.accessRights.length || user?.role === RolesKind.ADMIN)
                            &&
                            <div className={styles.docs}>
                                <Docs userId={userId} />
                            </div>
                        }
                    </div>}
                </div>
            </EditUserContext.Provider>
        </Layout >
    )
}

export default PersonalCard;