import { Endpoints } from '../constants/constants'
import { ProfileType } from '../types/types'
import api from '../utils/api'

type FinalType = {
    msg: string
    date?: string
    user?: ProfileType
}
export class User {
    static async UserFinal(date: string): Promise<FinalType> {
        const response = await api.get(`${Endpoints.USER_FINALE}?date=${date}`)

        return response.data.data
    }
}