import React, { FC } from 'react';
import { BranchType, ProfileType } from '../../../../../types/types';
import { TableBody } from './parts/TableBody';
import { TableHeader } from './parts/TableHeader';
import styles from './Table.module.scss'

type Props = {
    data: ProfileType[]
}
const Table:FC<Props> = ({data}):JSX.Element => {
    return (
        <table className={styles.tableStaff}>
            <TableHeader />
            <TableBody data={data} />
        </table>
    )
}

export default React.memo(Table);
