import { Endpoints } from '../constants/constants'
import { AccessRightsType } from '../types/types'
import api from '../utils/api'


export class RightsService {
    /**
     * метод получение прав пользователя
     * @returns список прав пользователя
     */
    static async fetchRights(): Promise<AccessRightsType[]>{
        const response = await api.get(Endpoints.RIGHTS_LIST)
        return response.data.data
    }
}