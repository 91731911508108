import React, { FC, useCallback, useRef, useState } from 'react'
import { _API_PREFIX } from '../../../../../constants/constants'
import { ProfileService } from '../../../../../services/ProfileService'
import { ProfileType } from '../../../../../types/types'
import { Button, ButtonColors } from '../../../../simples/Button'
import styles from './UploadPhoto.module.scss'
type Props = {
    user: ProfileType
}
const UploadPhoto: FC<Props> = ({ user }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const onUpload = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.click()
        }
    }

    const [photoPath, setPhotoPath] = useState<string>(user.photo?.path || '')

    const onUploadPhoto = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event && event.target && event.target.files) {
            const file = event.target.files[0]
            const fd = new FormData()

            fd.append('file', file)

            const response = await ProfileService.uploadPhoto(user.id, fd)
            setPhotoPath(response.photo?.path || '')

        }

    }, [user.id])

    return (
        <div className="">
            <div className={styles.photoWrapper}>
                {
                    photoPath && <img
                        className={styles.photo}
                        src={_API_PREFIX + photoPath}
                        alt={'face'}
                    />
                }
                <input
                    accept={'image/png,image/jpeg'}
                    onChange={onUploadPhoto}
                    ref={inputRef}
                    type={'file'}
                    className={styles.photoInput}
                />
            </div>
            <Button
                onClick={onUpload}
                className={styles.button}
                color={ButtonColors.Light}
            >Загрузить изображение</Button>
        </div>
    )
}

export default React.memo(UploadPhoto);