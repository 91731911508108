import api from '../utils/api'
import { Endpoints } from '../constants/constants';
import { AddNewUserType, BirthdayUserType, NewsType, SortByType, SortType, TabelNoutType } from '../types/types';
import { getFormData } from '../constants/helper';


export class NewsService {
    static async getNews(data: {
        name?: string
        description?: string
        content?: string
        sortBy?: SortByType
        sort?: SortType
        offset?: number
        limit?: number
    }): Promise<Array<NewsType>> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            query.push(`${key}=${aData[key]}`)
        }
        const response = await api.get(`${Endpoints.NEWS}?${query.join('&')}`)
        return response.data.data
    }

    static async getNotifications(): Promise<Array<
        BirthdayUserType |
        AddNewUserType |
        TabelNoutType
    >> {
        const response = await api.get(Endpoints.NOTIFICATIONS)
        return response.data.data
    }

    static async createNews(data: {
        name?: string
        description?: string
        content: string
    }): Promise<NewsType> {
        const fd = getFormData(data)

        const response = await api.post(Endpoints.NEWS_CREATE, fd)
        return response.data.data

    }
    static async updateNews(id: number, data: {
        name?: string
        description?: string
        content: string
    }): Promise<NewsType> {
        const fd = getFormData(data)

        const response = await api.post(`${Endpoints.NEWS_UPDATE}/${id}`, fd)
        return response.data.data

    }
}