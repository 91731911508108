import printJS from 'print-js';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { _API_PREFIX } from '../../../../../constants/constants';
import { DocsService } from '../../../../../services/DocsService';
import { AccessRightType, DocType, RolesKind } from '../../../../../types/types';
import { BlockWrapper } from '../../../../simples/BlockWrapper';
import { Button, ButtonColors } from '../../../../simples/Button';
import styles from './Docs.module.scss'
import Header from './parts/Header';
import Table from './parts/Table/Table';
import FileSaver from 'file-saver';
import { useAppSelector } from '../../../../../hooks/store';
import { selectUser } from '../../../../../store/slice/auth';

export const DocsContext = React.createContext<DocType[]>([]);
type Props = {
    userId: number
}

const Docs: FC<Props> = ({ userId }) => {
    const [list, setList] = useState<Array<DocType>>([])

    const currentList = useRef<Array<DocType>>(list)

    const [checked, setChecked] = useState<Array<number>>([])

    const inputRef = useRef<HTMLInputElement>(null)

    const [showModal, setShowModal] = useState<boolean>(false)
    const [filter, setFilter] = useState<string>('')

    const has = useRef(true)
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)

    const search = useRef('')

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await DocsService.getDocs({
            search: search.current,
            offset: offset.current,
            userId,
            limit: 30
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setList([...currentList.current, ...result])
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setList([])
    }

    const handleSearch = async (fil: string) => {
        clear()
        search.current = filter || ''


        await load()
    }

    useEffect(() => {
        offset.current = 0
        has.current = true
        handleSearch(filter)

    }, [filter, userId])



    const onCheckedDocs = useCallback((id: number[]) => {
        if (id.length !== 1) {
            setChecked(id)
        } else {
            if (checked.includes(id[0])) {
                setChecked(prev => prev.filter(i => i !== id[0]))
            } else {
                setChecked(prev => [...prev, id[0]])
            }
        }

    }, [checked])


    const onUploadDoc = useCallback(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.click()
        }
    }, [])

    const onUploadPhoto = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event && event.target && event.target.files) {
            const file = event.target.files[0]

            const response = await DocsService.uploadDoc({ userId, file })
            setList(prev => [response, ...prev])
        }

    }, [userId])

    const onDeleteDoc = useCallback(async (id) => {
        const response = await DocsService.deleteDoc(id)
        if (response) {
            setList(prev => prev.filter(i => i.id !== id))
        }
    }, [])

    const onDowloadDoc = useCallback(async (id) => {
        const response = await DocsService.getDocById(id)

        FileSaver.saveAs(
            _API_PREFIX + response.path,
            response.name
        );
    }, [])

    const onPrintDoc = useCallback(async (id) => {

        const response = await DocsService.getDocById(id)
        if (response.extension === 'pdf') {
            printJS({ printable: _API_PREFIX + response.path, type: 'pdf', showModal: true, modalMessage: 'Подготавливаем документ к печати...' })
            return
        }

        if (response.extension === 'jpg' || response.extension === 'png') {
            printJS({ printable: _API_PREFIX + response.path, type: 'image', showModal: true, modalMessage: 'Подготавливаем документ к печати...' })
            return
        }

        FileSaver.saveAs(
            _API_PREFIX + response.path,
            response.name
        );
    }, [])


    const downloadCheckedDocs = useCallback(async () => {
        const response = await DocsService.getZip(checked)
        FileSaver.saveAs(
            _API_PREFIX + response.fileName,
            'docs'
        );

    }, [checked])

    const selectAuth = useAppSelector(selectUser)

    const seeFile = useMemo(() => {
        if (selectAuth?.role === RolesKind.ADMIN) {
            return true
        } else if (!selectAuth?.accessRights.includes(AccessRightType.EditPersonalData)) {
            return false
        } else if (!selectAuth?.accessRights.length) {
            return false
        }
    }, [selectAuth?.accessRights, selectAuth?.role])

    return (
        <div className={styles.root}>
            <BlockWrapper>
                {seeFile ?
                    <>
                        <Header onSearch={setFilter} />
                        <DocsContext.Provider value={list}>
                            <Table
                                onDownload={onDowloadDoc}
                                onPrint={onPrintDoc}
                                onDelete={onDeleteDoc}
                                values={checked}
                                onCheck={onCheckedDocs}
                            />
                        </DocsContext.Provider>
                    </>
                    :
                    <></>
                }
            </BlockWrapper>
            <div className={styles.btnBlock}>
                <input className={styles.input} ref={inputRef} onChange={onUploadPhoto} type={'file'} />
                {checked.length > 0 && <Button
                    onClick={downloadCheckedDocs}
                    className={styles.button}
                    color={ButtonColors.Light}
                >Скачать</Button>}
                <Button
                    onClick={onUploadDoc}
                    className={styles.button}
                    color={ButtonColors.Light}
                >Добавить файл</Button>
            </div>
        </div>
    )

}

export default React.memo(Docs);