import classNames from 'classnames';
import React, { FC, useCallback, useContext } from 'react';
import { TabelTableType } from '../../../../../../../../../types/types';
import { ReactComponent as MarkIcon } from '../../../../../../../../../assets/icons/openMark.svg'
import styles from './UserItem.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../../../../../hooks/store';
import { activeCellTable, setActiveCellTable, setActiveUserTabel, setDateTabel, setTableTypesActive, setUserTabelNumber } from '../../../../../../../../../store/slice/tabel';
import { TabelTypeItem } from '../TabelTypeItem';
import { tabelFilter } from '../../../../../../../../../store/slice/filters';
import { DayOffContext } from '../../../../../../config';
import { VACATION_VAL } from '../../../../../../../../../constants/tabel';


type Props = {
    user: TabelTableType
}

export const ActiveUserContext = React.createContext<TabelTableType>({} as TabelTableType)

const UserItem: FC<Props> = ({ user }): JSX.Element => {

    const daysOff = useContext(DayOffContext)

    const dispatch = useAppDispatch()
    const aCell = useAppSelector(activeCellTable)
    const aUser = useAppSelector(state => state.tabel.activeUser)
    const filter = useAppSelector(tabelFilter)
    // const [openUser, setOpenUser] = useState<number>()

    /**
 * определяем является ли день выходным
 */
    const isWeekend = useCallback((day: string) => {
        const currentDay = new Date(`${filter?.year}-${filter?.month}-${+day + 1 < 10 ? `0${+day + 1}` : +day + 1}`).getDay()
        const isDayOff = daysOff.includes(`${filter?.year}-${filter?.month}-${+day < 10 ? `0${+day}` : +day}`)
        return currentDay === 1 || currentDay === 0 || isDayOff

    }, [daysOff, filter?.month, filter?.year])

    /**
* открываем список табелей выбранного пользователя
*/
    const onSetOpenUser = useCallback((id: TabelTableType, tabelNumber: number) => {
        if (id.id === aUser?.id) {
            dispatch(setActiveUserTabel(0))
            dispatch(setUserTabelNumber(0))
            dispatch(setActiveCellTable(undefined))
        } else {

            dispatch(setActiveUserTabel(id))
            dispatch(setUserTabelNumber(tabelNumber))
            dispatch(setTableTypesActive(user.tabelsTypes))
            if (user.tabelsTypes.length > 0) {
                dispatch(setActiveCellTable({
                    day: '1',
                    tabelId: user.tabelsTypes[0].id,
                    color: user.tabelsTypes[0].color.color,
                    value: user.tabelsTypes[0].tabels['1']

                }))
            }
            const currentDay = `${filter?.year}-${filter?.month}-01T23:23:23`
            dispatch(setDateTabel(currentDay))
        }

    }, [aUser?.id, dispatch, filter?.month, filter?.year, user.tabelsTypes])

    
    const lastName = user.lastName || ''
    const firstName = user?.firstName ? ` ${user?.firstName?.slice(0, 1)}.` : ''
    const secondName = user?.secondName ? ` ${user?.secondName?.slice(0, 1)}.` : ''
    
    return (
        <>
            <ActiveUserContext.Provider value={user}>
                <tr className={styles.row}>
                    <td className={classNames(styles.cell, styles.cell_sticky_1)}>
                        <div className={styles.number} onClick={() => onSetOpenUser(user, user.tabelNumber)}>
                            <MarkIcon className={classNames(styles.mark, {
                                [styles.activeMark]: aUser?.id === user.id
                            })} />
                            {user.tabelNumber}
                        </div>
                    </td>
                    <td
                        className={classNames(styles.cell, styles.cell_sticky_2)}
                        onClick={() => onSetOpenUser(user, user.tabelNumber)}
                    ><span className={classNames(styles.name, {
                        [styles.activeName]: aUser?.id === user.id
                    })}>{`${lastName}${firstName}${secondName}`}</span></td>
                    {
                        Object.keys(user.tabelsTotal).map((item, index) => <td
                            style={{
                                borderLeftColor: item === aCell?.day ? aCell.color : '',
                                borderRightColor: item === aCell?.day ? aCell.color : ''
                            }}
                            key={index}
                            className={classNames(styles.cell, {
                                [styles.isWeekend]: isWeekend(item),
                                [styles.isCurrentDay]: item === aCell?.day,
                                [styles.valueOt]: user.tabelsTotal[item] < 0
                            })} >{user.tabelsTotal[item] < 0 ? VACATION_VAL : (user.tabelsTotal[item] || '')}</td>)
                    }

                </tr>
                {(!!aUser && (aUser.id === user.id)) && user.tabelsTypes.length > 0 && user.tabelsTypes.map((tabelGroup) => <TabelTypeItem key={tabelGroup.id} tabelGroup={tabelGroup} />)}
            </ActiveUserContext.Provider>
        </>
    )
}

export default UserItem
