import React, { FC } from 'react'
import { LogType } from '../../../../types/types'
import Item from './Item'
import styles from './Parts.module.scss'
type Props = {
    data: Array<LogType>
}
const TableBody:FC<Props> = ({data}) => {
    return (
        <tbody>
            {
                data.map((item) => <Item key={item.id} data={item} />)
            }
        </tbody>
    )
}

export default React.memo(TableBody)