import { Endpoints } from '../constants/constants';
import { DayOffType } from '../types/types';
import api from '../utils/api'



export class DayOffService {
    static async getDayOffList(data:{
        dateFrom: string
        dateTo: string
    }):Promise<Array<DayOffType>>{
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            if(aData[key]){
                query.push(`${key}=${aData[key]}`)
            }
            
        }

        const response = await api.get(`${Endpoints.DAY_OFF_LIST}?${query.join('&')}`)

        return response.data.data
    }
}