import { Endpoints } from '../constants/constants'
import {Routes as RotesName, Routes} from '../constants/config'
import api from '../utils/api'
import { sendFormData } from '../constants/helper'


export class SettingsService {
    /**
     * получение фильтров
     * @returns JSON с настройками фильтра
     */
    static async fetchFilters(): Promise<{
        page: Routes,
        options: string
    }[]>{
        const response = await api.get(Endpoints.FILTER_LOAD)
        return response.data.data
    }

    /**
     * сохранение фильтров
     */
    static async saveFilter(data: {
        page: RotesName,
        options: string
    }){
        const response = await api.post(Endpoints.FILTER_SAVE, sendFormData(data))
        return response.data.data
    }


}