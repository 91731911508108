import React, { FC } from 'react'
import { RouteProps } from 'react-router-dom'
import styles from './RightBlockWrapper.module.scss'


type Props = RouteProps & {
    top: JSX.Element
    bottom: JSX.Element
}

const RightBlockWrapper: FC<Props> = ({ top, bottom, children }) => {
    return (
        <div className={styles.root}>
            <div className={styles.top}>
                {top}
            </div>
            <div className={styles.bottom}>
                {bottom}
            </div>
        </div>
    )
}

export default React.memo(RightBlockWrapper);