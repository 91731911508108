import { useEffect, useState } from 'react'

export default function useDebounce<T>(value: T, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return debouncedValue
}

export function useIsDebounce<T>(value: T, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(false)

    useEffect(() => {
        setDebouncedValue(false)
        const handler = setTimeout(() => {
            setDebouncedValue(true)
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return debouncedValue
}
