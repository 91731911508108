import React, { FC, useCallback, useContext } from 'react';
import { CheckBox } from '../../../../../../simples/CheckBox';
import styles from './TableHeader.module.scss'
import { ReactComponent as Mark } from '../../../../../../../assets/icons/mark.svg'
import classNames from 'classnames';
import { SortByType, SortType } from '../../../../../../../types/types';
import { CheckAllContext, SortUsersContext } from '../../../../StaffPage';

const TableHeader: FC = (): JSX.Element => {
    const [checkAll, setCheckAll] = useContext(CheckAllContext)
    const [sort, setSort] = useContext(SortUsersContext)

    const changeSortFio = useCallback(() => {
        if (sort.sort === SortType.ASC) {
            setSort({ sort: SortType.DESC, sortBy: SortByType.FIO })
        } else {
            setSort({ sort: SortType.ASC, sortBy: SortByType.FIO })
        }

    }, [setSort, sort])

    const changeSortTabelNumber = useCallback(() => {
        if (sort.sort === SortType.ASC) {
            setSort({ sort: SortType.DESC, sortBy: SortByType.TABEL_NUMBER })
        } else {
            setSort({ sort: SortType.ASC, sortBy: SortByType.TABEL_NUMBER })
        }

    }, [setSort, sort])

    return (
        <thead>
            <tr className={styles.headerRow}>
                <th className={classNames(styles.cell, styles.cell_1)}>
                    <div className={styles.flexBlock}>
                        <CheckBox
                            checked={checkAll}
                            onChange={() => setCheckAll(prev => !prev)}
                        />
                        <div
                            onClick={changeSortTabelNumber}
                            className={styles.flexBlock}>
                            <span className={styles.number}>№</span>
                            <Mark className={classNames(styles.filialSort, {
                                [styles.desc]: sort.sortBy === SortByType.TABEL_NUMBER && sort.sort === SortType.DESC
                            })} />
                        </div>
                    </div>
                </th>
                <th className={classNames(styles.cell, styles.cell_2)}>
                    <div
                        onClick={changeSortFio}
                        className={styles.flexBlock}>
                        <span>ФИО</span>
                        <Mark className={classNames(styles.filialSort, {
                            [styles.desc]: sort.sortBy === SortByType.FIO && sort.sort === SortType.DESC
                        })} />
                    </div>
                </th>
                <th className={classNames(styles.cell, styles.cell_3)}><span>Телефон</span></th>
                <th className={classNames(styles.cell, styles.cell_4)}><span>Внутр.</span></th>
                <th className={classNames(styles.cell, styles.cell_5)}><span>E-mail</span></th>
                <th></th>
            </tr>
        </thead>
    )
}

export default TableHeader;
