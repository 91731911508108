import classNames from 'classnames'
import React, { FC, useState } from 'react'
import InnerItem from './InnerItem'
import styles from './Parts.module.scss'
import {ReactComponent as Mark} from '../../../../assets/icons/mark.svg'
import { LogType } from '../../../../types/types'

type Props = {
    data: LogType
}

const Item: FC<Props> = ({data}) => {
    const [showInner, setShowInner] = useState<boolean>(false)
    return (
        <>
            <tr className={classNames(styles.itemRow,{
                [styles.activeItem]:showInner
            })}>
                <td className={classNames(styles.cell, styles.cell_1)}>
                    <div 
                    onClick={() => setShowInner(prev => !prev)}
                    className={styles.cell_mark}
                    >
                    <span>{data.action}</span>
                    <Mark className={classNames({
                        [styles.activeMark]:showInner
                    })} />
                    </div>
                </td>
                <td className={classNames(styles.cell, styles.cell_2)}>{data.user.lastName}</td>
                <td className={classNames(styles.cell, styles.cell_3)}>{data.createdAt}</td>
            </tr>
            {
                showInner &&
                data.items.map((item) => <InnerItem key={item.id} data={item} />)
            }
        </>
    )
}

export default React.memo(Item);