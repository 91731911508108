import React, { FC } from 'react'
import { AddNewUserType } from '../../../../../../../types/types'
import styles from './AddNewUserCard.module.scss'

type Props = {
    data: AddNewUserType
}

const AddNewUserCard:FC<Props> = ({data}) => {
    return (
        <div className="">
            Add new
        </div>
    )
}

export default React.memo(AddNewUserCard);