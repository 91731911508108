import classNames from 'classnames'
import React, { FC, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Routes } from '../../../constants/config'
import styles from './Layout.module.scss'

export type ItemMenuType = {
    label?: string
    icon: JSX.Element
    route: Routes
}

type Props = {
    data: ItemMenuType
}
const ItemMenu: FC<Props> = ({ data }) => {
    const navigate = useNavigate()
    const location = useLocation().pathname;
    const isActive = location === data.route


    const onHandleClick = useCallback(() => {
        if(!isActive){
            navigate(data.route)
        }
    }, [data.route, isActive, navigate])

    

    return (
        <div
            onClick={onHandleClick}
            className={classNames(styles.menuItem, {
                [styles.active]: isActive
            })}
        >
            {data.icon}
        </div>
    )
}

export default React.memo(ItemMenu);