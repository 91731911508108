import React, { FC, useCallback, useMemo } from 'react'
import styles from './ChangeMonthComponent.module.scss'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/Arrows-Vector.svg'

import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import './reactDatePicker.scss'

import "react-datepicker/dist/react-datepicker.css";
import classNames from 'classnames';
registerLocale('ru', ru)


type Props = {
    onChange: (date: {
        month: string,
        year: number
    }) => void
    classes? :{
        root?: string
    }
    date?: string
    onlyYear?: boolean
}

const ChangeMonthComponent: FC<Props> = ({ onChange, classes, date, onlyYear }) => {

    const onChangeDate = useCallback((date: any) => {
        const d = {
        month: (new Date(date).getMonth() + 1 < 10) ? `0${new Date(date).getMonth() + 1}` : `${new Date(date).getMonth() + 1}`,
            year: new Date(date).getFullYear()
        }
        return d
    }, [])

    const dateFormat = useMemo(() => {
        if(onlyYear){
            return 'yyyy'
        } 
        return "MMMM yyyy"
    },[onlyYear])



    const onPrevMonth = () => {
        onChange(onChangeDate((new Date(date ? new Date(date) : new Date()).setMonth(new Date(date ? new Date(date) : new Date()).getMonth() - 1))))
    }
    const onPrevYear = () => {
        onChange(onChangeDate((new Date(date ? new Date(date) : new Date()).setFullYear(new Date(date ? new Date(date) : new Date()).getFullYear() - 1))))
    }
    const onNextMonth = () => {
        onChange(onChangeDate((new Date(date ? new Date(date) : new Date()).setMonth(new Date(date ? new Date(date) : new Date()).getMonth() + 1))))
    }
    const onNextYear = () => {
        onChange(onChangeDate((new Date(date ? new Date(date) : new Date()).setFullYear(new Date(date ? new Date(date) : new Date()).getFullYear() + 1))))
    }

    

    const onClickPrev = useCallback(() => {
        if(onlyYear){
            onPrevYear()
        } else {
            onPrevMonth()
        }
       

    },[ onlyYear, date])

    const onClickNext = useCallback(() => {
        if(onlyYear){
            onNextYear()
        } else {
            onNextMonth()
        }
        

    },[ onlyYear, date])
    return (
        <div className={classNames(styles.root, classes?.root)}>
            <div onClick={onClickPrev} className={classNames(styles.btn, styles.btn_prev)}><ArrowIcon className={styles.prev} /></div>
            <div className={styles.date}>
                <DatePicker
            
                    locale={'ru'}
                    selected={date ? new Date(date) : new Date()}
                    onChange={(date) =>   onChange(onChangeDate(date))}
                    dateFormat={dateFormat}
                    showMonthYearPicker
                    showYearPicker={onlyYear}
                />
            </div>
            <div onClick={onClickNext} className={classNames(styles.btn, styles.btn_next)}><ArrowIcon className={styles.next} /></div>
        </div>
    )
}

export default React.memo(ChangeMonthComponent)