import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { emailDomens, mailRe } from '../../../constants/constants';
import { validMail, validPass } from '../../../constants/helper';
import { useAppSelector } from '../../../hooks/store';
import { selectAccessRights } from '../../../store/slice/storage';
import { ProfileType } from '../../../types/types';
import { AddUserContext } from '../../pages/StaffPage/StaffPage';
import { Button, ButtonColors } from '../../simples/Button';
import { CheckBox } from '../../simples/CheckBox';
import { ModalClose } from '../../simples/ModalClose';
import { TextField } from '../../simples/TextField';
import { Title } from '../../simples/Title';
import styles from './EditUserModal.module.scss'
// @ts-ignore
import $message from 'message-like-antd'
import produce from 'immer';



type Props = {
    onClose: () => void
    onSubmit: (data: localStorageType) => Promise<ProfileType | undefined>
    user: ProfileType
}

type ErrorsType = {
    mail?: string
    add_mail?: string
    pass?: string
}
export type localStorageType = {
    login: string
    password: string
}
const EditUserModal: FC<Props> = ({ onClose, onSubmit, user }) => {

    const [showAddUserModal, setShowAddUserModal] = useContext(AddUserContext)




    const accessRights = useAppSelector(selectAccessRights)
    // errors mail
    const [error, setError] = useState<ErrorsType>({})

    const [localStorage, setlocalStorage] = useState<localStorageType>({
        login: '',
        password: ''
    })

    /**
    * email notes
    */
    const emailNotes = useMemo(() => {
        if (showAddUserModal?.email && showAddUserModal?.email.includes('@') && !showAddUserModal?.email.match(mailRe)) {
            const nameMail = showAddUserModal?.email.slice(0, showAddUserModal?.email.indexOf('@') + 1)
            const firstLatter = showAddUserModal?.email.slice(showAddUserModal?.email.indexOf('@') + 1)

            const filderDomens = emailDomens.filter(i => i.includes(firstLatter))
            return filderDomens.map(i => ({ label: nameMail + i, value: nameMail + i }))
        } else {
            return []
        }

    }, [showAddUserModal?.email])

    const onAddRight = useCallback((id: number) => {

        if (showAddUserModal) {
            if (showAddUserModal?.accessRights.includes(id)) {
                const newRights = showAddUserModal.accessRights.filter((i) => i !== id)
                setShowAddUserModal({ ...showAddUserModal, accessRights: newRights })
            } else {
                setShowAddUserModal({ ...showAddUserModal, accessRights: [...showAddUserModal?.accessRights, id] })
            }
        }
    }, [setShowAddUserModal, showAddUserModal])

    const onChangeData = useCallback((e: string, key: keyof ProfileType) => {
        if (key === 'login') {
            setError({ ...error, mail: '' })

        }
        if (key === 'password') {
            setError({ ...error, pass: '' })

        }
        if (showAddUserModal) {
            setShowAddUserModal({ ...showAddUserModal, [key]: e })
        }

    }, [error, setShowAddUserModal, showAddUserModal])


    const changeLocalStorage = (e: string, key: string) => {
        setlocalStorage(produce(
            draft => {
                const f: any = draft
                f[key] = e
            }
        ))
    }

    /**
  * клик по подсказке почты
  */
    // const onEmailNoteClick = (e: string, field: keyof ProfileType) => {
    //     if (showAddUserModal) {
    //         if(field === 'email'){
    //             setShowAddUserModal({ ...showAddUserModal, email: e })
    //         }
    //     }
    // }

    /**
 * отправка на сохранение
 */
    const onSaveUser = useCallback(async () => {
        // if (showAddUserModal?.email && !validMail(showAddUserModal.email)) {
        //     setError({ ...error, mail: 'Неверно указан адрес почты' })
        //     $message.error('Неверно указан адрес почты')
        //     return
        // }
        // if (!showAddUserModal?.password || !validPass(showAddUserModal.password)) {
        //     setError({ ...error, pass: 'Пароль должен содержать минимум 8 символов' })
        //     $message.error('Недопустимый пароль')
        //     return
        // }


        onSubmit(localStorage)
            .then()
            .catch((error: any) => setError({ ...error, mail: error.response.data.errors[0] }))
    }, [error, onSubmit, localStorage, showAddUserModal?.password])

    useEffect(() => {
        changeLocalStorage(showAddUserModal?.login || "", 'login')
    }, [])

    return (
        <ModalClose

            classes={{
                modal: styles.modal,
                body: styles.modalBody
            }}
            close={true}
            onClose={onClose}
        >
            <div className={styles.holder}>
                <Title size={24}>Добавить пользователя</Title>
            </div>
            <div className={styles.content}>
                <div className={styles.holder}>
                    <TextField
                        error={error.mail}
                        classes={{
                            input: styles.input
                        }}
                        // notes={emailNotes}
                        // onNoteClick={(e) => onEmailNoteClick(e, 'login')}
                        label={'Логин'}
                        value={localStorage?.login || ''}
                        onChange={(e) => changeLocalStorage(e, 'login')}
                    />
                </div>
                <div className={styles.holder}>
                    <TextField
                        error={error.pass}
                        classes={{
                            input: styles.input
                        }}
                        label={'Пароль'}
                        value={localStorage?.password || ''}
                        onChange={(e) => changeLocalStorage(e, 'password')}
                    />
                </div>
                <div className={styles.holder}>
                    {
                        accessRights &&
                        accessRights.map((right) => <CheckBox
                            classes={{
                                root: styles.checkbox
                            }}
                            onChange={() => onAddRight(right.id)}
                            checked={showAddUserModal?.accessRights.includes(right.id)}
                            key={right.id}
                            label={right.name}
                        />)

                    }
                </div>
            </div>


            <div className={styles.btnBlock}>
                <Button
                    className={styles.btn}
                    onClick={onSaveUser}
                    color={ButtonColors.Light}
                >Сохранить</Button>
                <Button
                    className={styles.btnCancel}
                    onClick={onClose}
                    color={ButtonColors.Light}
                >Отмена</Button>
            </div>
        </ModalClose>
    )
}
export default EditUserModal