import React, { FC, useCallback, useState } from 'react'
import { Layout } from '../../complexes/Layout';
import { Tabs } from '../../simples/Tabs';
import { TabType } from '../../simples/Tabs/Tabs';
import { Departments } from './tabs/Departments';
import { StaffBirhdays } from './tabs/StaffBirhdays';
import { TabelGroup } from './tabs/TabelGroup';
import styles from './MainPage.module.scss'


enum TabsNames {
    BIRTH = 'Новости',
    DEPARTMENT = 'Филиал',
    TABEL_GROUP = 'Табельная группа'
}


const tabsMenu: TabType[] = [
    {
        label: TabsNames.BIRTH,
        value: TabsNames.BIRTH
    },
    {
        label: TabsNames.DEPARTMENT,
        value: TabsNames.DEPARTMENT
    },
    {
        label: TabsNames.TABEL_GROUP,
        value: TabsNames.TABEL_GROUP
    },
]


const MainPage: FC = () => {
    const [activeTab, setActiveTab] = useState<TabsNames>(TabsNames.BIRTH)

    const onHandleChangeTab = useCallback((value: string) => {
        setActiveTab(value as TabsNames)
    },[])
    return (
        <Layout>
            <div className={styles.root}>
                <div className="">
                    <Tabs 
                    data={tabsMenu} 
                    onChange={onHandleChangeTab} 
                    value={activeTab}
                    />
                </div>

                {activeTab === TabsNames.BIRTH && <StaffBirhdays />}
                {activeTab === TabsNames.DEPARTMENT && <Departments />}
                {activeTab === TabsNames.TABEL_GROUP && <TabelGroup />}
            </div>
        </Layout>
    )
}

export default MainPage;