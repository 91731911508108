import classNames from 'classnames'
import {
    ChangeEvent,
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { hexToRgb } from '../../../../../../../../../constants/helper'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../../../../hooks/store'
import { selectUser } from '../../../../../../../../../store/slice/auth'
import { tabelFilter } from '../../../../../../../../../store/slice/filters'
import {
    activeCellTable,
    setActiveCellTable,
    setActiveUserTabel,
    setDateTabel,
} from '../../../../../../../../../store/slice/tabel'
import {
    AccessRightType,
    IUpdateData,
    RolesKind,
    TabelGroupType,
} from '../../../../../../../../../types/types'
import {
    DayOffContext,
    EditDayContext,
    UpdateDataContext,
} from '../../../../../../config'
import { ActiveUserContext } from '../UserItem/UserItem'
import styles from './TabelTypeItem.module.scss'
import { LockTabelTypes } from '../../../../../../../../../constants/constants'
import { VACATION_VAL } from '../../../../../../../../../constants/tabel'

type Props = {
    day: string
    tabelGroup: TabelGroupType
}

const DayItem: FC<Props> = ({ day, tabelGroup }): JSX.Element => {
    const currentUser = useAppSelector(selectUser)
    const canEdit = useMemo(() => {
        return (
            currentUser?.accessRights.includes(
                AccessRightType.ChangeTabelData
            ) ||
            currentUser?.accessRights.includes(
                AccessRightType.EditTabelSelfGroup
            ) ||
            currentUser?.role === RolesKind.ADMIN
        )
    }, [currentUser?.accessRights, currentUser?.role])
    const selfData = useRef<IUpdateData>({})

    // выходные дни
    const daysOff = useContext(DayOffContext)
    // активный пользователь
    const aUser = useContext(ActiveUserContext)
    // активная ячека
    const aCell = useAppSelector(activeCellTable)
    const dispatch = useAppDispatch()
    // filter
    const filter = useAppSelector(tabelFilter)

    const updateData = useContext(UpdateDataContext)

    const initialValue: any = useRef('')
    /**
     * состояние значения ячейки
     */
    const [value, setValue] = useState<any>(
        aCell && aCell.value && aCell.value.hours
            ? aCell.value.hours
            : tabelGroup?.tabels[day as any]?.hours &&
              !tabelGroup?.tabels[day as any]?.isVacation
            ? tabelGroup?.tabels[day as any]?.hours
            : tabelGroup?.tabels[day as any]?.isVacation
            ? ''
            : ''
    )
    const [, setEditDay] = useContext(EditDayContext)
    const inputRef = useRef<HTMLInputElement>(null)
    const inputDebaunced = useRef<NodeJS.Timeout>()

    useEffect(() => {
        if (day === aCell?.day && tabelGroup.id === aCell.tabelId) {
            inputRef.current?.focus()
        }
    }, [aCell?.day, aCell?.tabelId, day, tabelGroup.id])

    useEffect(() => {
        const isVacation = tabelGroup?.tabels[day as any]?.isVacation
        const hours = tabelGroup?.tabels[day as any]?.hours || ''
        initialValue.current = isVacation ? 'От' : hours
        setValue(initialValue.current)
    }, [day, tabelGroup.tabels])

    /**
     * При клике на поле устанавливаем активную ячейку (ид табеля, пользватель и день)
     */
    const onSetActiveCell = useCallback(() => {
        const cell = {
            tabelId: tabelGroup.id,
            day,
            color: tabelGroup.color.color,
            value: tabelGroup.tabels[day as any],
            tabelName: tabelGroup.name,
        }
        const currentDay = `${filter?.year}-${filter?.month}-${
            +day < 10 ? `0${+day}` : +day
        }T23:23:23`
        selfData.current = {
            aUser,
            //@ts-ignore
            aCell: cell,
            tabelNumber: aUser.tabelNumber,
        }
        dispatch(setActiveCellTable(cell))
        dispatch(setDateTabel(currentDay))
        dispatch(setActiveUserTabel(aUser))
    }, [aUser, day, dispatch, filter?.month, filter?.year, tabelGroup])

    /**
     * определяем является ли день выходным
     */
    const isWeekend = useMemo(() => {
        const currentDay = new Date(
            `${filter?.year}-${filter?.month}-${
                +day + 1 < 10 ? `0${+day + 1}` : +day + 1
            }`
        ).getDay()
        const isDayOff = daysOff.includes(
            `${filter?.year}-${filter?.month}-${+day < 10 ? `0${+day}` : +day}`
        )
        return currentDay === 1 || currentDay === 0 || isDayOff
    }, [day, daysOff, filter?.month, filter?.year])

    //изменение значения в ячейки
    const onChangeValue = async (e: ChangeEvent<HTMLInputElement>) => {
        let val: string = e.target.value.toLocaleLowerCase()
        const isVacRow = aCell?.tabelName === LockTabelTypes.AUTO
        const isVacationValue = val === 'o' || val === 'о' || isVacRow
        val = isVacationValue ? VACATION_VAL : val
        const isNumeric = val.match(/^(\s*|\d+)$/)
        val = isNumeric ? (+val > 24 ? '24' : val) : val
        if (isNumeric) {
            setValue(val)
            setEditDay(val)
        }
        if (isVacationValue) {
            await updateData(val, selfData.current)
        }
    }
    const onChangeData = useCallback(async () => {
        const isBeenChanged = initialValue.current !== value
        const isData =
            !aCell?.value || (aCell?.value && +value !== aCell?.value?.hours)

        if (isBeenChanged && isData) {
            await updateData(value, selfData.current)
            setEditDay(undefined)
        }
    }, [aCell, setEditDay, updateData, value])

    useEffect(() => {
        inputDebaunced.current && clearTimeout(inputDebaunced.current)
        inputDebaunced.current = setTimeout(onChangeData, 1000)
        return () => {
            clearTimeout(inputDebaunced.current)
        }
    }, [value, onChangeData])

    /**
     * вывод компонента
     */
    return (
        <td
            style={{
                borderTopWidth: tabelGroup.id === aCell?.tabelId ? 2 : 0,
                borderBottomWidth: tabelGroup.id === aCell?.tabelId ? 2 : 0,
                borderLeftColor: day === aCell?.day ? aCell.color : '',
                borderRightColor: day === aCell?.day ? aCell.color : '',
                borderTopColor:
                    tabelGroup.id === aCell?.tabelId ? aCell.color : '',
                borderBottomColor:
                    tabelGroup.id === aCell?.tabelId ? aCell.color : '',
            }}
            className={classNames({
                [styles.isWeekendInner]: isWeekend,
                [styles.isCurrentDay]: day === aCell?.day,
            })}
        >
            {day && tabelGroup.tabels && (
                <div
                    className={classNames(styles.innerDiv)}
                    style={{
                        backgroundColor: `rgba(${hexToRgb(
                            tabelGroup.color.color
                        )},0.15)`,
                    }}
                >
                    {/* {dayValue} */}
                    {canEdit ? (
                        <input
                            onFocus={onSetActiveCell}
                            onBlur={onChangeData}
                            ref={inputRef}
                            className={classNames(styles.input, {
                                [styles.inputOt]:
                                    value === VACATION_VAL || value === -1,
                            })}
                            onChange={onChangeValue}
                            value={`${value === -1 ? VACATION_VAL : value}`}
                        />
                    ) : (
                        <span>{value}</span>
                    )}
                </div>
            )}
        </td>
    )
}

export default DayItem
