import classNames from 'classnames'
import React, { FC, useRef } from 'react'
import { Department } from '../../../../../types/types'
import styles from './Departments.module.scss'


type Props = {
    department: Department
    activeDepart?: Department
    onClick: (dep: Department) => void
    onChange: (id: number, val: string) => void
    isEdit: boolean
}

const Item: FC<Props> = ({ department, activeDepart, onClick, onChange, isEdit }) => {
    const mainBlock = useRef<HTMLDivElement>(null)

    return (
        <div
            ref={mainBlock}
            key={department.id}
            className={classNames(styles.item, {
                [styles.activeItem]: department.id === activeDepart?.id,
                [styles.disableItem]: !!activeDepart && department.id !== activeDepart?.id
            })}
            onClick={() => onClick(department)}>
            <div className={classNames({
                [styles.isEdit]: isEdit
            })}>
                <input
                    disabled={!isEdit}
                    onChange={(e) => onChange(department.id, e.target.value)} value={department.name}
                />
            </div>
        </div>
    )
}

export default Item