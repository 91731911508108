import classNames from 'classnames'
import React, { FC } from 'react'
import { hexToRgb } from '../../../../../../../../../constants/helper'
import { useAppSelector } from '../../../../../../../../../hooks/store'
import { activeCellTable } from '../../../../../../../../../store/slice/tabel'
import { TabelGroupType } from '../../../../../../../../../types/types'
import DayItem from './DayItem'
import styles from './TabelTypeItem.module.scss'

type Props = {
    tabelGroup: TabelGroupType
}

const TabelTypeItem: FC<Props> = ({ tabelGroup }): JSX.Element => {
    const aCell = useAppSelector(activeCellTable)

    return (
        <tr
            style={
                {
                    // borderColor: tabelGroup.color.color
                }
            }
            className={classNames(styles.typeRow, {
                [styles.isCurrentTabel]: tabelGroup.id === aCell?.tabelId,
            })}
        >
            <td
                style={{
                    borderWidth: tabelGroup.id === aCell?.tabelId ? 2 : 0,
                    borderColor: tabelGroup.color.color,
                    borderTopColor: tabelGroup.color.color,
                }}
                className={classNames(styles.cell, styles.cell_sticky_1)}
            >
                <div
                    className={classNames(styles.innerDivFirst, {})}
                    style={{
                        backgroundColor: `rgba(${hexToRgb(
                            tabelGroup.color.color
                        )},0.15)`,
                    }}
                >
                    <div className={styles.number}>
                        <div
                            style={{
                                backgroundColor: tabelGroup.color.color,
                            }}
                            className={styles.colorMark}
                        />
                        <span
                            style={{
                                color: tabelGroup.color.color,
                            }}
                        >
                            {tabelGroup.coefficient
                                ? 'X' + tabelGroup.coefficient
                                : ''}
                        </span>
                    </div>
                </div>
            </td>
            <td
                style={{
                    borderWidth: tabelGroup.id === aCell?.tabelId ? 2 : 0,
                    borderColor: tabelGroup.color.color,
                }}
                className={classNames(styles.cell, styles.cell_sticky_2)}
            >
                <div
                    className={classNames(styles.innerDivSecond)}
                    style={{
                        backgroundColor: `rgba(${hexToRgb(
                            tabelGroup.color.color
                        )},0.15)`,
                        borderColor: tabelGroup.color.color,
                    }}
                >
                    <span
                        className={classNames(styles.name, {
                            // [styles.activeName]: aUser === activeUser.id
                        })}
                    >
                        {tabelGroup.name}
                    </span>
                </div>
            </td>
            {Object.keys(tabelGroup.tabels).map((item, index) => (
                <DayItem key={index} day={item} tabelGroup={tabelGroup} />
            ))}
        </tr>
    )
}

export default React.memo(TabelTypeItem)
