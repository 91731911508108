import styles from './EditTypesTabelEmployerModal.module.scss'
import { ModalClose } from '../../simples/ModalClose'
import { CheckBox } from '../../simples/CheckBox'
import { useContext, useEffect, useState } from 'react'
import { Title } from '../../simples/Title'
import { Button, ButtonColors } from '../../simples/Button'
import { TabelTypeType } from '../../../types/types'
import { useAppSelector } from '../../../hooks/store'
import { EditImployerTabelTypesContext } from '../../pages/StaffPage/StaffPage'

type Props = {
    onClose: () => void
    onSubmit: () => void
    choosed: number[]
}

const EditTypesTabelEmployerModal = ({ onClose, onSubmit, choosed }: Props) => {
    const [tempChoosed, setTempChoosed] = useState(choosed)
    const tabelTypes = useAppSelector((state) => state.storage.tabelTypes)
    const [editUser, setEditUser] = useContext(EditImployerTabelTypesContext)

    const changeTempChoosed = (tabelType: TabelTypeType, checked: boolean) => {
        setTempChoosed((prev) => {
            return checked
                ? [...prev, tabelType.id]
                : prev.filter((el) => el !== tabelType.id)
        })
    }

    const save =() => {
        onSubmit();
        onClose()
    }

    useEffect(() => {
        editUser &&
            editUser?.tabelsTypes !== tempChoosed &&
            setEditUser({ ...editUser, tabelsTypes: tempChoosed })
    }, [editUser, setEditUser, tempChoosed])

    return (
        <ModalClose
            classes={{
                modal: styles.modal,
                body: styles.modalBody,
            }}
            close={true}
            onClose={onClose}
        >
            <div className={styles.holder}>
                <Title size={24}>Редактирование типов табеля</Title>
            </div>
            {tabelTypes.map((tabelType) => {
                const isChecked = tempChoosed.includes(tabelType.id)
                return (
                    <div className={styles.item} key={String(tabelType.id)}>
                        <CheckBox
                            checked={isChecked}
                            onChange={changeTempChoosed.bind(null, tabelType)}
                        />
                        <p className={styles.tableType}>{tabelType.name}</p>
                    </div>
                )
            })}
            <div className={styles.btnBlock}>
                <Button
                    className={styles.btn}
                    onClick={save}
                    color={ButtonColors.Light}
                >
                    Сохранить
                </Button>
                <Button
                    className={styles.btnCancel}
                    onClick={onClose}
                    color={ButtonColors.Light}
                >
                    Отмена
                </Button>
            </div>
        </ModalClose>
    )
}

export default EditTypesTabelEmployerModal
