import api from '../utils/api'
import { Department, SortByDepart, SortType } from "../types/types";
import { Endpoints } from '../constants/constants';



export class DepartamentService {
    static async getDepartaments(data: {
        name?: string
        sortBy?: SortByDepart
        sort?: SortType,
        offset?: number
        limit?: number
    }): Promise<Department[]>{
        const query: Array<string> = []

        let aData: any = data

        for(let key in data){
            query.push(`${key}=${aData[key]}`)
        }
        const response = await api.get(`${Endpoints.DEPARTMENTS}?${query.join('&')}`)

        return response.data.data
    }

    static async updateDepart(id: number, data: {
        name: string,
        desription?: string
    }): Promise<Department> {
        const fd = new FormData()
        const aData: any = data
        for(let key in data){
            fd.append(`${key}`, aData[key])
        }
        const response = await api.post(`${Endpoints.DEPARTMENTS_UPDATE}/${id}`, fd)
        return response.data.data
    }
    static async createDepart(data: {
        name: string,
        desription?: string
    }): Promise<Department> {
        const fd = new FormData()
        const aData: any = data
        for(let key in data){
            fd.append(`${key}`, aData[key])
        }
        const response = await api.post(Endpoints.DEPARTMENTS_CREATE, fd)
        return response.data.data
    }

    static async deleteDepartment(id: number):Promise<string>{
        const response = await api.delete(`${Endpoints.DEPARTMENTS_DELETE}/${id}`)
        return response.data.data[0]
    }
}