import React, { FC, useCallback, useState } from 'react'
import { Button, ButtonColors } from '../../simples/Button'
import { ModalClose } from '../../simples/ModalClose'
import { TextField } from '../../simples/TextField'
import styles from './CreateNewsModal.module.scss'

type Props = {
    onCreate: (content: string) => void
    onClose: () => void
}

/**
 * 
 * @param onCreate 
 * @param onClose 
 * @returns 
 */
const CreateNewsModal:FC<Props> = ({onCreate, onClose}) => {
    const [content, setContent] = useState<string>('')

    const onHandleCreate = useCallback(() => {
        onCreate(content)
    },[content, onCreate])
    return (
        <ModalClose
            title={'Создать нововсть'}
            onClose={onClose}
        >
            <div className={styles.root}>
                <TextField 
                    multyline
                    onChange={setContent}
                    value={content}
                />
                <Button
                    onClick={onHandleCreate}
                    className={styles.button}
                    color={ButtonColors.Light}
                >Добавить новость</Button>
            </div>
        </ModalClose>
    )
}

export default CreateNewsModal