import classNames from 'classnames'
import React, { FC, useMemo } from 'react'
import { stringDateWithTime } from '../../../../constants/helper'
import { LogItemType } from '../../../../types/types'
import styles from './Parts.module.scss'

type Props = {
    data: LogItemType
}

const InnerItem: FC<Props> = ({ data }) => {

    const change = useMemo((): JSX.Element[] => {
        const c = JSON.parse(data.change)
        let arrayjsx = Object.entries(c).map((i: Array<any>) => <div className={styles.wrapperItem}>
            <div className={styles.left}>{i[0]}</div>
            <div className={styles.right}>{i[1]}</div>
        </div>)
        return arrayjsx
    }, [data])
    const changed = useMemo((): JSX.Element[] => {
        const c = JSON.parse(data.changed)
        let arrayjsx = Object.entries(c).map((i: Array<any>) => {
            return (
                <div key={i[0]} className={styles.wrapperItem}>

                    <div className={styles.left}>{i[0]}</div>
                    <div className={styles.right}>{i[1]}</div>
                </div>
            )
        })
        return arrayjsx
    }, [data])


    return (
        <tr className={styles.innerRow}>
            <td className={classNames(styles.cell, styles.cell_inner)}>
                <div className={styles.holder}>{change}</div>
            </td>
            <td className={classNames(styles.cell, styles.cell_inner)}>
                <div className={styles.holder}>{changed}</div>
            </td>
            <td className={classNames(styles.cell, styles.cell_inner)}>{stringDateWithTime(data.createdAt)}</td>
        </tr>
    )
}

export default React.memo(InnerItem)