import { RightBlockWrapper } from '../../../../simples/RightBlockWrapper'
import BottomPart from './BottomPart'
import TopPart from './TopPart'



const RightPanel = () => {
    return (
        <RightBlockWrapper
            top={<BottomPart />}
            bottom={<TopPart />}
        />
    )
}

export default RightPanel
