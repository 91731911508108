import { Endpoints } from '../constants/constants'
import { PositionType, SortType } from '../types/types'
import api from '../utils/api'

export class PositionsService {
    static async getPositions(data: {
        name?: string
        sort?: SortType
        offset?: number
        limit?: number
    }): Promise<PositionType[]> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            if (aData[key]) {
                query.push(`${key}=${aData[key]}`)
            }
        }

        const response = await api.get(
            `${Endpoints.POSITIONS}?${query.join('&')}`
        )
        return response.data.data
    }

    static async getPositionsId(id: string): Promise<PositionType> {
        const response = await api.get(`${Endpoints.POSITIONS_ID}/${id}`)
        return response.data.data
    }

    static async createPosition(name: string):Promise<PositionType> {
        const fd = new FormData()
            fd.append('name', name)
        
        const response = await api.post(Endpoints.POSITIONS_CREATE,fd )
        return response.data.data
    }
}
