import api from '../utils/api'
import { Endpoints } from '../constants/constants'

import { SortByType, SortType, TabelGroup } from '../types/types'

export class TabelsGroupService {
    static async getTabelsGroups(data: {
        name?: string
        branchId?: number
        sort?: SortType
        sortBy?: SortByType
        offset?: number
        limit?: number
    }): Promise<TabelGroup[]> {
        const query: Array<string> = []

        let aData: any = data

        for (let key in data) {
            query.push(`${key}=${aData[key]}`)
        }
        const response = await api.get(
            `${Endpoints.TABELS_GROUP}?${query.join('&')}`
        )
        return response.data.data
    }

    static async updateTabelsGroup(
        id: number,
        data: {
            name: string
            branchId: number
        }
    ): Promise<TabelGroup> {
        const fd = new FormData()
        const aData: any = data
        for (let key in data) {
            fd.append(`${key}`, aData[key])
        }
        const response = await api.post(
            `${Endpoints.TABELS_GROUP_UPDATE}/${id}`,
            fd
        )
        return response.data.data
    }
    static async createTabelsGroup(data: {
        name: string
        branchId: number
    }): Promise<TabelGroup> {
        const fd = new FormData()
        const aData: any = data
        for (let key in data) {
            fd.append(`${key}`, aData[key])
        }
        const response = await api.post(Endpoints.TABELS_GROUP_CREATE, fd)
        return response.data.data
    }

    static async getTabelGroupId(id: string): Promise<TabelGroup> {
        const response = await api.get(`${Endpoints.TABELS_GROUP_ID}/${id}`)
        return response.data.data
    }

    static async deleteTabelGroup(id: number){
        const response = await api.delete(`${Endpoints.TABELS_GROUP_DELETE}/${id}`)
        return response.data.data
    }
}
