import { mailRe } from "./constants"

export const getFormData = (data: any) => {
    const fd: FormData = new FormData()

    for (let [key, item] of Object.entries(data)) {
        if (item !== undefined || item !== '') {
            if (typeof item === 'string' || typeof item === 'number') {
                // @ts-ignore
                fd.append(`${key}`, item)
            } else if (typeof item === 'boolean') {
                fd.append(`${key}`, item ? '1' : '0')
            } else if (Array.isArray(item)) {
                item.forEach((elem) => {
                    fd.append(`${key}`, elem)
                })
            } else {
                new Error('Неизвестный формат данных')
            }
        }
    }
    return fd
}

export const sendFormData = (data: any): FormData => {
    const sendData = new FormData()
    for (let key in data) {
        if (data[key] !== undefined && data[key] !== null) {
            if (Array.isArray(data[key])) {
                sendData.append(`${key}`, JSON.stringify(data[key]))
            } else {
                sendData.append(`${key}`, data[key])
            }

        }
    }

    return sendData
}

export const getUnique = (arrBase: any, arrNew: any) => {
    //@ts-ignore
    const newUniqueValue = Array.from(new Set(arrNew)).map(JSON.stringify)
    //@ts-ignore
    const baseUniqueValue = Array.from(new Set(arrBase)).map(JSON.stringify)

    let unique1 = newUniqueValue.filter(
        (o) => baseUniqueValue.indexOf(o) === -1
    )
    // let unique2 = baseUniqueValue.filter((o) => newUniqueValue.indexOf(o) === -1);
    //@ts-ignore
    return unique1.map(JSON.parse)
}

export const uploadPhoto = async (
    id: number,
    method: (id: number, data: any) => any,
    data: any
): Promise<any> => {
    const response = await method(id, data)
    return response
}

export const stringDate = (date: string): string => {
    if (!date) {
        return ''
    }
    if (date.split('.')[0].length === 2) {
        return date
    }
    let options: any = {
        // era: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        // weekday: 'long',
        // timezone: 'UTC',
        // hour: 'numeric',
        // minute: 'numeric',
        // second: 'numeric'
    }
    const d = new Date(date)
    const stringD = d.toLocaleString('ru', options)
    return stringD
}

export const clearDate = (date: string) => {
    if (date.split('.')[0].length === 2) {
        return date.split('.').reverse().join('-')
    } else if (date.split('.')[0].length === 4) {
        return date
    } else {
        return ''
    }
}
export const stringDateWithTime = (date: string): string => {
    let options: any = {
        // era: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        // weekday: 'long',
        // timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    }
    const d = new Date(date)
    const stringD = d.toLocaleString('ru', options)
    return stringD
}

export const prindTimeDate = (date: string): string => {
    let options: any = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }

    let timeOptions: any = {
        hour: 'numeric',
        minute: 'numeric',
    }

    if (!date) {
        return ''
    }
    const d = new Date(date)
    const stringD = d.toLocaleString('ru', options)
    const stringTime = d.toLocaleString('ru', timeOptions)
    return `${stringTime}, ${stringD}`
}

export const stringDateWithDay = (date: string): string => {
    let options: any = {
        // era: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        // timezone: 'UTC',
        // hour: 'numeric',
        // minute: 'numeric',
        // second: 'numeric'
    }
    const d = new Date(date)
    const stringD = d.toLocaleString('ru', options)
    return stringD
}

export const getfullAge = (date: string): number => {
    let timeMS = new Date(date).getTime()
    let currentDate = new Date().getTime()
    let different = new Date(currentDate - timeMS).getFullYear() - 1970;
    return different
}

export const hexToRgb = (hex?: string): string => {
    let result = hex && /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
            result[3],
            16
        )}`
        : '0,0,0'
}

export const arrayToQuery = (data: Array<number | string>) => {
    const query = '%5B' + data.join('%2C') + '%5D'
    return query
}

export const printTime = (time: number) => {
    const hours = Math.floor(time / 1000 / 60 / 60)
    const min = Math.floor((time / 1000 / 60 / 60 - hours) * 60)
    return `${hours || '0'} часов ${min || '0'} мин`
}

export const validMail = (mail: string): boolean => {
    return !!mail.match(mailRe)
}

export const validPass = (pass: string): boolean => {
    return !!pass.match(/^.{8,}$/)
}

export const addZero = (num: number): string => {
    return num < 10 ? `0${num}` : `${num}`
}

// функция высчитывает разницу времени между внесенными данными
export const getTime = (ent: string, ex: string) => {
    const currentDate = new Date()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    const day = currentDate.getDate()
    const entTime = ent.split('T')[1]
    const exTime = ex.split('T')[1]
    const entrance = `${year}-${addZero(month)}-${addZero(day)}T${entTime}`
    const exit = `${year}-${addZero(month)}-${addZero(day)}T${exTime}`
    let differtentTime = 0
    if (new Date(exit || new Date()).getTime() >= new Date(entrance || new Date()).getTime()) {
        differtentTime = new Date(exit || new Date()).getTime() - new Date(entrance || new Date()).getTime()
    } else {


        const d = new Date(entrance)
        const month = d.getMonth() + 1
        const year = d.getFullYear()
        const day = d.getDate()

        const getPrevDay = (date: string) => {


            let parseDate = date.split('-')
            let dd = parseDate[2]
            let dm = parseDate[1]
            let dy = parseDate[0]

            const DAYS_IN_MONTH = [
                31,
                +dy % 4 === 0
                    ? 29
                    : 28
                ,
                31,
                30,
                31,
                30,
                31,
                31,
                30,
                31,
                30,
                31,
            ]

            let newDate = date
            if (dd === '00' && +dm > 1) {
                newDate = `${dy}-${addZero(+dm - 1)}-${DAYS_IN_MONTH[+dm - 2]}`
            }
            if (dd === '00' && +dm === 1) {
                newDate = `${+dy - 1}-${addZero(+dm - 1)}-${DAYS_IN_MONTH[+dm - 2]}`
            }

            return newDate
        }

        let currentDayTime = '23:59:59'
        let dateDay = `${year}-${addZero(month)}-${addZero(day)}T${currentDayTime}`
        let datePrevDay = `${getPrevDay(`${year}-${addZero(month)}-${addZero(day - 1)}`)}T${currentDayTime}`
        dateDay = entrance.split('T')[0] + 'T' + currentDayTime
        const timeBeforeNewDay = new Date(dateDay).getTime() - new Date(entrance).getTime()
        const timeAfterOldDay = new Date(exit).getTime() - new Date(datePrevDay).getTime()

        differtentTime = timeBeforeNewDay + timeAfterOldDay

    }
    return differtentTime

}
