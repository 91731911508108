import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { ProfileType } from '../../../types/types';
import { AddImployerContext } from '../../pages/StaffPage/StaffPage';
import { Button, ButtonColors } from '../../simples/Button';
import { Dropdown } from '../../simples/Dropdown';
import { OptionType } from '../../simples/Dropdown/Dropdown';
import { ModalClose } from '../../simples/ModalClose';
import { TextField } from '../../simples/TextField';
import { Title } from '../../simples/Title';
import { ReactComponent as MarkIcon } from '../../../assets/icons/mark.svg';
import styles from './EditImployerModal.module.scss'
// @ts-ignore
import $message from 'message-like-antd'
import { validMail } from '../../../constants/helper';
import classNames from 'classnames';
import { ProfileService } from '../../../services/ProfileService';
import { emailDomens, mailRe } from '../../../constants/constants';
import { PositionsService } from '../../../services/PositionsServece';
import { fetchPositions } from '../../../store/slice/storage';



type Props = {
    onClose: () => void
    onSubmit: () => void
    user?: ProfileType
}

enum TabelKeyName {
    TABEL_GROUP = 'tabel_group',
    TABEL_TYPE = 'tabel_type'
}

type ErrorsType = {
    mail?: string
    add_mail?: string
}

const EditImployerModal: FC<Props> = ({
    onClose,
    onSubmit,
}) => {

    const dispatch = useAppDispatch()

    // Tabel groups
    const tabelGroups = useAppSelector(state => state.storage.tabelGroups)
    // Positions
    const positions = useAppSelector(state => state.storage.positions)
    // errors mail
    const [error, setError] = useState<ErrorsType>({})
    // Profile notes
    const [notes, setNotes] = useState<ProfileType[]>([])



    /**
     * editble user
     */
    const [editUser, setEditUser] = useContext(AddImployerContext)
    console.log('editUser', editUser);


    /**
     * email notes
     */
    const emailNotes = useMemo(() => {
        if (editUser?.email && editUser?.email.includes('@') && !editUser?.email.match(mailRe)) {
            const nameMail = editUser?.email.slice(0, editUser?.email.indexOf('@') + 1)
            const firstLatter = editUser?.email.slice(editUser?.email.indexOf('@') + 1)

            const filderDomens = emailDomens.filter(i => i.includes(firstLatter))
            return filderDomens.map(i => ({ label: nameMail + i, value: nameMail + i }))
        } else {
            return []
        }

    }, [editUser?.email])
    /**
     * add email notes
     */
    const addEmailNotes = useMemo(() => {
        if (editUser?.addEmail && editUser?.addEmail.includes('@') && !editUser?.addEmail.match(mailRe)) {
            const nameMail = editUser?.addEmail.slice(0, editUser?.addEmail.indexOf('@') + 1)
            const firstLatter = editUser?.addEmail.slice(editUser?.addEmail.indexOf('@') + 1)

            const filderDomens = emailDomens.filter(i => i.includes(firstLatter))
            return filderDomens.map(i => ({ label: nameMail + i, value: nameMail + i }))
        } else {
            return []
        }

    }, [editUser?.addEmail])




    /**
     * positions options
     */
    const positionsOptions: OptionType[] = useMemo((): OptionType[] => {
        let options: OptionType[] = []
        if (positions) {
            options = positions.map((i): OptionType => ({
                label: i.name,
                value: `${i.id}`
            }))
        }

        return options
    }, [positions])

    /**
     * tabelTypes options
     */
    const optionsTabelGroup = useMemo((): OptionType[] => {
        const opt: {
            [key: number]: OptionType
        } = {}
        tabelGroups.map((item) => {
            if (item.branchId) {

                const os: Array<OptionType> = opt[item.branchId] && opt[item.branchId].options ? opt[item.branchId].options as Array<OptionType> : [] as Array<OptionType>

                opt[item.branchId] = {
                    label: item.branch?.name || '',
                    value: `${item.branchId}`,
                    keyName: TabelKeyName.TABEL_TYPE,
                    options: [...os, {
                        label: item.name || '',
                        value: `${item.id}`,
                        keyName: TabelKeyName.TABEL_GROUP
                    }]
                }
            }

        })
        return Object.values(opt).map(i => i).sort((a, b) => {
            if (a.label < b.label) { return -1 }
            if (a.label > b.label) { return 1 }
            return 0
        })
    }, [tabelGroups])


    /**
     * change user
     */
    const changeUser = useCallback((field: keyof ProfileType, value: string | number) => {
        if (editUser) {
            if (field === 'email') {
                setError({ ...error, mail: '' })

            }
            if (field === 'addEmail') {
                setError({ ...error, add_mail: '' })
            }
            setEditUser({ ...editUser, [field]: value })
        }

    }, [editUser, error, setEditUser])


    /**
     * change tabel group
     */
    const onChangeTabelGroup = useCallback((e: {
        value: string
    }) => {
        if (editUser) {
            const branchId = tabelGroups.find(i => i.id === +e.value)?.branchId as number
            setEditUser({ ...editUser, tabelGroupId: +e.value, branchId })
        }
    }, [editUser, setEditUser, tabelGroups])

    /**
     * change tpositions
     */
    const onChangePosition = useCallback((e: {
        value: string
    }) => {
        if (editUser) {
            setEditUser({ ...editUser, positionId: +e.value })
        }
    }, [editUser, setEditUser])

    /**
     * отправка на сохранение
     */
    const onSaveUser = useCallback(() => {
        if (editUser?.email && !validMail(editUser.email)) {
            setError({ ...error, mail: 'Неверно указан адрес почты' })
            $message.destroy()
            $message.error('Неверно указан адрес почты')
            return
        }
        if (editUser?.addEmail && !validMail(editUser.addEmail)) {
            setError({ ...error, add_mail: 'Неверно указан адрес дополнительной почты' })
            $message.destroy()
            $message.error('Неверно указан адрес дополнительной почты')
            return
        }
        onSubmit()
    }, [editUser?.addEmail, editUser?.email, error, onSubmit])

    /**
     * получение подсказок по пользователям
     */
    const loadUsers = useCallback(async (search: string) => {
        const response = await ProfileService.getProfile({ lastName: search, limit: 15 })
        setNotes(response)
    }, [])

    useEffect(() => {
        if (editUser?.lastName !== undefined && !editUser.id) {
            loadUsers(editUser?.lastName || '').then()
        }
    }, [editUser?.id, editUser?.lastName, loadUsers])

    /**
     * клие на подсказку пользователя
     */
    const onNoteClick = (e: string) => {
        const find = notes.find(i => i.id === +e)
        if (find) {
            setEditUser(find)
            setNotes([])
        }
    }

    /**
     * клик по подсказке почты
     */
    const onEmailNoteClick = (e: string, field: keyof ProfileType) => {
        if (editUser) {
            if (field === 'email') {
                setEditUser({ ...editUser, email: e })
            }
            if (field === 'addEmail') {
                setEditUser({ ...editUser, addEmail: e })
            }

        }
    }

    const dropdownFilterLabel = useMemo(() => {
        let label: string = ''
        if (editUser && (editUser.tabelGroupId)) {


            const findTabelGroup = tabelGroups.find((i) => {
                if (editUser.tabelGroupId) {
                    return i.id === editUser.tabelGroupId
                }
            })?.name || ''

            label = ` ${findTabelGroup ? findTabelGroup : ''}`
        }

        return label

    }, [editUser, tabelGroups])


    //add new position
    const onAddNewPosition = useCallback(async (positionName: string) => {
        try {
            const response = await PositionsService.createPosition(positionName)
            dispatch(fetchPositions()).then(() => {
                if (editUser) {
                    setEditUser({ ...editUser, positionId: response.id })
                }
            })
            return true
        } catch (error) {
            return false
        }
    }, [dispatch, editUser, setEditUser])


    return (
        <ModalClose

            classes={{
                modal: styles.modal,
                body: styles.modalBody
            }}
            close={true}
            onClose={onClose}
        >
            <div className={styles.holder}>
                <Title size={24}>{editUser?.id ? 'Редактирование сотрудника' : 'Добавить сотрудника'}</Title>
            </div>
            <div className={styles.content}>
                <div className={styles.holder}>
                    <TextField
                        notes={notes.map((i) => ({
                            label: `${i.lastName || ''} ${i.firstName || ''} ${i.secondName || ''}`,
                            value: `${i.id}`
                        }))}
                        onNoteClick={onNoteClick}
                        classes={{
                            input: classNames(styles.input, styles.fioInput)
                        }}
                        label={'Фамилия'}
                        value={editUser?.lastName || ''}
                        onChange={(value) => changeUser('lastName', value)}
                    />
                </div>
                <div className={styles.holder}>
                    <TextField
                        classes={{
                            input: classNames(styles.input, styles.fioInput)
                        }}
                        label={'Имя'}
                        value={editUser?.firstName || ''}
                        onChange={(value) => changeUser('firstName', value)}
                    />
                </div>
                <div className={styles.holder}>
                    <TextField
                        classes={{
                            input: classNames(styles.input, styles.fioInput)
                        }}
                        label={'Отчество'}
                        value={editUser?.secondName || ''}
                        onChange={(value) => changeUser('secondName', value)}
                    />
                </div>
                <div className={styles.holder}>
                    <TextField
                        date
                        mask={'99.99.9999'}
                        classes={{
                            input: styles.input
                        }}
                        label={'Дата рождения'}
                        value={editUser?.dateOfBirth || ''}
                        onChange={(value) => changeUser('dateOfBirth', value)}
                    />
                </div>
                <div className={styles.holder}>
                    <Dropdown
                        cascade
                        icon={<MarkIcon />}
                        classes={{
                            drop: styles.dropDownCascadeBlock,
                            root: styles.dropdownRoot
                        }}
                        labelInner={dropdownFilterLabel}
                        label={'Табельная группа'}
                        value={[`${editUser?.tabelGroupId || ''}`]}
                        options={optionsTabelGroup}
                        placeholder={'Табельная группа'}
                        onChange={onChangeTabelGroup}
                    />
                </div>

                <div className={styles.holder}>
                    <Dropdown
                        withSearch
                        searchPlaceholder={'Введите должность'}
                        onAdd={onAddNewPosition}
                        icon={<MarkIcon />}
                        classes={{

                            root: styles.dropdownRoot,

                        }}
                        label={'Должность'}
                        value={[`${editUser?.positionId || ''}`]}
                        options={positionsOptions}
                        placeholder={'Должность'}
                        onChange={onChangePosition}
                    />
                </div>
                <div className={styles.holder}>
                    <TextField
                        mask={'+7(999) 999-99-99'}
                        classes={{
                            input: styles.input
                        }}
                        label={'Телефон'}
                        value={editUser?.phone || ''}
                        onChange={(value) => changeUser('phone', value)}
                    />
                </div>
                <div className={styles.holder}>
                    <TextField
                        classes={{
                            input: styles.input
                        }}
                        label={'Внут. телефон'}
                        value={editUser?.internalPhone || ''}
                        onChange={(value) => changeUser('internalPhone', value)}
                    />
                </div>
                <div className={styles.holder}>
                    <TextField
                        error={error.mail}
                        notes={emailNotes}
                        onNoteClick={(e) => onEmailNoteClick(e, 'email')}
                        classes={{
                            input: styles.input
                        }}
                        label={'Email'}
                        value={editUser?.email || ''}
                        onChange={(value) => changeUser('email', value)}
                    />
                </div>
                <div className={styles.holder}>
                    <TextField
                        error={error.add_mail}
                        notes={addEmailNotes}
                        onNoteClick={(e) => onEmailNoteClick(e, 'addEmail')}
                        classes={{
                            input: styles.input
                        }}
                        label={'Доп. E-mail'}
                        value={editUser?.addEmail || ''}
                        onChange={(value) => changeUser('addEmail', value)}
                    />
                </div>
            </div>


            <div className={styles.btnBlock}>
                <Button
                    className={styles.btn}
                    onClick={onSaveUser}
                    color={ButtonColors.Light}
                >{editUser?.id ? 'Сохранить' : 'Добавить'}</Button>
                <Button
                    className={styles.btnCancel}
                    onClick={onClose}
                    color={ButtonColors.Light}
                >Отмена</Button>

            </div>

        </ModalClose>
    )
}
export default EditImployerModal