import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { DocsContext } from '../../Docs'
import { ReactComponent as DownloadIcon } from '../../../../../../../assets/icons/download.svg'
import { ReactComponent as PrintIcon } from '../../../../../../../assets/icons/print.svg'
import { ReactComponent as DelIcon } from '../../../../../../../assets/icons/del.svg'
import styles from './Table.module.scss'
import { CheckBox } from '../../../../../../simples/CheckBox'
import classNames from 'classnames'

type Props = {
    onDownload: (id: number) => void
    onPrint: (id: number) => void
    onDelete: (id: number) => void

    values: number[]
    onCheck: (id: number[]) => void
}
const Table: FC<Props> = ({
    onDownload,
    onPrint,
    onDelete,
    values,
    onCheck,

}) => {

    const data = useContext(DocsContext);

    const [all, setAll] = useState<boolean>(false)

    useEffect(() => {
        if(all){
            onCheck(data.map(i => i.id))
        } else {
            onCheck([])
        }
    },[all, data])

    const onHandleCheck = useCallback((id) => {
        onCheck(id)
    },[onCheck])

    
    return (
        <table className={styles.docTable}>
            <thead>
                <tr className={styles.docRow}>
                    <th className={classNames(styles.cell,styles.cell_1)}>
                        <CheckBox
                                onChange={() => setAll(prev => !prev)}
                                checked={all}
                            />
                            </th>
                    <th className={classNames(styles.cell,styles.cell_2)}>№</th>
                    <th className={classNames(styles.cell,styles.cell_3)}>Название документа</th>
                    {/* <th>Назначение</th> */}
                    <th className={classNames(styles.cell,styles.cell_4)}></th>
                </tr>
            </thead>

            <tbody>
                {
                    data.map((doc, index) => <tr className={styles.docRow} key={doc.id}>
                        <th className={classNames(styles.cell,styles.cell_1)}>
                            <CheckBox
                                onChange={() => onHandleCheck([doc.id])}
                                checked={values.includes(doc.id)}
                            />
                        </th>
                        <th className={classNames(styles.cell,styles.cell_2)}>{index + 1}</th>
                        <th className={classNames(styles.cell,styles.cell_3)}>{doc.name}</th>
                        <th className={classNames(styles.cell,styles.cell_4)}>
                            <div>
                                <DownloadIcon onClick={() => onDownload(doc.id)} />
                                <PrintIcon onClick={() => onPrint(doc.id)} />
                                <DelIcon onClick={() => onDelete(doc.id)} />
                            </div>
                        </th>
                    </tr>)
                }
            </tbody>
        </table>
    )
}

export default React.memo(Table)