import React, { FC } from 'react'
import { BirthdayUserType } from '../../../../../../../types/types'
import styles from './BirthDayCard.module.scss'


type Props = {
    data: BirthdayUserType
}

const BirthDayCard:FC<Props> = ({data}) => {
    return (
        <div className="">
            Birthday
        </div>
    )
}

export default React.memo(BirthDayCard);