import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Input.module.scss'
import InputMask from 'react-input-mask';

type Props = {
    onChange: (e: string) => void
    value: string
    disabled?: boolean
    placeholder?: string
    classes?: {
        input?: string
    }
    mask?: string | any
    error?: string
}

const Input: FC<Props> = ({
    onChange,
    value,
    disabled,
    placeholder,
    classes,
    mask,
error }) => {
    return (
        <div className="">
            {!mask ? <input
                disabled={disabled}
                className={classNames(styles.input, {
                    [styles.disabled]: disabled,
                    [styles.error]: !!error
                }, classes?.input)}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                value={value} /> :
                <InputMask
                    disabled={disabled}
                    className={classNames(styles.input, {
                        [styles.disabled]: disabled,
                        [styles.error]: !!error
                    }, classes?.input)}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    mask={mask}
                />
            }
        </div>
    )
}

export default Input;
