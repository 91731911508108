import classNames from 'classnames'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { ActiveUserContext } from '../../../../../StaffPage'
import { ReactComponent as MarkIcon } from '../../../../../../../../assets/icons/mark.svg'
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/pencil.svg'

import styles from './PartsInfoUser.module.scss'
import { Title } from '../../../../../../../simples/Title'
import { Input } from '../../../../../../../simples/Input'
import { ProfileService } from '../../../../../../../../services/ProfileService'

// @ts-ignore
import $message from 'message-like-antd'
import { AccessRightType, ErrorTypeResponse, RolesKind } from '../../../../../../../../types/types'
import { useAppSelector } from '../../../../../../../../hooks/store'
import { selectUser } from '../../../../../../../../store/slice/auth'

const PersonalInfo: FC = (): JSX.Element => {

    const currentUser = useAppSelector(selectUser)
    const canEdit = useMemo(() => {
        return currentUser?.accessRights.includes(AccessRightType.EditPersonalData) || currentUser?.role === RolesKind.ADMIN
    }, [currentUser?.accessRights, currentUser?.role])

    const [user, setUser] = useContext(ActiveUserContext)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(true)

    const saveUser = useCallback(async () => {
        try {
            const response = await ProfileService.uploadProfileId(user, user.id)
            setUser(response)
            $message.success('Основные данные обновлены', [750])
        } catch (error: any) {
            if (error.response.data.status === 403) {
                $message.error(error.response.data.message, [750])
            } else {
                const errors = error.response.data.errors

                for (let key of errors) {
                    $message.error(key, [750])
                }
            }
        }
    }, [setUser, user])

    const onSetEditAndSave = useCallback(() => {
        if (isEdit) {
            saveUser().then(() => {
                setIsEdit(false)
            })
        } else {
            setIsEdit(true)
        }
    }, [isEdit, saveUser])

    return (
        <div className={styles.root}>
            <div className={styles.top}>
                <div
                    onClick={() => setIsOpen((prev) => !prev)}
                    className={classNames(
                        styles.flexBlock,
                        styles.cursorPointer
                    )}
                >
                    <MarkIcon
                        className={classNames(styles.icon, {
                            [styles.isOpen]: isOpen,
                        })}
                    />
                    <Title size={14}>Персональные данные</Title>
                </div>
                {canEdit && <div
                    onClick={onSetEditAndSave}
                    className={classNames(
                        styles.flexBlock,
                        styles.cursorPointer
                    )}
                >
                    <EditIcon className={styles.icon} />
                    <span>{!isEdit ? 'Изменить' : 'Сохранить'}</span>
                </div>}
            </div>
            {isOpen && (
                <div className={styles.items}>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Снилс</div>
                        <div className={classNames(styles.rightItem)}>
                            <Input
                                mask={'999-999-999 99'}
                                disabled={!isEdit}
                                value={user.SNILS || ''}
                                onChange={(e) =>
                                    setUser((prev) => ({ ...prev, SNILS: e }))
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>ИНН</div>
                        <div className={classNames(styles.rightItem)}>
                            <Input
                                disabled={!isEdit}
                                value={user.INN || ''}
                                onChange={(e) =>
                                    setUser((prev) => ({ ...prev, INN: e }))
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Реквизиты</div>
                        <div className={classNames(styles.rightItem)}>
                            {isEdit ? (
                                <Input
                                    disabled={!isEdit}
                                    value={user.BANK || ''}
                                    onChange={(e) =>
                                        setUser((prev) => ({
                                            ...prev,
                                            BANK: e,
                                        }))
                                    }
                                />
                            ) : (
                                <div>{user.BANK || ''}</div>
                            )}
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Паспорт</div>
                        <div className={classNames(styles.rightItem)}>
                            {isEdit ? (
                                <Input
                                    disabled={!isEdit}
                                    value={user.PASS || ''}
                                    onChange={(e) =>
                                        setUser((prev) => ({
                                            ...prev,
                                            PASS: e,
                                        }))
                                    }
                                />
                            ) : (
                                <div>{user.PASS || ''}</div>
                            )}
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.leftItem}>Загран. паспорт</div>
                        <div className={classNames(styles.rightItem)}>
                            {isEdit ? (
                                <Input
                                    disabled={!isEdit}
                                    value={user.INTPASS || ''}
                                    onChange={(e) =>
                                        setUser((prev) => ({
                                            ...prev,
                                            INTPASS: e,
                                        }))
                                    }
                                />
                            ) : (
                                <div>{user.INTPASS || ''}</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default React.memo(PersonalInfo)
