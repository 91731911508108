import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AuthService } from '../../services/AuthService'
import { DepartamentService } from '../../services/DepartamentService'
import { ProfileService } from '../../services/ProfileService'
import { ProfileType, User as UserType } from '../../types/types'
import { ErrorsTypes } from '../../utils/api'
import { RootState, store } from '../index'
import { fetchTabelGroups } from './storage'

/**
 * Начальное состояние редьюсера Auth/
 */

export const initialState = {
    /** Объект авторизованного пользователя. */
    user: null as ProfileType | null,
    /** Состояние: проверка токена пользователя */
    checking: true,
    /** Состояние: проверка токена пользователя была произведена */
    checked: false,
    /** Состояние: пользователь авторизован */

    isAuth: false,
    /** Состояние: идет обновление данных пользователя */
    updating: false,
    /** Состояние: данные пользователя были обновлены */
    updated: false,
    /** Состояние: было ли обновление пользователя с ошибкой */
    errorUpdate: false,
    /** Объект ошибки при обновлении пользователя */
    errorUpdateData: undefined as ErrorsTypes | undefined,
}

// -------------------------------------------
// Async Thunks
// -------------------------------------------

export const checkUser = createAsyncThunk('auth/checkUser', async () => {
    const response = await AuthService.getUser()
    return response
})
export const login = createAsyncThunk(
    'auth/login',
    async (data: { email: string; password: string }) => {
        const aData: any = data
        const fd = new FormData()
        for (let key in data) {
            fd.append(`${key}`, aData[key])
        }
        const response = await AuthService.login(fd)
        return response
    }
)

// -------------------------------------------
// Slice
// -------------------------------------------

/**
 * Создание слайса Auth.
 */
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthStatus: (state) => {
            state.isAuth = !state.isAuth
        },
        updateUser: (state, action) => {
            state.user = action.payload
        },
        logout: (state) => {
            state.user = null
            state.isAuth = false

            localStorage.removeItem('access_token')
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.checking = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.checking = false
            state.isAuth = !!action.payload
        })
        builder.addCase(login.rejected, (state, action) => {
            state.checking = false
            state.isAuth = !!action.payload
        })
        /**
         * Перед отправкой запроса на проверку токена ставим состояние checking в true.
         */
        builder.addCase(checkUser.pending, (state) => {
            state.checking = true
            state.checked = false
            state.isAuth = false
        })
        /**
         * Если проверка токена прошла успешно, то сохраняем объект авторизованного пользователя в стор.
         */
        builder.addCase(checkUser.fulfilled, (state, action) => {
            state.user = action.payload
            state.checking = false
            state.checked = true
            state.isAuth = true
        })
        /**
         * Если проверка токена завершилась провалом, то ставим состояние, что проверка была осуществлена
         */
        builder.addCase(checkUser.rejected, (state) => {
            state.checking = false
            state.checked = true
            state.isAuth = false
        })
    },
})

// -------------------------------------------
// Export action creators
// -------------------------------------------

// -------------------------------------------
// Selectors
// -------------------------------------------

/**
 * Селектор, идет ли в текущий момент проверка токена.
 * @param state Объект стора
 */
export const selectAuthChecking = (state: RootState) => state.auth.checking
/**
 * Селектор, была ли проверка токена.
 * @param state Объект стора
 */
// export const selectAuthChecked = (state: RootState) => state.auth.checked
/**
 * Селектор, авторизован ли пользователь.
 * @param state Объект стора
 */
export const selectIsAuth = (state: RootState) => state.auth.isAuth
export const selectUser = (state: RootState) => state.auth.user

/**
 * Селектор объекта авторизованного пользователя.
 * @param state Объект стора
 */
// export const selectAuthUser = (state: RootState) => state.auth.user

export const { setAuthStatus, updateUser, logout } = authSlice.actions

// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default authSlice.reducer
